import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import "./services.component.scss";

import jsonCompleteServicesData from "../../../json/complete.services.page.json";
import jsonSolarServicesData from "../../../json/solar.services.page.json";
import jsonRoofServicesData from "../../../json/roof.services.page.json";

import MainSectionServices from "./main/main.section.services";
import StockSectionServices from "./stock/stock.section.services";
import WhySectionServices from "./why/why.section.services";
import ModelsSectionServices from "./models/models.section.services";
import FAQsSectionServices from "./FAQs/FAQs.section.services";

const Services = () => {
  const [servicesData, setServicesData] = useState(jsonRoofServicesData);
  const { id } = useParams();

  useEffect(() => {
    if (id === "1") {
      setServicesData(jsonCompleteServicesData);
    } else if (id === "2") {
      setServicesData(jsonSolarServicesData);
    } else if (id === "3") {
      setServicesData(jsonRoofServicesData);
    }
  }, [id]);
  return (
    <div className="ServicesPage">
      {/* <MainSectionServices servicesData={servicesData} />
      <Container fixed>
        <StockSectionServices servicesData={servicesData} />
        <WhySectionServices servicesData={servicesData} />
        <ModelsSectionServices servicesData={servicesData} />
        <GallerySectionServices servicesData={servicesData} />
        <FAQsSectionServices servicesData={servicesData} />
      </Container> */}
      <MainSectionServices servicesData={servicesData} />
      <div
        style={{
          backgroundColor: "white",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <Container fixed>
          {servicesData.stock_section && (
            <StockSectionServices servicesData={servicesData} />

          )}
          {servicesData.why_section && (

            <WhySectionServices servicesData={servicesData} />

          )}
          {servicesData.models_section && (

            <ModelsSectionServices servicesData={servicesData} />

          )}
          {servicesData.FAQs_section && (

            <FAQsSectionServices servicesData={servicesData} />

          )}
          {/* <GallerySectionServices servicesData={servicesData} /> */}

        </Container>
      </div>
    </div>
  );
};

export default Services;
