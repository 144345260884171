import Carousel from 'react-material-ui-carousel';
import React from "react";

const images = [
  'https://images.pexels.com/photos/236060/pexels-photo-236060.jpeg',
  'https://images.pexels.com/photos/371917/pexels-photo-371917.jpeg',
  '/img/seven.section.image.svg',
];
const WebBannerSection = () => {
    return (
      <section className="webBannerSection">
        
      </section>
    );
}

export default WebBannerSection;