// import SectionFour from "./sections/section.four.home";
// import SectionFive from "./sections/section.five.home";
// import SectionSix from "./sections/section.six.home";

import ContactFormPage from "./contact.form.component";
import "./style.scss";
import { Container, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import jsonContactData from "../../json/contact.page.json";
import MainContact from "./section/main.contact";
import CustomerServiceContact from "./section/customer.service.contact";
import AnimatedSection from "../home/sections/animated.section.home";
import { useTheme } from "@emotion/react";

const ContactPage = (props) => {
  const [contactData, setContactData] = useState(jsonContactData);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/contact.page.json");
      const json = await response.json();
      setContactData(json);
    }
    fetchData();

    AOS.init({ duration: 2000 });
    // AOS.refresh();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Grid data-aos="fade-down">
        <MainContact contactData={contactData}></MainContact>
      </Grid>
      <Container fixed style={{ paddingBottom: "10px" }}>
        <Grid
          container
          spacing={1}
          style={{ paddingTop: "78px" }}
          className="contactCardsPage"
        >
          <Grid
            data-aos={isMobile ? "fade-up" : "fade-right"}
            item
            md={7}
            xs={12}
            className="formsContactPage"
          >
            <h2>{contactData.customer_form_section[0].title}</h2>
            <p>{contactData.customer_form_section[0].description}</p>
            <ContactFormPage></ContactFormPage>
          </Grid>
          <Grid
            data-aos={isMobile ? "fade-up" : "fade-left"}
            item
            md={5}
            xs={12}
          >
            <CustomerServiceContact
              contactData={contactData}
            ></CustomerServiceContact>
          </Grid>
        </Grid>
        <Grid data-aos="fade-up">
          <AnimatedSection />
        </Grid>

        {/* <div className="contactPageAnimated">
        <Player
          src='/contact.json'
          className="player"
          loop
          autoplay
        />
      </div> */}
      </Container>
    </>
  );
};

export default ContactPage;
