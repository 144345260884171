import React, { useEffect, useRef, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import CustomButton from "../../../../utils/custom.button";
import "./rating.slider.section.home.scss";
import CardRatingSectionHome from "./card.rating.section.home";
import CustomLink from "../../../../utils/custom.link";

const RatingSliderSectionHome = (props) => {
  const [activeDotNum, setActiveDotNum] = useState(0);
  const wrapperRef = useRef(null);
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));
  const handleRatingDotClick = (index) => {
    if (index === activeDotNum) {
      return;
    } else {
      const wrapper = wrapperRef.current;
      const dots = document.querySelectorAll(".ratingDot");
      const displayArea = wrapper.parentElement.clientWidth;
      const pixels = -displayArea * index;

      wrapper.style.transform = `translateX(${pixels}px)`;

      dots[activeDotNum].classList.remove("ratingActive");
      dots[index].classList.add("ratingActive");

      setActiveDotNum(index);
    }
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const displayArea = wrapper.parentElement.clientWidth;
    const pixels = -displayArea * activeDotNum;

    wrapper.style.transform = `translateX(${pixels}px)`;
  }, [activeDotNum]);
  const numSlides = () => {
    const numCards = props.homeData.ratings_section[0].ratings_list.length;

    //console.log(numCards);
    if (isMD) {
      //console.log("md");

      return Math.ceil(numCards);
    } else if (isLG) {
      //console.log("lg");
      if (numCards % 3 !== 0) {
        // Adjust the number of cards to the nearest multiple of 3
        return Math.ceil(numCards / 2 - 1);
      } else {
        return Math.ceil(numCards / 2);
      }
    } else {
      //console.log("else");
      if (numCards % 3 !== 0) {
        // Adjust the number of cards to the nearest multiple of 3
        return Math.ceil(numCards / 3 - 1);
      } else {
        return Math.ceil(numCards / 3);
      }
    }
  };
  return (
    <>
      {
        <div className="listRatingSection">
          <div className="rating-cards-wrapper" ref={wrapperRef}>
            {props.homeData.ratings_section[0].ratings_list.map(
              (rate, index) => (
                <div key={index}>
                  <CardRatingSectionHome
                    homeData={props.homeData}
                    rate={rate}
                  />
                </div>
              )
            )}
          </div>
        </div>
      }
      <div className="rating-slideshow-dots">
        {Array.from({ length: numSlides() }).map((_, index) => (
          <span
            key={index}
            className={`ratingDot ${
              activeDotNum === index ? "ratingActive" : ""
            }`}
            onClick={() => handleRatingDotClick(index)}
          ></span>
        ))}
      </div>
    </>
  );
};

export default RatingSliderSectionHome;
