import { Grid } from "@mui/material";
import React from "react";
import CustomButton from "../../../../utils/custom.button";
import { useNavigate } from "react-router-dom";
const SmallCardListProjects = (props) => {
  const navigate = useNavigate();
  const moveToProject = (project) => {
    navigate("/projects/" + project.id, { state: project });
    // console.log(project);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Grid
        container
        direction="row"
        className="smallCardProjectSectionHome"
        onClick={() => moveToProject(props.project)}
      >
        <Grid item sx={{ flex: 4 }}>
          <img
            alt=""
            className="mainImageProjectSectionHome"
            title="image"
            src={props.project.img[0]}
          />
        </Grid>
        <Grid item sx={{ flex: 8 }}>
          <Grid
            container
            direction="column"
            className="descriptionProjectSectionHome"
          >
            <h2>{props.project.address}</h2>
            {/* <h3>{props.project.address}</h3> */}
            {/* <h4>TJÄNSTER:</h4> */}
            <p>{props.project.description[0]}</p>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <Grid>
                <img
                  alt=""
                  className="iconProjectSectionHome"
                  title="image"
                  src={props.project.img}
                />
              </Grid> */}
              <Grid sx={{ marginRight: "19px" }}>
                <CustomButton
                  id="project_page_show"
                  //id="header_main_section_get_a_quote"
                  //onClick={projectAdd}
                  onClick={() => moveToProject(props.project)}
                  label="Visa projekt"
                  size="8px"
                  width="133px"
                  height="18px"
                  color="#ffffff"
                  background="#FF8718"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SmallCardListProjects;
