import { ErrorMessage, Field } from "formik";
import CustomField from "../../../utils/custom.field";

const PersonalAddProjectForm = (props) => {
  return (
    <>
      <Field
        name="name"
        type="text"
        label="Namn"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        style={{ color: "red" }}
        name="name"
      />
      <Field
        name="email"
        type="text"
        label="E-postadress"
        required={true}
        component={CustomField}
      />

      <ErrorMessage component="span" className="error-message" name="email" />
      <Field
        name="phone"
        type="text"
        label="Telefon"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        style={{ color: "red" }}
        name="phone"
      />
    </>
  );
};
export default PersonalAddProjectForm;
