import { Grid, Slider, Typography } from '@mui/material';
import React, { useState } from 'react';
import './direction.css';

const DirectionAddServiceForm = (props) => {
    const [direction, setDirection] = useState(180);
    function valueText(value) {
        return `${value}degree`;
    }
    const handleAddressChange = (e) => {
        // setAddressValue("address", e.target.value);
    };
    const handleChange = (event, newValue) => {
        setDirection(newValue);
    };
    return (
        <Grid   container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Grid itme xs={6} 
            direction="row"
            container
            justifyContent="center"
        alignItems="center">
            <RotateAnimation rotation={direction} />
            <Slider
                id="annualConsumption"
                aria-label="Always visible"
                defaultValue={direction}
                getAriaValueText={valueText}

                max={360}
                step={1}
                marks={marks}
                valueLabelDisplay="on"
                onChange={handleChange}
            />
            </Grid>

                <Grid itme xs={6}>
                <Typography component={'h2'}>
                Takets kompassriktning
                </Typography>
                <Typography>
Välj riktning som taket lutar emot genom att dra den gröna punkten längs med linjen under kompassen.
                </Typography>
                </Grid>
        </Grid>
    );
}

const RotateAnimation = ({ rotation }) => {
    const handleClick = () => {
        console.log('Clicked!');
    }
    return (
        <div className="container-outer">
            <img src="/direction_background.png" alt="" className="outer-image"/>
            <div className="container-inner" style={{ transform: `rotate(${rotation}deg)` }} onClick={handleClick}>
                <img className="inner-image" src={"/compass.svg"} alt="Arrow pointing North" />
            </div>
        </div>

    );
};

const marks = [
    {
        value: 0,
        label: '0 Norr',
    },
    {
        value: 90,
        label: '180 Väst',
    },
    {
        value: 180,
        label: '180 Söder',
    },
    {
        value: 270,
        label: '270 Öst',
    },
    {
        value: 360,
        label: '360 Norr',
    }
];
export default DirectionAddServiceForm;
