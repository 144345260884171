import React, { useEffect, useState } from 'react';
import { CartContext } from '../context';

const CartProvider = (props) => {
  const [cart, setCart] = useState([]);
  useEffect(() => {
    const cartStorage = localStorage.getItem("cart");
    if (cartStorage) {
      setCart(JSON.parse(cartStorage));
    }
  }, []);
  return (
    <CartContext.Provider value={[cart, setCart]}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;