/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
export const validatePassword = (Yup) => {
    return Yup.string()
    .required("Please Enter your password")
    .min(5, "Your password must be longer than 5 characters.")
    .max(25)
    .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Must Contain One Uppercase, One Lowercase"
    )
    .matches(
      /[-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
      "Must Contain One Special Case Character"
    )
    .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number")
};

export default validatePassword