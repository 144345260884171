import { Grid, useMediaQuery } from "@mui/material";
import "./product.section.home.scss";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../utils/custom.button";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const ProductSliderSectionHome = (props) => {
  const [showFullText, setShowFullText] = useState(false);
  const textLimit = 100; // Character limit for initial display
  const [activeIndex, setActiveIndex] = useState(null);
  const [slideIndex, setSlideIndex] = useState(1);
  const theme = useTheme();
  let navigate = useNavigate();

  const moveToProduct = (id) => {
    navigate("/products/" + id);
    //console.log(`Product's ${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const handleMouseEnter = (index) => {
    setActiveIndex(index);
    setShowFullText(true);
  };

  const handleMouseLeave = (index) => {
    setActiveIndex(undefined);
    setShowFullText(false);
  };

  const getDescriptionText = (description, index) => {
    if (showFullText && activeIndex === index) {
      return description;
    } else {
      return description.length > textLimit
        ? description.slice(0, textLimit) + "..."
        : description;
    }
  };
  const numSlides = props.homeData.products_section[0].product_list.length;
  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;

      // Handle wrap-around when reaching the end or beginning of slides
      if (newIndex > numSlides) {
        newIndex = 1;
      } else if (newIndex < 1) {
        newIndex = numSlides;
      }

      return newIndex;
    });
  };

  const currentSlide = (n) => {
    setSlideIndex(n);
  };

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  const showSlides = (n) => {
    let i;
    const slides = document.getElementsByClassName("cardProductSlide");
    const dots = document.getElementsByClassName("dot");

    if (n > slides.length) {
      setSlideIndex(1);
    } else if (n < 1) {
      setSlideIndex(slides.length);
    }

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = i === n - 1 ? "block" : "none";
    }

    for (i = 0; i < dots.length; i++) {
      dots[i].classList.remove("active");
    }

    dots[n - 1].classList.add("active");
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className="listProductSection"
        spacing={2}
      >
        <Grid item xs={1}>
          <button className="productPrevButton" onClick={() => plusSlides(-1)}>
            ❮
          </button>
        </Grid>

        <Grid item xs={10} className="productSlider">
          {props.homeData.products_section[0].product_list.map(
            (product, index) => (
              <div key={index} className="cardProductSlide fade">
                <Grid
                  container
                  className={`cardProductSection ${
                    activeIndex === index ? "cardProductSectionActive" : ""
                  }`}
                  // onMouseEnter={() => handleMouseEnter(index)}
                  // onMouseLeave={() => handleMouseLeave(index)}
                >
                  <Grid className="rightProductSide" item xs={6}>
                    <h2 className="cardProductSectionTitle">{product.title}</h2>
                    <p className="cardProductSectionDescription">
                      {/* {getDescriptionText(product.description, index)} */}
                      {product.description}
                    </p>
                    <CustomButton
                      id="home_product_section"
                      icon="Läs mer"
                      size="16px"
                      width="135px"
                      height="45px"
                      color="#ffffff"
                      background="#FF8718"
                      onClick={() => moveToProduct(product.id)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      alt=""
                      className="cardProductSectionImage"
                      title="image"
                      src={product.img}
                    />
                  </Grid>
                </Grid>
              </div>
            )
          )}
        </Grid>
        <Grid item xs={1}>
          <button className="productNextButton" onClick={() => plusSlides(1)}>
            ❯
          </button>
        </Grid>
      </Grid>
      <div
        className="slideshow-dots"
        style={{
          textAlign: "center",
          alignItems: "center",
          paddingTop: "73px",
        }}
      >
        {props.homeData.products_section[0].product_list.map(
          (product, index) => (
            <span
              key={index}
              className={`dot ${slideIndex === index + 1 ? "active" : ""}`}
              onClick={() => currentSlide(index + 1)}
            ></span>
          )
        )}
      </div>
    </>
  );
};

export default ProductSliderSectionHome;
