import { Grid, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useTheme } from "@emotion/react";
import DesktopMainSectionHome from "./desktop.main.section.home";
import MobileMainSectionHome from "./mobile.main.section.home";

const MainSectionHome = (props) => {
  let navigate = useNavigate();
  const [openProjectAdd, setOpenProjectAdd] = React.useState(false);
  const projectAdd = () => {
    //setOpenProjectAdd(true);
    navigate("/project/add", {
      state: { scrollToTop: true },
    });
  };
  const closeProjectAdd = () => {
    setOpenProjectAdd(false);
  };
  const learnMore = () => {
    navigate("/about");

    //setOpenProjectAdd(false);
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <section className="mainSection">
      {isMobile ? (
        <MobileMainSectionHome
          homeData={props.homeData}
          projectAdd={projectAdd}
          closeProjectAdd={closeProjectAdd}
          learnMore={learnMore}
        />
      ) : (
        <DesktopMainSectionHome
          homeData={props.homeData}
          projectAdd={projectAdd}
          closeProjectAdd={closeProjectAdd}
          learnMore={learnMore}
        />
      )}
    </section>
  );
};

export default MainSectionHome;
