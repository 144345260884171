import { ErrorMessage, Field } from "formik";
import CustomMapField from "./service.add.map.component";

const SlopeAddServiceForm = (props) => {
  const handleAddressChange = (e) => {
    // setAddressValue("address", e.target.value);
  };
  return (
    <>
      <Field
        name="address"
        label="address"
        type="text"
        required={true}
        onChange={handleAddressChange}
        apiKey={"AIzaSyCDXtmPd5JQhNUpps_d5Rz3FP4RIT5GgzU"}
        component={CustomMapField}
      />
      <ErrorMessage component="span" className="error-message" name="address" />
    </>
  );
};
export default SlopeAddServiceForm;
