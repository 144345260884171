import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Grid,
  Link,
  Paper,
} from "@mui/material";
import NoStyleLink from "../../../../utils/no.style.link";
import { CartContext } from "../../../../context";

import styled from "@emotion/styled";
import { useContext } from "react";

const Root = styled.div({
  borderRadius: 10,
  transition: "transform .2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

// it has an error message
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const ProductCard = (props) => {
  const [cart, setCart] = useContext(CartContext);

  function addToCart(product) {
    let quantity = 1;
    let quantityPrice = 0;
    for (let index = 0; index < cart.length; index++) {
      if (cart[index].product.id === product.id) {
        quantity = cart[index].quantity + 1;
        quantityPrice =
          parseFloat(cart[index].quantityPrice) + parseFloat(product.price);
        cart[index].quantity = quantity;
        cart[index].quantityPrice = quantityPrice;
      }
    }
    if (quantity === 1) {
      quantityPrice = product.price;
      setCart([...cart, { product, quantity, quantityPrice }]);
      localStorage.setItem(
        "cart",
        JSON.stringify([...cart, { product, quantity, quantityPrice }])
      );
    } else {
      setCart([...cart]);
      localStorage.setItem("cart", JSON.stringify([...cart]));
    }
    // const products = JSON.parse(localStorage.getItem('carts')) || [];
    // products.push(product);
    // localStorage.setItem("carts", JSON.stringify(products));
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={4} gap={8}>
      {/* <Item> */}
      <NoStyleLink to={"/product/" + props.product.id}>
        <CardActionArea>
          <CardMedia
            image="https://www.free-energy.com/uploads/images/sol.png"
            title="Energy Product"
          >
            <img src="/logo.png" alt="energy-product-icon" />
          </CardMedia>
          <CardContent>
            <Typography gutterBottom variant="h3" component="h3">
              {props.product.title}
            </Typography>
            <Typography variant="h4" color="primary" component="p">
              {props.product.price} SEK
            </Typography>
            <Typography
              sx={{ overflow: "hidden", height: "200px" }}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {props.product.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </NoStyleLink>
      <CardActions>
        <Grid container>
          <Grid xs={6} item>
            <Link
              to={"/product/" + props.product.id}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" size="small" color="primary">
                LÄS MER
              </Button>
            </Link>
          </Grid>
          <Grid xs={6} item>
            <Button
              onClick={() => addToCart(props.product)}
              variant="contained"
              size="small"
              color="primary"
            >
              Add to cart
            </Button>
          </Grid>
        </Grid>
      </CardActions>

      {/* </Item> */}
    </Grid>
  );
};

export default ProductCard;
