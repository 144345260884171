import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./custom.sub.menu.link.scss";
import { Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomLink from "./custom.link";
const LinkItem = styled(Link)(({ background_color, font_color }) => ({
  backgroundColor: background_color,
  color: font_color + " !important",
  textDecoration: "none",
  margin: "0px",
  "&:hover": {
    textDecoration: "none",
  },
}));

const CustomSubMenuLinkModal = ({
  children,
  to,
  id,
  className,
  closeMenu,
  onMouseEnter,
  onMouseLeave,
  section,
  indicatorLeave,
  background_color,
  font_color,
  onClick,
  label,
  modalVisible,
  setModalVisible,
  closeModal,
  subMenu,
}) => {
  const handleOutsideClick = (event) => {
    const modalContent = document.querySelector(".modal-content");
    const modalSubMenu = document.querySelector(".modalSubMenu");
    modalContent.classList.add("slide-out");
    modalSubMenu.classList.add("hide-up");

    setTimeout(() => {
      setModalVisible(false);
    }, 500);
  };
  return (
    <>
      {children}
      {modalVisible && (
        <div
          id="myModal"
          className="modalSubMenu" //onClick={handleOutsideClick}
        >
          <div className="modal-content slide-from-right">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="modalSubMenu-header"
            >
              <Grid
                item
                xs={2}
                style={{
                  backgroundColor: "#39C2FB",
                  height: "100%",
                }}
              >
                <span className="close" onClick={handleOutsideClick}>
                  <ArrowBackIosIcon />
                </span>
              </Grid>
              <Grid item xs={10}>
                <h2>Modal Header</h2>
              </Grid>
            </Grid>

            <div className="modalSubMenu-body">
              {subMenu.map((subMenuItem, index) => (
                <div
                  style={{
                    paddingBottom: "17px",
                    paddingTop: "17px",
                    borderBottom: "3px solid #E5E5E5",
                  }}
                >
                  <CustomLink
                    className="menuMobileLink"
                    id={index}
                    to={subMenuItem.to}
                    //onClick={subMenu ? openModal : ""}
                    //closeModal={subMenu ? closeModal : ""}
                    closeMenu={closeMenu}
                  >
                    <span className="text">{subMenuItem.label}</span>
                    <span className="arrow">
                      <ArrowForwardIosIcon />
                    </span>
                  </CustomLink>
                </div>
                // <LinkItem
                //   key={index}
                //   onMouseEnter={onMouseEnter}
                //   onMouseLeave={onMouseLeave}
                //   className={className}
                //   background_color={background_color}
                //   font_color={font_color}
                //   to={to}
                //   //onClick={handleClick}
                // >
                //   <span className="text">{subMenuItem.label}</span>
                //   <span className="arrow">
                //     <ArrowForwardIosIcon />
                //   </span>
                // </LinkItem>
              ))}
            </div>
            <div className="modalSubMenu-footer">
              <></>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CustomSubMenuLinkModal;
