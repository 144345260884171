// import SectionFour from "./sections/section.four.home";
// import SectionFive from "./sections/section.five.home";
// import SectionSix from "./sections/section.six.home";
import { Player } from "@lottiefiles/react-lottie-player";
import { Button, Grid, TextField } from "@mui/material";
import CustomField from "../../utils/custom.field";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./style.scss";
import CustomButton from "../../utils/custom.button";
import ContactService from "./contact.service";

const ContactFormPage = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");

  // SubmitData.Post({
  //   username: '',
  //   password: '',
  // })
  // form props
  function SubmitData(formValue) {
    setLoading(true);

    const { name, subject, email, phone, description } = formValue;
    // console.log(formValue);
    setDescription("");

    ContactService(name, subject, email, phone, description, "contact").then(
      () => {
        navigate("/project/requited");
        window.location.reload();
      },
      (error) => {
        const expressage =
          (error.response &&
            error.response.data &&
            error.response.data.description) ||
          error.description ||
          error.toString();

        setLoading(false);
        setDescription(expressage);
      }
    );
  }
  const formikProps = {
    initialValues: {
      name: "",
      subject: "",
      email: "",
      phone: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(""),
      subject: Yup.string().required(""),
      email: Yup.string().required(""),
      phone: Yup.string(),
      description: Yup.string().required(""),
    }),
    onSubmit: (values) => {
      //console.log(values);
      SubmitData(values);
    },
  };
  return (
    <Formik {...formikProps}>
      <Form className="formContactPage">
        <Grid container>
          <Grid item xs={12}>
            <Field
              name="name"
              type="text"
              label="Namn"
              required={true}
              component={CustomField}
            />
            <ErrorMessage
              component="span"
              className="error-message"
              name="name"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="subject"
              type="text"
              label="Ämne"
              required={true}
              component={CustomField}
            />
            <ErrorMessage
              component="span"
              className="error-message"
              name="subject"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="email"
              type="email"
              label="E-postadress"
              required={true}
              component={CustomField}
            />
            <ErrorMessage
              component="span"
              className="error-message"
              name="email"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="phone"
              type="text"
              label="Telefon"
              required={false}
              component={CustomField}
            />
            <ErrorMessage
              component="span"
              className="error-message"
              name="phone"
            />
          </Grid>

          <Field
            name="description"
            type="text"
            label="Skriv ditt meddelande..."
            required={true}
            component={CustomField}
            multiline
            rows={5.8}
            fullWidth
          />
        </Grid>

        <CustomButton
          id="contact_form_submit"
          disabled={loading}
          type="submit"
          label="Skicka"
          size="24px"
          width="164px"
          height="64px"
          color="#FFFFFF"
          // background="background-image: linear-gradient(to right, #FBB034, #ffdd00);"
          background="#FFA300"
        />
        {loading && <span className="spinner-border spinner-border-xs"></span>}
        {description && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {description}
            </div>
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default ContactFormPage;
