
import { useEffect, useState } from "react";
import { listProducts } from "../../../services/product.service";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SearchResult from "./result/product.result.component";
import SearchSidebar from "./sidebar/sidebar.component";
import Mainbar from "./mainbar/search.field.component";
import AddProduct from "../add/product.add.component";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { listCategories } from "../../category/category.service";
import eventBus from "../../../common/EventBus";
const ProductSearch = () => {
  const [message, setMessage] = useState("");
  const [results, setResults] = useState([]);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState(1);
  const [Make, setMake] = useState('');
  const [price, setPrice] = useState([0,1000000]);
  const [priceRange, setPriceRange] = useState([0,100000]);
  const [height, setHeight] = useState(5000);
  const [width, setWidth] = useState(2500);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    listCategories().then(
      (response) => {
        //console.log(response);
        setCategories(response.data);
        //setCount(response.data.count);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setCategories(_content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
      }
    );
  }, []);
  function getHighestPrice(products) {
    let highestPrice = 0
    for(let i=0; i< products.length; i++){
      if(products[i].price > highestPrice){
        highestPrice = products[i].price;
      }
    }
    return highestPrice;
  }

  const fetchResults = async (title,
    category,
    Make,
    price,
    height,
    width) => {
    listProducts(title,
      category,
      Make,
      price,
      height,
      width).then(
        (response) => {
          setPriceRange([0, Number(getHighestPrice(response.data.products))]);
          //console.log(priceRange);
          setResults(response.data.products);
          //setCount(response.data.count);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(message);
        }
      );
  };
  // console.log(category);
  // console.log(title,"jihad");
  
  useEffect(() => {
    if (!title &&
      !category &&
      !Make &&
      !price &&
      !height &&
      !width
    ) return;
    fetchResults(title,
      category,
      Make,
      price,
      height,
      width);
  }, [title,
    category,
    Make,
    price,
    height,
    width
  ]);

  let running = true;

  function hasParent(selectedCategory, itemCategory) {
    if (running) {
    if (selectedCategory.id === itemCategory.id) {
      return true;
    }
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].id === itemCategory.id || categories[i].parent_id === selectedCategory.id) {
        return hasParent(categories[i], itemCategory);
      }
        if (selectedCategory.id === categories[i].id) {
          running = false;
          return false;
      }
    }
  }
  else {
    return false;
  }
}

  function isSelectedCategory(item) {
    for (let s = 0; s < categories.length; s++) {
      if (category === categories[s].id) {        
        if (hasParent(categories[s], item.categories[0])) {
          return true
        }
      }
    }
    running = true;

    return false;
  }


  const filteredData = results.filter((item) => {

    return (

      (item.title.toLowerCase().includes(title.toLowerCase()) || item.description.toLowerCase().includes(title.toLowerCase())) &&
      item.Make.includes(Make.toLowerCase()) &&
      item.price >= price[0] &&
      item.price <= price[1] &&
      item.height <= height &&
      item.width <= width &&
      isSelectedCategory(item)

    );
  }) || results?.map((option) => option);

  const formikProps = {
    
    initialValues: {
      title: "",
      category: "",
      Make: "",
      price: priceRange,
      height: 5000,
      width: 2500
    },
    validationSchema: Yup.object().shape({}),

    onChange: (values, event) => {
      setTitle(values.title);
      setCategory(values.category);
      setMake(values.Make);
      setPrice(values.price);
      setHeight(values.height);
      setWidth(values.width);
    }
  }

  //setResults(top100Films)
  return (
    <>
      <Formik {...formikProps}>
        {formik => (

          <Form>
            <Container fixed maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <SearchSidebar
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    set_category={setCategory}
                    set_make={setMake}
                    set_price={setPrice}
                    
                    set_height={setHeight}
                    set_width={setWidth}
                    categories={categories}

                  />
                </Grid>
                <Grid item xs={10}>
                  <Grid item sx={{ px: 1, py: 5 }} xs={12}>
                    <Mainbar
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      set_title={setTitle}

                      setResults={setResults}
                      results={filteredData}
                    />
                  </Grid>
                  <Grid item sx={{ px: 1 }} xs={12}>
                    <SearchResult results={filteredData} />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
      <AddProduct />
    </>
  );
}

export default ProductSearch;