import { Container, Grid } from "@mui/material";
import CustomButton from "../../../../utils/custom.button";
import "./main.section.services.scss";
import React from "react";

const DesktopMainSectionServices = (props) => {
  function scrollToSection(sectionId) {
    setTimeout(() => {
      console.log(sectionId);
      const serviceSection = document.getElementById(sectionId);

      if (serviceSection) {
        serviceSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }
  return (
    <div className="DesktopContainerService">
      <img
        className="backgroundSectionImage"
        alt=""
        title="image"
        src={props.servicesData.main_section[0].image}
      />
      <Container fixed className="mainSectionContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid xs={6} className="left">
            <h1>{props.servicesData.main_section[0].title[0]}</h1>
            <h1>{props.servicesData.main_section[0].title[1]}</h1>
            <p>{props.servicesData.main_section[0].description}</p>
            {/* <CustomButton
              id="header_get_a_quote_mobile"
              onClick={() => scrollToSection("modelsSectionServices")}
              label={props.servicesData.main_section[0].buttonLabel}
              closeMenu={props.toggleB}
              color={"#fff"}
              background={"#ff8717"}
              border_radius={"25px 25px 25px 25px"}
              margin={"26px 0px 0px 0px"}
              size={"20px"}
            ></CustomButton> */}
          </Grid>
          {props.servicesData.main_section[0].imageMain && (
            <Grid xs={6} className="right">
              <img
                className="mainSectionImage"
                alt=""
                title="image"
                src={props.servicesData.main_section[0].imageMain}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default DesktopMainSectionServices;
