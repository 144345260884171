import React, { useEffect, useState } from "react";
import "./project.details.component.scss";
import { Container, Grid, useMediaQuery } from "@mui/material";

import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../utils/custom.button";
import CustomBackButton from "../../../utils/custom.back.button";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import ProjectDetailsSlider from "./slider/project.details.slider.component";
import MainSectionProjectsDetails from "./main/main.section.details.project";
const ProjectDetails = (props) => {
  const [projectsData, setProjectsData] = useState();

  const theme = useTheme();
  let navigate = useNavigate();
  const { projects, id } = useParams();
  const location = useLocation();
  const { state } = location;
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const lastSection = document.querySelector(".lastSection");
    lastSection.style.display = "none";
  }, []);
  const contactButton = (id) => {
    navigate("/contact");
    //console.log(`Product's ${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const downloadPdf = async (state) => {
    try {
      const response = await axios.get(state.pdf, {
        responseType: "arraybuffer", // Set the response type to 'arraybuffer'
      });

      const buffer = response.data; // Get the ArrayBuffer object
      const url = window.URL.createObjectURL(
        new Blob([buffer], { type: "application/pdf" })
      ); // Create a URL from the ArrayBuffer object

      // Set the current window location to the PDF file URL
      window.location = url;
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <MainSectionProjectsDetails backgroundImage={state.backgroundImage} />
      <section className="projectPageDetails">
        {!isSM && (
          <CustomBackButton
            id="header_main_section_learn_more"
            label={"Solpaneler"}
            section={"projectsSectionProducts"}
            width="211px"
            height="47px"
            size="20px"
            color="#FF7817"
            background="#fffff"
            border="#ffff"
            margin="22px 22px 22px 22px"
            container
            fixed
          ></CustomBackButton>
        )}

        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            {/* <img
            className=""
            alt=""
            title="image"
            src={state.img[0]}
            style={{ width: "70%", height: "70%", objectFit: "scale-down" }}
          /> */}
            <ProjectDetailsSlider images={state.img} />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="mainTitleProject">
              <h1>{state.address}</h1>
            </div>
            {state.description.map((desc, i) => (
              <p>{desc}</p>
            ))}
            {state.text && (

            <div className="cardRatingProject">
            <h2>{state.title}</h2>
            {state.text.map((text, i) => (
              <p>{text}</p>
            ))}

            <Grid className="starContainer">
              {Array.from({ length: 5 }).map((_, i) => (
                <img
                  key={i}
                  alt=""
                  className="starImageCardSlide"
                  title="image"
                  src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/home/ratings_section/star.png"
                />
              ))}
            </Grid>
            </div>

            )}
        
            <Grid
              className="mainSubButtons"
              style={{ margin: "66px 0px 33px 0px" }}
              container
              direction="row"
              justifyContent={isSM ? 'center': 'left' }
              alignItems="left"
            >
              <CustomButton
                id="header_main_section_learn_more"
                onClick={() => contactButton(state.id)}
                label="Kontakta oss"
                width="181px"
                height="57px"
                size="14px"
                color="#FFF"
                background="#FF7817"
                border="#ffff"
                // margin="22px 22px 22px 22px"
              />
            </Grid>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default ProjectDetails;
