import { useState, useEffect } from 'react';
import styled from '@emotion/styled'
import Theme from '../../../utils/theme';
import { Typography, Card, Grid, Chip, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { patchUser } from '../users.service';

const GridStyled = styled(Grid)({
  minHeight: '60vh',
});

const TitleStyled = styled(Typography)({
  fontWeight: 500,
});
const TitleItem = styled(Typography)({
  marginTop: Theme.spacing(2),
});

const ChipStyled = styled(Chip)({
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: 'content-box',
          padding: 3,
          fontSize: '1.125rem',
        },
      },
    },
  },
});

function CustomChip(props) {
  const [open, setOpen] = useState(false);
  const [editedValue, setEditedValue] = useState(props.value[props.name]);


  const handleOpen = () => {
    if ( props.setValue) {
      setOpen(true);

    }
  };
  const handleChange = (event) => {
    setEditedValue(event.target.value)

    //setEditedValue(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    props.value[props.name] = editedValue;
    patchUser(props.value.id, {[props.name]: editedValue })
    props.setValue(props.value)
    
    // Submit the form and update the value in the parent component's state
    handleClose();
  };

  return (
    <>
      <Card sx={{ marginY: { sm: '3rem' }, padding: { sm: '1rem' } }} >
        <TitleStyled variant="body2" sx={{ marginY: { sm: '0.5rem' } }}>{props.label}</TitleStyled>
        <ChipStyled label={props.value[props.name]} deleteIcon={<EditIcon />} onDelete={handleOpen} />
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Value</DialogTitle>
        <DialogContent >
          <TextField style={{ width: 251 }} value={editedValue} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomChip;