import { useNavigate } from "react-router-dom";
import CustomButton from "../../../utils/custom.button";
import "./section.two.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useState } from "react";
import AddProject from "../../project/add/project.add.dialog";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
const SectionTwo = (props) => {
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  let navigate = useNavigate();
  const [openProjectAdd, setOpenProjectAdd] = React.useState(false);
  const projectAdd = () => {
    //setOpenProjectAdd(true);
    navigate("/project/add", {
      state: { scrollToTop: true },
    });
  };
  const closeProjectAdd = () => {
    setOpenProjectAdd(false);
  };
  const [isLoading, setIsLoading] = useState(true);

  // useEffect hook to simulate the file loading process
  useEffect(() => {
    // Simulate file loading process with a delay
    const delay = 2000; // Adjust the delay time as needed

    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <section className="quoteSection">
      <Grid container spacing={3} className="quoteSectionFirst">
        <Grid Item sx={12} md={6} className="quoteSectionAnimation">
          <Player
            src={props.homeData.quote_section[0].animated_image}
            className="player"
            loop
            autoplay
          />
        </Grid>
        <Grid Item sx={12} md={6} className="quoteSectionContainer">
          <div className="innerContainer">
            <h6 className="quoteSmallTitle">
              {props.homeData.quote_section[0].subTitle}
            </h6>
            <h1 className="quoteTitle">
              {props.homeData.quote_section[0].title}
            </h1>
            {props.homeData.quote_section[0].description.map((desc, i) => (
              <p className="quoteSubTitle">
              {desc}
            </p>          
            ))}
            <div className="quoteIconsList">
              {props.homeData.quote_section[0].quoteIconsList.map((icon, i) => (
                <div key={i} className="quoteIcon">
                  <img src={icon.image} alt="" />
                  <p>{icon.title}</p>
                </div>
              ))}
            </div>
            <div className="quoteSectionButtons">
              <CustomButton
                id="header_second_section_get_a_quote"
                className="quoteSectionButton"
                onClick={projectAdd}
                label="FÅ EN OFFERT"
                width="209px"
                height="46px"
                color="#ffffff"
                background="#FF8718"
              />
              <a href="about" className="quoteSectionReadmore">
                LÄS MER
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.96028 6.14228C7.17996 6.36196 7.17996 6.71806 6.96028 6.93773L1.22541 12.6726C1.00573 12.8923 0.649631 12.8923 0.429956 12.6726L0.164756 12.4074C-0.0549187 12.1878 -0.0549187 11.8316 0.164756 11.6119L5.23671 6.54001L0.164756 1.46806C-0.0549186 1.24838 -0.0549186 0.89228 0.164756 0.672606L0.429956 0.407406C0.649631 0.187731 1.00573 0.187731 1.22541 0.407406L6.96028 6.14228Z"
                    fill="#1C96DF"
                  />
                </svg>
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="quoteSectionSecond"
      >
        <Grid
          item
          sx={12}
          md={6}
          className="quoteSectionSecondContainer quoteSectionContainer"
        >
          <div className="innerContainer">
            <h6 className="quoteSmallTitle">
              {props.homeData.quote_section[0].subTitle2}
            </h6>
            <h1 className="quoteTitle">
              {props.homeData.quote_section[0].title2}
            </h1>
            {isMD && (
              <Grid item sx={12} md={6} className="quoteSectionImage">
                <div className="imageContainer">
                  <img
                    src={props.homeData.quote_section[0].image2}
                    className="centeredImage"
                  />
                </div>
              </Grid>
            )}
                {props.homeData.quote_section[0].description2.map((desc, i) => (
              <p className="quoteSubTitle">
              {desc}
            </p>          
            ))}
          
          </div>
        </Grid>
        {!isMD && (
          <Grid item sx={12} md={6} className="quoteSectionImage">
            <Grid className="imageContainer">
              <img
                src={props.homeData.quote_section[0].image2}
                className="centeredImage"
              />
            </Grid>
          </Grid>
        )}

        {isMD && (
          <Grid container justifyContent="center">
            <Grid item sx={12} className="readMoreButtonContainer">
              <CustomButton
                id="header_second_section_get_a_quote"
                className="readMoreButton"
                onClick={projectAdd}
                label="LÄS MER"
                width="145px"
                height="35px"
                size="12px"
                color="#ffffff"
                background="#FF8718"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </section>
  );
};

export default SectionTwo;
