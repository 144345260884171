// import { getProducts } from "../../services/product.service";

//import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { ErrorMessage, Field } from "formik";
import CustomAutoField from "../../../utils/custom.auto.field";

const SearchField = (props) => {
  //console.log(props.formik.values.user);
    return (
        <Grid>
            <Grid item xs={12}>
            {/* <p>{props.formik.values.user}</p> */}
                   <Field
                    freeSolo={true}
                    component={CustomAutoField}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.user}
                    onBlur={props.formik.handleBlur}
                    set_value={props.setUser}

                    name="user"
                    label="Sök"
                    options={props.users?.map((user) => (user.username))}
                    formik={props.formik}
                    placeholder="Sök"
                  />
                  <ErrorMessage
                    name="user"
                    component="div"
                    className="alert alert-danger"
                  /> 
            </Grid>
        </Grid>
    );
}

export default SearchField;
