import { Checkbox, Grid } from "@mui/material";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

const SelectAdviceQuote = ({ field, form, advice, setAdvice, ...props }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="advice"
        defaultValue={true}
        name={field.name}
        onChange={(event) => {
          const selectedLabel = event.target.labels[0].innerText; // Get the label text
          setAdvice(event.target.value);
          form.setFieldValue(field.name, selectedLabel);
        }}
      >
        <FormControlLabel
          value="false"
          control={<Radio />}
          label="Fortsätt till tak, solpaneler och elförbrukning."
        />
        <FormControlLabel
          value="true"
          control={<Radio />}
          label="Jag vill bara ha rådgivning från er."
        />
      </RadioGroup>
    </FormControl>
  );
};

export default SelectAdviceQuote;
