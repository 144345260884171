import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import "./custom.avatar.scss";

const StyledFab = styled(Grid)({
  position: "fixed",
});

const CustomAvatar = ({ review, className }) => {
  const renderAvatar = () => {
    if (review.image) {
      return (
        <img
          className={"avatarRateBigSectionImg " + className}
          src={review.image}
          alt="dfg 3"
        />
        // <img
        //   className="avatarRateSectionImg"
        //   src={review.image}
        //   alt="Review Image"
        //   style={{ width: "30%" }}
        // />
      );
    } else {
      const initials = getInitials(review.title);
      return (
        <>
          <div className={"avatar " + className}>{initials}</div>
        </>
      );
    }
  };

  const getInitials = (name) => {
    const parts = name.split(" ");
    let initials = "";

    for (let i = 0; i < parts.length; i++) {
      initials += parts[i][0];
    }

    return initials.toUpperCase();
  };

  return renderAvatar();
};

export default CustomAvatar;
