import axios from "axios";
import eventBus from "../../common/EventBus";
import { environment } from "../../environments/environment";
import authHeader from "../../services/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_Server_URL;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {

    if(error){
      console.log(error.response.data);
    }
    if (error.response.status === 401) {
      eventBus.dispatch("logout");

      // Log out the user here
      localStorage.removeItem('token');
      localStorage.removeItem("user");

    }
    return Promise.reject(error);
  }
);
export const listUsers = () => {
  return api.get(process.env.REACT_APP_Server_URL + "users", { headers: authHeader() });
};
export const postUser = (userName) => {
  return api.post(process.env.REACT_APP_Server_URL + "users", {
      userName
  }, { headers: authHeader() })
  .then(response => {
    if (response.data.accessToken) {
      //console.log(JSON.stringify(response.data));
    }

    return response.data;
  });
};
export const patchUser = (id, data) => {
  return api.patch(process.env.REACT_APP_Server_URL + "users/" + id, {
    data
  }, { headers: authHeader() })
  .then(response => {
    if (response.data.accessToken) {
      //console.log(JSON.stringify(response.data));
    }

    return response.data;
  });
};
export const patchRoles = (id, data) => {
  return api.patch(process.env.REACT_APP_Server_URL + "roles/" + id, {
    data
  }, { headers: authHeader() })
  .then(response => {
    if (response.data.accessToken) {
      //console.log(JSON.stringify(response.data));
    }

    return response.data;
  });
};

export const getUser = (userId) => {
  return api.get(process.env.REACT_APP_Server_URL + "users/"+ userId, { headers: authHeader() });
};
export const deleteUser = (id) => {
  return api.delete(process.env.REACT_APP_Server_URL + "users/"+ id, { headers: authHeader() });
};