import React, { useEffect, useRef, useState } from "react";
import "./custom.scroll.slider.scss";
import CustomButton from "./custom.button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export const CustomScrollSlider = ({
  children,
  containerHeight,
  setTouchDelta,
  cardWidth,
  hideArrow,
  className,
  containerWidth,
  disableAutoScroll,
}) => {
  const [autoScrollActive, setAutoScrollActive] = useState(disableAutoScroll);
  const sliderRef = useRef(null); // Ref for the slider container
  const autoScroll = () => {
    sliderRef.current.scrollLeft += cardWidth; // Scroll by a specific amount
    const container = document.getElementsByClassName(
      "scroll-slider-container"
    )[0];
    // Get the cardWidth of the container element
    const containerWidth = container.getBoundingClientRect().width;
    console.log(
      sliderRef.current.scrollLeft + containerWidth,
      sliderRef.current.scrollWidth
    );
    if (
      sliderRef.current.scrollLeft + containerWidth >=
      sliderRef.current.scrollWidth
    ) {
      // If reached the end or beyond, reset to the beginning
      sliderRef.current.scrollLeft = 0;
      // If reached the end or beyond, reset to the beginning
      setTimeout(() => {
        sliderRef.current.scrollTo({
          left: 0,
          behavior: "smooth",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    const autoScrollInterval = setInterval(() => {
      if (autoScrollActive) {
        autoScroll();
      }
    }, 9000);

    return () => {
      clearInterval(autoScrollInterval);
    };
  }, [autoScrollActive]);
  const handleUserInteraction = () => {
    setAutoScrollActive(false);
    // setTimeout(() => {
    //   setAutoScrollActive(true);
    // }, 3000);
  };

  const handleScroll = () => {
    // Add your scroll handling logic here
  };

  // Add event listeners to your slider container
  useEffect(() => {
    const container = sliderRef.current;

    container.addEventListener("scroll", handleScroll);
    container.addEventListener("touchstart", handleUserInteraction);
    container.addEventListener("mousedown", handleUserInteraction);

    return () => {
      container.removeEventListener("scroll", handleScroll);
      container.removeEventListener("touchstart", handleUserInteraction);
      container.removeEventListener("mousedown", handleUserInteraction);
    };
  }, []);

  const goNext = () => {
    sliderRef.current.scrollLeft += cardWidth; // Scroll by a specific amount
  };

  const goBack = () => {
    sliderRef.current.scrollLeft -= cardWidth; // Scroll back by a specific amount
  };

  return (
    <div
      ref={sliderRef}
      style={{
        height: containerHeight + "",
        // width: "800px",
        overflowX: "scroll",
      }}
      className="scroll-slider-container"
    >
      <div
        className={"slider-cards " + className}
        style={{
          height: containerHeight + "",
          width: containerWidth + "",
        }}
        //onTouchStart={handleTouchStart}
        //onTouchEnd={handleTouchEnd}
        // onTouchMove={handleTouchMove}
      >
        {children}
      </div>
      {!hideArrow && (
        <>
          <div className="buttonRight">
            <CustomButton
              id=""
              onClick={goNext}
              icon={<ArrowForwardIosIcon />}
              color={"#fff"}
              background={"#ff8717"}
              width={"20px"}
              border_radius={"50%"}
              margin={"0px 0px 0px 0px"}
              size={"20px"}
            ></CustomButton>
          </div>
          <div className="buttonLeft">
            <CustomButton
              id=""
              onClick={goBack}
              icon={<ArrowBackIosNewIcon />}
              color={"#fff"}
              background={"#ff8717"}
              width={"20px"}
              border_radius={"50%"}
              margin={"0px 0px 0px 0px"}
              size={"20px"}
            ></CustomButton>
          </div>
        </>
      )}
    </div>
  );
};
