import axios from "axios";
import { environment } from "../../environments/environment";
import authHeader from "../../services/auth-header";


export const listCategories = () => {
  return axios.get(process.env.REACT_APP_Server_URL + "categories", { headers: authHeader() });
};
export const postCategory = (categoryName, categoryParent) => {
  return axios.post(process.env.REACT_APP_Server_URL + "categories", {
    name:categoryName,
    parentId:categoryParent
  }, { headers: authHeader() })
  .then(response => {
    if (response.data.accessToken) {
      //console.log(JSON.stringify(response.data));
    }

    return response.data;
  });
};
export const getCategory = (categoryId) => {
  return axios.get(process.env.REACT_APP_Server_URL + "categories/"+ categoryId, { headers: authHeader() });
};
export const deleteCategory = (categoryId) => {
  return axios.delete(process.env.REACT_APP_Server_URL + "categories/"+ categoryId, { headers: authHeader() });
};