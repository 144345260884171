import React, { useState } from "react";
import "./models.section.products.scss";
import { Grid, useMediaQuery } from "@mui/material";
import CustomButton from "../../../../utils/custom.button";
import {
  CustomScrollSlider,
  CustomScrollSliderCard,
} from "../../../../utils/custom.scroll.slider";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const ModelsSectionProducts = (props) => {
  const [counter, setCounter] = useState(0);
  const [touchDelta, setTouchDelta] = useState(0);
  const [deactivateAutoScroll, setDeactivateAutoScroll] = useState(false);

  const theme = useTheme();
  let navigate = useNavigate();

  const moveToModel = (model) => {
    navigate("/products/models/" + model.id, { state: model });
    //console.log(`Product's ${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <section className="modelsSectionProducts" id="modelsSectionProducts">
      <h1>{props.productsData.models_section[0].title}</h1>
      <div className="modelsBackgroundContainer">
        <div className="modelsSectionProductsContainer">
          <CustomScrollSlider
            containerHeight="435px"
            containerWidth={
              (isSM ? "269" : isMD ? "269" : isLG ? "295" : "295") *
                props.productsData.models_section[0].model_list.length +
              "px"
            }
            cardWidth={"277"}
            setCounter={setCounter}
            counter={counter}
            touchDelta={touchDelta}
            setTouchDelta={setTouchDelta}
            deactivateAutoScroll={deactivateAutoScroll}
            setDeactivateAutoScroll={setDeactivateAutoScroll}
            hideArrow={isSM ? true : isMD ? false : isLG ? false : false}
          >
            {props.productsData.models_section[0].model_list.map((model, i) => (
              <div
                key={i}
                onClick={() => moveToModel(model)}
                style={{
                  width: isSM
                    ? "264px"
                    : isMD
                    ? "264px"
                    : isLG
                    ? "263px"
                    : "263px",

                  //height: "358px",
                  height: "290px",

                  margin: isSM
                    ? "13px 7px 13px 7px"
                    : isMD
                    ? "13px 11px 13px 11px"
                    : isLG
                    ? "13px 10px 13px 10px"
                    : "13px 12px 13px 12px",
                }}
                className={"modelCard"}
              >
                <img className="" alt="" title="image" src={model.image[0]} />
                <h2>{model.short_title}</h2>
                {model.properties && (
                  <>
                    {model.properties.slice(0, 2).map((property, index) => (
                      <h3 key={index}>
                        <span>{property.name}</span> {property.value}
                      </h3>
                    ))}
                  </>
                )}
                <CustomButton
                  id="header_main_section_learn_more"
                  onClick={() => moveToModel(model)}
                  label="LÄS MER"
                  width="120px"
                  height="30.521px"
                  size="11px"
                  color="#FFF"
                  background="#FF7817"
                  border="#ffff"
                  margin="22px 22px 22px 22px"
                />
              </div>
            ))}
          </CustomScrollSlider>
        </div>
      </div>
    </section>
  );
};

export default ModelsSectionProducts;
