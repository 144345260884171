import { Grid, Typography } from "@mui/material";


function SignatureOrderDetails(props) {
    return (
        <Grid
            style={{
                textAlign: "left"
            }}
        >
            <Grid>
                <Typography variant="h4" component="h4" color="initial">
                    Bonus:

                </Typography>
            </Grid>
            <Grid>
                <Typography variant="p" color="initial">
                    Som en bonus bjuder vi på två solpaneler samt kostnaden för byggnadsställning.
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"

                alignItems="left"
                style={{
                    paddingTop: "21pt",
                }}
            >
                <Grid item xs={6}>
                    <Typography variant="h3" component="h3">
                        Kund
                    </Typography>
                    <br />

                    <Typography variant="p" component="p" >
                        Personnr.
                    </Typography>
                    <br />

                    <Typography variant="p" component="p" >
                        Datum:
                    </Typography>
                    <br />
                    <Typography variant="p" component="p" >
                        Signatur:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                
                    <Typography variant="h3" component="h3">
                        Tolo Solar AB

                    </Typography>
                    <br />

                    <Typography variant="p" component="p" >
                        Khalid Afrid
                    </Typography>
                    <br />

                    <Typography variant="p" component="p" >
                        Datum:
                    </Typography>
                    <br />
                    <Typography variant="p" component="p" >
                        Signatur:
                    </Typography>
                </Grid>
            </Grid>

            <Grid style={{
                paddingTop: "25pt",
            }}>
                <Typography variant="p" color="initial">
                    *Vi förbehåller oss rätten att fakturera skattereduktionen om begäran från Skattverket avslås.
                </Typography>
                <Grid>
                    <hr />
                </Grid>
            </Grid>


            <Grid
             container
             direction="row"
             justifyContent="center"
             alignItems="flex-start"
                style={{
                    paddingTop: "0pt",
                    paddingBottom: "25pt"

                }}
            >
                <Grid item xs={4}>
                    <Typography variant="p" component="p">
                        Adress
                    </Typography>
                    <Typography variant="p" component="p">
                    Tolo Solar AB

                    </Typography>
                    <Typography variant="p" component="p">
                    Sporregatan 23

                    </Typography>
                    <Typography variant="p" component="p">
                    213 77 Malmö

                    </Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography variant="p" component="p">
                Telefon
                    </Typography>
                    <Typography variant="p" component="p">
                    020-89 94 88
                    </Typography>
                    <Typography variant="p" component="p">
                    E-post

                    </Typography>

               
                    <a
                        href="mailto:E-post.hello@tolosolar.se"
                        className="a"
                        target="_blank" rel="noreferrer"
                        variant="p" component="p"
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        hello@tolosolar.se
                    </a>
                    <Typography variant="p" component="p">
                    Webbadress
                    </Typography>
                    <a href="http://www.tolosolar.se/" variant="p" component="p" className="a" target="_blank" rel="noreferrer" style={{
                        textDecoration: 'none',
                    }}>
                        www.tolosolar.com
                    </a>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="p" component="p">
                        Organisationsnr
                    </Typography>
                    <Typography variant="p" component="p">
                    559403-0933
                    </Typography>
                    <br />
                    <Typography variant="p" component="p">
                    Momsreg.nr

                    </Typography>
                    <Typography variant="p" component="p">
                    SE559403093301
                    </Typography>
                    <Typography variant="p" component="p">
                    Godkänd för F-skatt
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SignatureOrderDetails;