import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import "./projects.component.scss";

import jsonProjectsData from "../../../json/projects.page.json";

import MainSectionProjects from "./main/main.section.projects";
import FAQsSectionProjects from "./FAQs/FAQs.section.projects";
import ListProjects from "./list/list.projects.component";

const ProjectsPage = () => {
  const [projectsData, setProjectsData] = useState(jsonProjectsData);

  useEffect(() => {
    setProjectsData(jsonProjectsData);
  }, []);
  return (
    <div className="ProjectsPage">
      {/* <MainSectionProjects projectsData={projectsData} />
      <Container fixed>
        <StockSectionProjects projectsData={projectsData} />
        <WhySectionProjects projectsData={projectsData} />
        <ModelsSectionProjects projectsData={projectsData} />
        <GallerySectionProjects projectsData={projectsData} />
        <FAQsSectionProjects projectsData={projectsData} />
      </Container> */}
      <MainSectionProjects projectsData={projectsData} />
      <div
        style={{
          backgroundColor: "white",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <Container fixed>
          {projectsData.list_section && (
            <ListProjects projectsData={projectsData} />
          )}
          {projectsData.FAQs_section && (
            <FAQsSectionProjects projectsData={projectsData} />
          )}
          {/* <GallerySectionProjects projectsData={projectsData} /> */}
        </Container>
      </div>
    </div>
  );
};

export default ProjectsPage;
