import React from "react";
// authentication
import Login from "./components/login/login.component";
import Register from "./components/register/register.component";

// pages
//import home from "./components/home.component";
import Profile from "./components/profile/profile.component";
// home pages
import AdminPage from "./components/home/admin.component";
import CompanyPage from "./components/home/company.component";
import EmployeePage from "./components/home/employee.component";
import InstallerPage from "./components/home/installer.component";
import PrivateCustomerPage from "./components/home/privateCustomer.component";
import SuperUserPage from "./components/home/superuser.component";

import { Routes, Route } from "react-router-dom";
import UserSearch from "./components/users/users.component";
import CategoriesSearch from "./components/category/search/categories.search.component";
import ResetPassword from "./components/password/reset.password.component";
import ResetPasswordRequest from "./components/password/reset.password.request.component";
import UsernameRequest from "./components/password/username.request.component";
import ProductSearch from "./components/product/search/products.search.component";
import ShowProduct from "./components/product/products/products.component";
import FeedbackPage from "./components/feedback/feedback.component";
import EditProfile from "./components/profile/edit/edit.profile.component";
import UserDetails from "./components/users/details/user.detail.component";
import CartPage from "./components/cart/cart.component";
import OrdersPage from "./components/order/orders.component";
import OrderDetails from "./components/order/detailes/details.order.component";
import ProjectAdd from "./components/project/add/project.add.component";
import HomePage from "./components/home/home.component";
import ServiceAddForm from "./components/service/add/service.add.form.component";
import AddService from "./components/service/add/service.add.component";
import AboutPage from "./components/about/about.component";
import ContactPage from "./components/contact/contact.component";
import TermsPage from "./components/terms/terms.component";
import JobsPage from "./components/jobs/jobs.component";
import PolicyPage from "./components/policy/policy.component";
import CookiesPage from "./components/cookies/cookies.component";
import ShowService from "./components/service/details/service.component";
import FAQsPage from "./components/faq/fAQs.component";
import ProjectRequited from "./components/project/add/requited/project.requited.component";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import Products from "./components/product/products/products.component";
import Services from "./components/service/services/services.component";
import ModelPageProducts from "./components/product/products/models/page/model.page.products";

import ScrollToTop from "./app.scroll.top";
import ProjectsPage from "./components/project/projects/projects.component";
import ProjectDetails from "./components/project/details/project.details.component";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqZrkih2rMb8NCJT0hRnN74py7vOfsUDc",
  authDomain: "tolosolar.firebaseapp.com",
  projectId: "tolosolar",
  storageBucket: "tolosolar.appspot.com",
  messagingSenderId: "995890089696",
  appId: "1:995890089696:web:78328fb5bdac836ad14a85",
  measurementId: "G-6411NQTBQ4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function AppRouts() {
  return (
    <>
        <ScrollToTop />
        <Routes basename="">
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      {/* <Route path="/home" element={<home />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/password/reset/request"
        element={<ResetPasswordRequest />}
      />
      <Route path="/password/reset" element={<ResetPassword />} />
      <Route path="/username/request" element={<UsernameRequest />} />
      <Route path="/profile" element={<EditProfile />} />
      <Route path="/profile/edit" element={<EditProfile />} />
      {/* <Route path="/products" element={<Products />} /> */}
      <Route path="/products/:id" element={<Products />} />
      <Route path="/products/:models/:id" element={<ModelPageProducts />} />
      <Route path="product/add" element={<ProductSearch />} />
      <Route path="project/add" element={<ProjectAdd />} />
      <Route path="project/requited" element={<ProjectRequited />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/projects/:id" element={<ProjectDetails />} />

      <Route path="service/add" element={<AddService />} />
      <Route path="Services" element={<Services />} />
      <Route path="Services/:id" element={<Services />} />
      <Route path="product/:id" element={<ShowProduct />} />
      <Route path="categories/search" element={<CategoriesSearch />} />
      <Route path="/users/search" element={<UserSearch />} />
      <Route path="/users/:id" element={<UserDetails />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/orders/:id" element={<OrderDetails />} />
      <Route path="/feedback" element={<FeedbackPage />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/company" element={<CompanyPage />} />
      <Route path="/employee" element={<EmployeePage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/cookies" element={<CookiesPage />} />
      <Route path="/policy" element={<PolicyPage />} />
      <Route path="/FAQs" element={<FAQsPage />} />

      <Route path="/jobs" element={<JobsPage />} />
      {/* <Route path="/home" element={<homePage />} /> */}
      <Route path="/installer" element={<InstallerPage />} />
      <Route path="/privateCustomer" element={<PrivateCustomerPage />} />
      <Route path="/superuser" element={<SuperUserPage />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
    </>

  );
}

export default AppRouts;
