import "./main.quote.scss";

const MainQuote = (props) => {
  return (
    <section className="mainQuotePage">
      <div className="imageQuoteBackgroundImage">
        <img alt="" title="image" src={props.quoteData.main_section[0].image} />
      </div>
      <div className="quotePageSecondTitle">
        <h1>{props.quoteData.main_section[0].title}</h1>
        <h2>{props.quoteData.main_section[0].title2}</h2>
      </div>
    </section>
  );
};

export default MainQuote;
