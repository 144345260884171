// import { getProducts } from "../../services/product.service";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

//import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import ProductCard from "./product.card.component";

const SearchResult = (props) => {
  return (
    <>
      {props.results && (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/*<Box>
            <Paper*
              sx={{
                p: 2,
                margin: 'auto',
                // maxWidth: 500,
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }} >*/}
          {props.results?.map((product, index) => (
            <ProductCard product={product} index={index} key={index} />
          ))}
          {/* </Paper>
          </Box> */}
        </Grid>
      )}

      {!props.results && (
        <Box>
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              // maxWidth: 500,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <p>Det finns inget resultat för att visa!</p>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default SearchResult;
