import { Grid } from '@mui/material';
import CustomChip from './custom.chip.component';
function UserPersonalDetails(props) {
  return (
      <Grid container direction="row"
        justifyContent="flex-start"
        alignItems="center">
        <Grid item lg={6} xs={12} sx={{  paddingX: { sm: '1rem' } }}>
          <CustomChip setValue={props.setUser} name="address" label={"Address 1: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="postNumber" label={"Post Number: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="city" label={"City: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="country" label={"Country: "} value={ props.user} />
        </Grid>
        <Grid item lg={6} xs={12} sx={{  paddingX: { sm: '1rem' } }}>
          <CustomChip setValue={props.setUser} name="secondaryAddress" label={"Address 2: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="secondaryPostNr" label={"Post Number: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="secondaryCity" label={"City: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="secondaryCountry" label={"Country: "} value={ props.user} />
        </Grid>
      </Grid>
  );
}

export default UserPersonalDetails;