import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
const isImage = (url) => {
  return (
    url.toLowerCase().endsWith(".jpg") ||
    url.toLowerCase().endsWith(".png") ||
    url.toLowerCase().endsWith(".gif")
  );
};

const isVideo = (url) => {
  return (
    url.toLowerCase().endsWith(".mp4") ||
    url.toLowerCase().endsWith(".avi") ||
    url.toLowerCase().endsWith(".mov")
  );
};
const MediaCards = ({ cards, setDisableScroll }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);

  const openModal = (mediaUrl) => {
    setDisableScroll(true);
    setSelectedMedia(mediaUrl);
  };

  const closeModal = () => {
    setSelectedMedia(null);
  };

  const renderMedia = (item) => {
    if (isImage(item)) {
      return (
        <img
          className="gallerySectionImg"
          src={item}
          alt={item.alt}
          onClick={() => openModal(item)}
        />
      );
    } else if (isVideo(item)) {
      return (
        <video
          className="gallerySectionImg"
          autoPlay
          loop
          muted
          playsInline
          style={{ transform: "translateZ(0)" }}
          onClick={() => openModal(item)}
        >
          <source src={item} type="video/mp4" />
          <source src={item} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      );
    }
  };

  return (
    <div>
        <Grid className="imageGallerySlider">
          {renderMedia(cards )}
        </Grid>
      <Dialog
        open={!!selectedMedia}
        onClose={closeModal}
        maxWidth="md"
        fullWidth // Add fullWidth to make sure the modal takes up the full width of the viewport
        PaperProps={{
          style: { margin: "0px", padding: "0px" },
        }}
      >
        <>
          {selectedMedia && (
            <>
              {isImage(selectedMedia) ? (
                <img
                  src={selectedMedia}
                  alt="Preview"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "100vh", // Set a maximum height for the image
                    objectFit: "contain", // Adjust the object-fit property as needed
                  }}
                />
              ) : (
                <video
                  src={selectedMedia}
                  controls
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "100vh", // Set a maximum height for the video
                    objectFit: "contain", // Adjust the object-fit property as needed
                  }}
                />
              )}

              {/* Close icon positioned on the top-right corner of the container */}
              <IconButton
                edge="end"
                onClick={closeModal}
                aria-label="close"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 20,
                  zIndex: 1,
                  backgroundColor: "rgb(161, 169, 181, .5)",
                }}
              >
                <CloseIcon
                  style={{
                    color: "white",
                    "&:hover": {
                      color: "#FF8718",
                    },
                  }}
                />
              </IconButton>
            </>
          )}
        </>
      </Dialog>
    </div>
  );
};

export default MediaCards;
