import React, { useState, useEffect } from "react";

import SectionTwo from "./sections/section.two.home";

import "./style.css";
import GallerySectionHome from "./sections/gallery/gallery.section.home";
import AnimatedSection from "./sections/animated.section.home";
import WebBannerSection from "./sections/web.banner.section.home";
import AOS from "aos";
import "aos/dist/aos.css";
import jsonHomeData from "../../json/home.page.json";
import { Container, useMediaQuery } from "@mui/material";
import ProjectSectionHome from "./sections/project/project.section.home";
import ReasonSectionHome from "./sections/reason/reason.section.home";
import ProductSectionHome from "./sections/product/product.section.home";
import RatingSectionHome from "./sections/rating/rating.section.home";
import ServiceSectionHome from "./sections/service/service.section.home";
import MainSectionHome from "./sections/main/main.section.home";
import { useTheme } from "@emotion/react";

const HomePage = () => {
  const [homeData, setHomeData] = useState(jsonHomeData);
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  React.useLayoutEffect(() => {
    const lastSection = document.querySelector(".lastSection");
    if (isMD) {
      lastSection.style.display = "none";
    }
    return () => {
      lastSection.style.display = ""; // Reset the display property
    };
  }, []);
  React.useEffect(() => {
    AOS.init({ duration: 2000 });
    // AOS.refresh();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MainSectionHome homeData={homeData} />
      <div style={{ backgroundColor: "white" }}>
        <Container fixed>
          <div data-aos="fade-up">
            <SectionTwo homeData={homeData} />
          </div>
        </Container>

        <div data-aos="fade-up">
          <ServiceSectionHome homeData={homeData} />
        </div>

        <Container fixed>
          <div data-aos="fade-up">
            <ProductSectionHome homeData={homeData} />
          </div>
          <div data-aos="fade-up">
            <ProjectSectionHome homeData={homeData} />
          </div>

          <div data-aos="fade-up">
            <GallerySectionHome homeData={homeData} />
          </div>
          <div data-aos="fade-up">
            <ReasonSectionHome homeData={homeData} />
          </div>
          <div>
            <RatingSectionHome homeData={homeData} />
          </div>
          <div data-aos="fade-up">
            <WebBannerSection />
          </div>
          {/*
           */}
          <div>
            <AnimatedSection homeData={homeData} />
          </div>
        </Container>
      </div>

      {/* <SectionFour /> */}
      {/* <SectionFive /> */}
      {/* <SectionSix />  */}
      {/* {products.map(item => (
             <div key={item.id}>
            <ProductCard {...item} />
            </div>
          ))}  */}
    </>
  );
};

export default HomePage;
