import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomLink from "../../utils/custom.link";

import { Container, Grid, Box, Typography, Stack } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormikRegister from "./formik.register.component";

const theme = createTheme();

const Register = () => {
  const loginList = [
    { label: "Se priser på produkter!" },
    { label: "Kontrollera lagersaldo!" },
    { label: "Kontrollera order!" },
    { label: "Kontrollera fakturor!" },
    { label: "Självskanning i butik!" },
    { label: "Skapa egna produktlistor!" },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{ paddingTop: { sm: "3rem" }, backgroundColor: { xs: "#fff" } }}
      >
        <Grid
          container
          sx={{
            // boxShadow: { sm: '0 0 5px #ddd' },
            py: "112px",
            px: "1rem",
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            rowSpacing={5}
            sx={{
              maxWidth: { sm: "45rem" },
              marginInline: "auto",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ borderRight: { sm: "1px solid #ddd" } }}
            >
              <Box
                display="flex"
                flexDirection="column"
                noValidate
                autoComplete="off"
                sx={{ paddingRight: { sm: "3rem" } }}
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{
                    mb: "1.5rem",
                  }}
                >
                  Fördelar med en inloggning:
                </Typography>

                <Box display="flex" flexDirection="column">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 500,
                      bgcolor: "background.paper",
                    }}
                  >
                    {loginList.map((item) => (
                      <ListItem key={item.label} sx={{ py: 0, minHeight: 32 }}>
                        <ListItemIcon sx={{ color: "inherit" }}>
                          <CheckIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.label}
                          primaryTypographyProps={{
                            fontSize: 14,
                            fontWeight: "medium",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Grid container justifyContent="center">
                  <Stack sx={{ mt: "3rem", textAlign: "center" }}>
                    <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
                      Har du redan ett konto?{" "}
                      <CustomLink id="" to="/login">
                        logga in
                      </CustomLink>
                    </Typography>
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      Glömde din{" "}
                      <CustomLink id="" to="/password/reset/request">
                        Lösenord?
                      </CustomLink>
                    </Typography>
                  </Stack>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                sx={{
                  paddingLeft: { sm: "3rem" },
                  mb: "1.5rem",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{
                    mb: "1.5rem",
                    textAlign: "center",
                  }}
                >
                  Registrera konto
                </Typography>
                <FormikRegister />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Register;
