import { Grid, useMediaQuery } from "@mui/material";
import "./list.projects.component.scss";
import React from "react";
import CustomButton from "../../../../utils/custom.button";
import CustomLink from "../../../../utils/custom.link";
import { useTheme } from "@emotion/react";
import SmallCardListProjects from "./small.card.list.projects.component";
import LargeCardListProjects from "./large.card.list.projects.component";

const ListProjects = (props) => {
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div className="listProjectPage">
        {/* <h1>
          VÅRA PROJEKT
          <CustomLink to="/projects">{"Visa Alla  >"}</CustomLink>
        </h1> */}
        {/* <hr /> */}
        {props.projectsData.list_section.map((project, i) => (
          <React.Fragment key={i}>
            {isMD && <SmallCardListProjects project={project} />}
            {!isMD && <LargeCardListProjects project={project} />}
          </React.Fragment>
        ))}
        {!isMD && <hr />}
      </div>
    </>
  );
};

export default ListProjects;
