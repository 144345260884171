import Button from "@mui/material/Button";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

const ProductAddBar = ( props, onClick ) => {    
    const Close = () => {
        props.handleClose(false);
    };
    const Submit = () => {
        props.handleSubmit(false);
    };
    return (
        <>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={Close}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add Product
                </Typography>
                <Button autoFocus color="inherit" onClick={Submit}>
                    save
                </Button>
            </Toolbar>
        </AppBar>
        </>
    );
}
export default ProductAddBar;
