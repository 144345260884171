// import { getProducts } from "../../services/product.service";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
//import Stack from '@mui/material/Stack';
import { Grid, Typography } from "@mui/material";
import CustomSelectField from "../../../../utils/custom.select.field";

import { ErrorMessage, Field } from "formik";
import CustomRangeSlider from "../../../../utils/custom.range.slider";
import CustomSlider from "../../../../utils/custom.slider";
import CustomFieldFormik from "../../../../utils/custom.field.formik";

const SearchSidebar = (props) => {
  //props.setCategory(props.formik.values.categories);

  //props.setCategory
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Sök</h1>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="p"
          sx={{
            paddingLeft: { sm: "0rem" },
            fontSize: { xs: "1.2rem", sm: "1rem" },

            marginTop: "10px",
          }}
        >
          Pris:
        </Typography>
        <Field
          name="price"
          label="Pris"
          value={props.value}
          component={CustomRangeSlider}
          set_value={props.set_price}
        />
        <ErrorMessage component="span" className="error-message" name="price" />
      </Grid>

      <Grid item xs={12}>
        {/* <Field
            name="Make"
            type="text"
            label="material"
            component={CustomFieldFormik}
            set_value={props.set_make}

          />
          <ErrorMessage component="span" className="error-message"name='Make' />
           */}
        <Field
          id="height"
          name="height"
          label="Höjd"
          component={CustomSlider}
          set_value={props.set_height}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="height"
        />
        <Field
          id="width"
          name="width"
          label="Bredd"
          component={CustomSlider}
          set_value={props.set_width}
        />
        <ErrorMessage component="span" className="error-message" name="width" />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={CustomSelectField}
          onChange={props.formik.handleChange}
          onBlur={props.formik.handleBlur}
          type="select"
          name="category"
          label="kategori namn"
          categories={props.categories}
          set_Category={props.set_category}
          //options={Categories.map((option) => option.name)}
          formik={props.formik}
          placeholder="kategori namn"
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="category"
        />
      </Grid>
    </Grid>
  );
};

export default SearchSidebar;
