import { Grid, Typography } from "@mui/material";

function FooterOrderDetails(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      {" "}
      <Grid
        item
        xs={1}
        style={{
          paddingRight: "10pt",
        }}
      >
        <img alt="Tolo Solar" src={"/logo.png"} />
      </Grid>
      <Grid
        item
        xs={11}
        style={{
          textDecoration: "none",
          color: "black",
          fontWeight: "bold",

          backgroundColor: "#FEC44C",
        }}
      >
        <Typography
          variant="p"
          component="p"
          style={{
            paddingLeft: "13pt",
            paddingRight: "13pt",
            fontSize: "15px",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          <span className="s12" style={{ backgroundColor: "#FFC000" }}>
            {" "}
          </span>
          <a
            href="http://www.tolosolar.se/"
            className="a"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Tolo Solar AB | Org.nr. 559403-0933 |{" "}
          </a>
          <a
            href="mailto:E-post.hello@tolosolar.se"
            className="a"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            www.tolosolar.se | Tel. 020-89 94 88 | E-post. hello@tolosolar.se
          </a>
          <a
            href="mailto:E-post.hello@tolosolar.se"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default FooterOrderDetails;
