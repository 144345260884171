import { Grid } from "@mui/material";
import CustomButton from "../../../../utils/custom.button";
import "./main.section.home.scss";
import React from "react";

const DesktopMainSectionHome = (props) => {
  return (
    <>
      <div className="videoContainer">
        <video
          src={props.homeData.main_section[0].video}
          type="video/mp4"
          className="mainSectionVideo"
          autoPlay
          loop
          muted
          playsInline
          style={{ transform: "translateZ(0)" }}
        />
      </div>
      {/* <div className="mainSectionImageMask"></div> */}
      {/* <img
        className="mainSectionImage"
        alt=""
        title="image"
        src={props.homeData.main_section[0].videoGif}
      /> */}
      {/* <img
        className="mainSectionImage"
        alt=""
        title="image"
        src={props.homeData.main_section[0].image}
      />*/}

      <div className="mainSectionContainer">
        <h1 className="mainTitle">
          {props.homeData.main_section[0].title[0]}
          <span> {props.homeData.main_section[0].title[1]} </span>
        </h1>
        <h1 className="mainTitle">{props.homeData.main_section[0].title[2]}</h1>

        <p className="mainSubTitle">
          {props.homeData.main_section[0].description}
        </p>
        <Grid container spacing={2}>
          <Grid item>
            <CustomButton
              id="header_main_section_get_a_quote"
              onClick={props.projectAdd}
              label="FÅ EN OFFERT"
              size="17px"
              width="209px"
              height="46px"
              color="#ffffff"
              background="#FF8718"
            />
          </Grid>
          <Grid item>
            <CustomButton
              id="header_main_section_learn_more"
              onClick={props.learnMore}
              label="LÄS MER"
              width="154px"
              height="46px"
              color="#F5F2EB"
              background="transparent"
              border="#ffff"
            />
          </Grid>
        </Grid>
      </div>
      <div className="mainSolenergi">
        <img
          alt=""
          title="image"
          src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/solenergi.svg"
        />
      </div>
      {/* <AddProject closeProjectAdd={closeProjectAdd} openProjectAdd={openProjectAdd} /> */}
    </>
  );
};

export default DesktopMainSectionHome;
