import { useState, useEffect } from 'react';


import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import eventBus from '../../../common/EventBus';
import { getUser } from '../users.service';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled'
import Theme from '../../../utils/theme';
import { Box, Tabs, Typography, Tab, Stack, Divider, IconButton, Avatar, Card, Grid, Chip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import EditUser from '../edit/user.edit.component';
import CustomChip from './custom.chip.component';
import UserPersonalDetails from './user.detail.personal.component';
import UserHistory from './user.detail.history.component';
import UserOrders from './user.detail.orders.component';
import UserStatus from './user.status.component';

const GridStyled = styled(Grid)({
  minHeight: '60vh',
});

const TitleStyled = styled(Typography)({
  fontWeight: 500,
});
const TitleItem = styled(Typography)({
  marginTop: Theme.spacing(2),
});

const ChipStyled = styled(Chip)({
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: 'content-box',
          padding: 3,
          fontSize: '1.125rem',
        },
      },
    },
  },
});

function UserDetails(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState('2');
  const [user, setUser] = useState([]);
  const [orders, setOrders] = useState([]);
  const [username, setUsername] = useState('');
  const [roles, setRoles] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    getUser(id).then(
      (response) => {
        console.log(response);
        setUser(response.data.user);
        setUsername(response.data.user['username']);
        setRoles(response.data.roles);
        setOrders(response.data.orders);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setUser(_content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
      }
    );
  }, [id]);
  const handleChange = (event, newValue) => {

    setValue(newValue);
  };
  const handleEdit = (event) => {

  };

  return (
    <>
      <Card sx={{ margin: { sm: '3rem' }, padding: { sm: '1rem' } }}>
        <Box sx={{ p: 1, display: 'flex' }}>
          <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item lg={1} md={2} sm={2} xs={4}>
              <Avatar variant="rounded" sx={{ width: 70, height: 70 }}>{username.toUpperCase().charAt(0)}</Avatar>

            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={4}>
              <Typography fontWeight={700}>{" " + user.firstName + " " + user.lastName} </Typography>
              <Chip label={user.accountStatus + " - " + roles[0] + ""} size="small" />

              <Typography variant="body2" color="text.secondary">
                <LocationOnIcon sx={{ color: 'grey' }} />{user.city + ", " + user.country}
              </Typography>
            </Grid>

            <Grid item lg={7} md={6} sm={4} xs={1}>
            </Grid>

            <Grid item lg={1} justifyContent="flex-end">
              <Grid>
                {/* <EditUser />                 */}

                <UserStatus user={user} setUser={setUser} setRoles={setRoles} />

              </Grid>
            </Grid>
          </Grid>

        </Box>
        <Divider />
        <GridStyled
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs variant="fullWidth" value={value} onChange={handleChange} centered aria-label="lab API tabs example">
                <Tab value="1" label="Order" />
                <Tab value="2" label="Personlig information" />
                <Tab value="3" label="ADRESS" />
              </Tabs>
            </Box>
            <TabPanel value="1">
              <UserOrders orders={orders} setOrders={setOrders} />
            </TabPanel>
            <TabPanel value="2">
              <UserPersonalDetails user={user} setUser={setUser} />
            </TabPanel>
              <TabPanel value="3"><UserHistory user={user} setUser={setUser} />
            </TabPanel>
          </TabContext>
        </GridStyled>

      </Card>

    </>
  );
}

export default UserDetails;