import React, { useEffect, useState } from "react";
import "./model.page.products.scss";
import { Container, Grid, useMediaQuery } from "@mui/material";

import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../utils/custom.button";
import CustomBackButton from "../../../../../utils/custom.back.button";
import axios from "axios";
import CustomLink from "../../../../../utils/custom.link";
import AOS from "aos";
import "aos/dist/aos.css";
const ModelPageProducts = (props) => {
  const [modelData, setModelData] = useState();

  const theme = useTheme();
  let navigate = useNavigate();
  const { models, id } = useParams();
  const location = useLocation();
  const { state } = location;
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const lastSection = document.querySelector(".lastSection");
    lastSection.style.display = "none";
  }, []);
  const contactButton = (id) => {
    navigate("/contact");
    //console.log(`Product's ${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const downloadPdf = async (state) => {
    try {
      const response = await axios.get(state.pdf, {
        responseType: "arraybuffer", // Set the response type to 'arraybuffer'
      });

      const buffer = response.data; // Get the ArrayBuffer object
      const url = window.URL.createObjectURL(
        new Blob([buffer], { type: "application/pdf" })
      ); // Create a URL from the ArrayBuffer object

      // Set the current window location to the PDF file URL
      window.location = url;
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <section className="modelPage">
      <CustomBackButton
        id="header_main_section_learn_more"
        label={"Solpaneler"}
        section={"modelsSectionProducts"}
        width="211px"
        height="47px"
        size="20px"
        color="#FF7817"
        background="#fffff"
        border="#ffff"
        margin="22px 22px 22px 22px"
        container
        fixed
      ></CustomBackButton>
      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", alignItems: "", justifyContent: "center" }}
        >
          <img
            className=""
            alt=""
            title="image"
            src={state.image[0]}
            style={{ width: "70%", height: "70%", objectFit: "scale-down" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="mainTitleModel">
            <h1>{state.title}</h1>
            {/* <h3>
              <span>{state.type[0] + " "}</span>
              {state.type[1]}
            </h3> */}
          </div>
          {state.description.map((desc, i) => (
            <p>{desc}</p>
          ))}
          <p>
            {state.moreLink && (
              <a href={state.moreLink} id="header_main_section_learn_more">
                läs mer
              </a>
            )}
          </p>
          {state.properties && (
            <div className="propertiesBoxModel">
              {state.properties.map((property, i) => (
                <p>
                  <span>{property.name + " "}</span>
                  {property.value}
                </p>
              ))}
            </div>
          )}
          <Grid
            className="mainSubButtons"
            style={{ margin: "66px 0px 33px 0px" }}
            container
            direction="row"
            justifyContent={isSM ? "center" : "left"}
            alignItems="left"
          >
            <CustomButton
              id="contact_button_product_model_page"
              onClick={() => contactButton(state.id)}
              label="Kontakta oss"
              width="181px"
              height="57px"
              size="14px"
              color="#FFF"
              background="#FF7817"
              border="#ffff"
              // margin="22px 22px 22px 22px"
            />
            {state.pdf && (
              <CustomButton
                id="datablad_button_product_model_page"
                onClick={() => downloadPdf(state)}
                label="Datablad"
                width="181px"
                height="57px"
                size="14px"
                color="#000000"
                background="transparent"
                border="#000000"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default ModelPageProducts;
