import React, { useEffect, useState, useRef } from "react";
import DropDownMenu from "./drop.down.menu";
import CustomLink from "../../../utils/custom.link";
import { useLocation } from "react-router-dom";

function HeaderMenu(props) {
  const location = useLocation();

  const [indicatorLocation, setIndicatorLocation] = useState(0);
  const [menuHovered, setMenuHovered] = useState(false);
  const indicatorRef = useRef(null);

  useEffect(() => {
    let notMenu = true;

    const currentPath = location.pathname;
    const menuLinks = document.querySelectorAll(".menuLinks .menuLink");
    const menuMarker = indicatorRef.current;
    //console.log( menuHovered);

    if(menuHovered){
      const link=menuLinks[menuHovered]
    
      //console.log(link, menuHovered, link.offsetLeft, link.offsetTop);
      notMenu = false
       menuMarker.style.left = link.offsetLeft + "px";
        menuMarker.style.width = link.offsetWidth + "px";
    }
    else{
      menuLinks.forEach((link) => {
        //console.log(link)
        const linkUrl = new URL(link["href"]);
        const path = linkUrl.pathname;
        const hash = linkUrl.hash;
        const fullPath = path + hash;
        const currentURL = new URL(window.location.href);
        const section = currentURL.hash.substring(1);
        //console.log(fullPath, currentPath + "#" + section);
        if (
          fullPath === currentPath + "#" + section ||
          fullPath === currentPath ||
          (fullPath.includes("product") && currentPath.includes("product")) ||
          (fullPath.includes("service") && currentPath.includes("service"))
        ) {
          menuMarker.style.left = link.offsetLeft + "px";
          menuMarker.style.width = link.offsetWidth + "px";
          notMenu = false;
          //console.log("jihad");
          // console.log(link);
        }
      });
    }

    if (notMenu) {
      menuMarker.style.left = 0 + "px";
      menuMarker.style.width = 0 + "px";
    }
  }, [location.pathname, menuHovered]);
  // const indicatorLeave = () => {
  //   const logo = document.querySelectorAll(".leftBar img")[0];
  //   const menuMarker = indicatorRef.current;

  //   setIndicatorLocation(0); // Reset the indicator location

  //   menuMarker.style.left = "0";
  //   menuMarker.style.width = logo.offsetWidth + "px";
  //   if (logo.offsetWidth === 0) {
  //     menuMarker.style.width = "111px";
  //   }
  // };
  return (
    <React.Fragment>
      <div className="menuLinks">
        {/* Use the indicatorRef to create the indicator element */}
        <div id="menuMarker" ref={indicatorRef}></div>
        {props.headerData.menu.map((menu, index) =>
          menu.subMenu ? (
            <DropDownMenu
            key={index}
            index={index}
            id={menu.id}
              to={menu.to}
              label={menu.label}
              location={location}
              subMenu={menu.subMenu}
              //isActive={isDropMenuActive}
              section={menu.section}
              indicatorRef={indicatorRef}
              setMenuHovered={setMenuHovered}
            />
          ) : (
            <CustomLink
              key={index}
              index={index}

              className={"menuLink"}
              id={menu.id}
              to={menu.to}
              setMenuHovered={setMenuHovered}
              // indicatorLeave={indicatorLeave}
            >
              {menu.label}
            </CustomLink>
          )
        )}
      </div>
    </React.Fragment>
  );
}

export default HeaderMenu;
