import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import CustomField from "../../utils/custom.field";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const FormikResetPasswordRequest = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");

  // SubmitData.Post({
  //   email: '',
  // })
  // form props
  function SubmitData(formValue) {
    setLoading(true);
    setMessage("");
    setSuccess("");

    const { email } = formValue;
    console.log(email);
    AuthService.requestPassword(email).then(
      (success) => {
        setSuccess(success);
        console.log(success);
        //navigate('/login')
        //window.location.reload()
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  }
  const formikProps = {
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Detta fält är obligatoriskt!"),
    }),
    onSubmit: (values) => {
      console.log(values);
      SubmitData(values);
    },
  };

  return (
    <Formik {...formikProps}>
      <Form>
        <Field
          name="email"
          type="text"
          label="E-postadress"
          required={true}
          component={CustomField}
        />
        <ErrorMessage component="span" className="error-message" name="email" />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          Skicka in
        </Button>
        {success && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {success} — <strong>check it out!</strong>
          </Alert>
        )}
      </Form>
    </Formik>
  );
};

export default FormikResetPasswordRequest;
