import axios from "axios";
import authHeader from "./auth-header";

export const getShopService = () => {
  return axios.get(process.env.REACT_APP_Server_URL, { headers: authHeader() });
};
export const getSuperUserService = () => {
  return axios.get(process.env.REACT_APP_Server_URL + "superuser", {
    headers: authHeader(),
  });
};
export const getAdminService = () => {
  return axios.get(process.env.REACT_APP_Server_URL + "admin", {
    headers: authHeader(),
  });
};
export const getInstallerService = () => {
  return axios.get(process.env.REACT_APP_Server_URL + "installer", {
    headers: authHeader(),
  });
};
export const getEmployeeService = () => {
  return axios.get(process.env.REACT_APP_Server_URL + "employee", {
    headers: authHeader(),
  });
};
export const getCompanyService = () => {
  return axios.get(process.env.REACT_APP_Server_URL + "company", {
    headers: authHeader(),
  });
};
export const getPrivateCustomerService = () => {
  return axios.get(process.env.REACT_APP_Server_URL + "privateCustomer", {
    headers: authHeader(),
  });
};
