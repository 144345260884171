import React, { useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  DrawingManager,
  InfoWindow,
  InfoWindowF,
} from "@react-google-maps/api";
import { Autocomplete } from "@react-google-maps/api";
import CustomField from "../../../utils/custom.field";
import { Button, Grid, TextField } from "@mui/material";

const libraries = ["places", "drawing"];

const AddProjectMap = ({ field, form, apiKey }) => {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 55.562923,
    lng: 13.072238,
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: 55.562923,
    lng: 13.072238,
  });
  const [headingValue, setHeadingValue] = useState(0);
  const [drawingManagerLoaded, setDrawingManagerLoaded] = useState(false);
  const [mapTypeId, setMapTypeId] = useState("Map");
  const [hideTilt, setHideTilt] = useState(1);
  const [zoom, setZoom] = useState(30);
  const [polygon, setPolygon] = useState(null);
  const mapRef = useRef(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [infoWindowContent, setInfoWindowContent] = useState();

  const autocompleteRef = useRef(null);
  const mapStyles = [
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const handleAutocompleteSelect = async (address) => {
    try {
      const results = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
      ).then((res) => res.json());
      console.log(results);
      if (
        results.results[0] &&
        results.results[0].geometry &&
        results.results[0].geometry.location
      ) {
        const { lat, lng } = results.results[0].geometry.location;
        mapRef.current.setCenter({ lat, lng });
        mapRef.current.setZoom(30);
        //setMarkerPosition({ lat, lng });
        //mapRef.current.panTo({ lat, lng }); // Pan the map to the new location

        setSelectedLocation({ lat, lng });
        form.setFieldValue(field.name, address);
        const infoWindowContent = <div>{address}</div>;

        setInfoWindowContent(infoWindowContent);
        setInfoWindowOpen(true);
      } else {
        console.error("Selected place doesn't have a location property.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePolygonComplete = async (polygon) => {
    // Add it to the map
    //polygon.setMap(map);
    // Save it to a database
    const polygonCoords = polygon.getPath().getArray();
    //savePolygonToDatabase(polygonCoords);
    console.log(polygonCoords);
    // Perform some calculations based on its coordinates
    const area =
      window.google.maps.geometry.spherical.computeArea(polygonCoords);
    console.log(`The polygon has an area of ${area} square meters.`);

    const polygonArea = window.google.maps.geometry.spherical.computeArea(
      polygon.getPath()
    );
    const polygonPerimeter =
      window.google.maps.geometry.spherical.computeLength(polygon.getPath());
    setPolygon(polygon);

    console.log(
      `The area of the polygon is ${polygonArea.toFixed(2)} square meters.`
    );
    console.log(
      `The perimeter of the polygon is ${polygonPerimeter.toFixed(2)} meters.`
    );
  };
  const handleMapClick = async (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    try {
      const results = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=${apiKey}`
      ).then((res) => res.json());
      const address = results.results[0].formatted_address;
      form.setFieldValue(field.name, address);

      console.log(infoWindowContent);
    } catch (error) {
      console.log(error);
    }
  };
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  const handleAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
    window.google.maps.event.addListenerOnce(
      autocompleteRef.current,
      "place_changed",
      () => {
        handleAutocompleteSelect(
          autocompleteRef.current.getPlace().formatted_address
        );
      }
    );
  };
  const clearDrawing = () => {
    if (polygon) {
      polygon.setMap(null);
      setPolygon(null);
    }
  };
  const handleMapLoad = (map) => {
    mapRef.current = map;
    window.google.maps.event.addListenerOnce(map, "tilesloaded", () => {
      // Load DrawingManager
      setDrawingManagerLoaded(true);
      setMapTypeId("satellite");
      setHideTilt(0);
      setHeadingValue(180);

      // Set the map heading to 180 degrees to face the southern part
      //map.setHeading(180);
    });
    // map.setOptions({
    //     mapTypeId: 'satellite',
    //     heading: 180
    //   });
  };
  const handleRotate = () => {
    // Toggle heading between 0 and 180 degrees
    const newHeading = headingValue === 0 ? 180 : 0;

    // Check if the new heading is different from the current heading
    if (newHeading !== headingValue) {
      setHeadingValue(newHeading);

      // Set the new heading on the map
      mapRef.current.setHeading(newHeading);
    }
  };
  return (
    <Grid>
      <Autocomplete
        name="address"
        onPlaceChanged={() =>
          handleAutocompleteSelect(
            document.getElementById("autocomplete").value
          )
        }
        onLoad={(autocomplete) => handleAutocompleteLoad(autocomplete)}
        options={{
          types: ["address"],
          componentRestrictions: { country: "se" },
        }}
        style={{ zIndex: 9999 }}
      >
        <TextField
          id="autocomplete"
          margin="normal" //dense normal
          variant="outlined" // standard filled outlined
          color="warning" // warning secondary success
          type="text"
          placeholder="Fyll i adress *"
          fullWidth
          autoComplete="off"
          name={"address"}
          onKeyDown={() =>
            handleAutocompleteSelect(
              document.getElementById("autocomplete").value
            )
          }
        />
      </Autocomplete>
      <Grid style={{ height: "300px", width: "100%" }}>
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          disableDoubleClickZoom={true}
          mapTypeId={mapTypeId}
          zoom={zoom}
          ref={mapRef}
          center={selectedLocation}
          //onClick={handleMapClick}
          options={{
            headingValue,
            zoomControl: true,
            scaleControl: false,

            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            tilt: 0,
            rotateControl: false,
            styles: mapStyles,
          }}
          tilt={1}
          onLoad={handleMapLoad}
        >
          {isLoaded && (
            <Grid style={{ height: "300px", width: "100%" }}>
              {/* {drawingManagerLoaded && (
                <DrawingManager
                  //drawingMode={window.google.maps.drawing.OverlayType.POLYGON} // Set drawingMode to 'POLYGON' to show the polygon by default
                  // onPolygonComplete={handlePolygonComplete}
                  options={{
                    drawingControl: false,
                    drawingControlOptions: {
                      position: window.google.maps.ControlPosition.TOP_CENTER,
                      drawingModes: [
                        // window.google.maps.drawing.OverlayType.MARKER,
                        window.google.maps.drawing.OverlayType.POLYLINE,
                        window.google.maps.drawing.OverlayType.POLYGON,
                      ],
                    },
                  }}
                />
              )} */}
              {/* {selectedLocation && (
                <>
                  <Marker visible={true} position="center" />
                </>
              )} */}
              {infoWindowOpen && (
                <InfoWindowF
                  position={selectedLocation}
                  onCloseClick={() => {
                    setInfoWindowOpen(false);
                    setInfoWindowContent("");
                  }}
                >
                  {infoWindowContent}
                </InfoWindowF>
              )}
            </Grid>
          )}
        </GoogleMap>
      </Grid>
      {/* <Button
                fullWidth
                color="warning"
                onClick={clearDrawing}>Clear Drawing</Button> */}
      {/* <Button
                fullWidth
                color="primary"
                onClick={handleRotate}>Rotate</Button> */}
    </Grid>
  );
};

export default AddProjectMap;
