import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const CustomSelectField = ({ field, form, set_Category, set_CategoryName, ...props }) => {
  const [value, setValue] = React.useState(field.value || '');

  return (
    <FormControl fullWidth>

      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      
      <Select
        labelId="demo-simple-select-label"
        {...field}
        {...props}
        name={props.label}
        id={props.name}
        label={props.label}
        placeholder={props.label}
        required={props.required}
        fullWidth
        variant="outlined"
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
          form.setFieldValue(field.name, event.target.value);
          if (set_Category) {
            set_Category(event.target.value);
          }
          if (set_CategoryName) {
            let name = props.categories.find((type) => type.id === event.target.value).name
            form.setFieldValue(field.name, name);
            set_CategoryName(name);
          }
        }}
      >
        {props.categories.map((category, index) => (
          <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
        ))}
      </Select>
    </ FormControl>
  );
};

export default CustomSelectField;
