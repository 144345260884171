import { Grid, useMediaQuery } from "@mui/material";
import "./valuation.about.scss"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";

const ValuationAbout = (props) => { 
  useEffect(() => {
    AOS.init({ duration: 2000 });
    //AOS.refresh();
  }, []);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <section className="valuationAboutPage">
      <h1>
        {props.aboutData.valuation_section[0].title}
      </h1> 
      <Grid container alignItems="flex-start"  className="valuationAboutPageSectionRight">
        <Grid className="valuationAboutPageSectionRightImage" item xs={12} md={6}>
          <img alt="" title="image" src={props.aboutData.valuation_section[0].image} />
        </Grid>
        <Grid data-aos={isMobile ? "fade-up" : "fade-left"} item xs={12} md={6}>
          
          {props.aboutData.valuation_section[0].values.map((value, index) => (
            <Grid container key={index}>
              <Grid item xs={2}> 
                <img className="valueImage" alt="" title="image" src={value.image} />
              </Grid>
              <Grid item xs={10}>
                <h2>
                {value.title}
                </h2>
                <p className="valueDescription"> 
                {value.description}
                </p>
              </Grid> 
              {/* <TeamCard key={index} member={member} /> */} 
            </Grid>
          ))}
        </Grid> 
      </Grid>
    </section>
  );
};

export default ValuationAbout;
