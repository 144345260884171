import { Grid, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import CustomMapField from "./service.add.map.component";

const MarkAddServiceForm = (props) => {
  const handleAddressChange = (e) => {
    // setAddressValue("address", e.target.value);
  };
  return (
    <Grid container gap={3}>
      <Grid item xs={8}>
        <Field
          name="address"
          label="address"
          type="text"
          required={true}
          onChange={handleAddressChange}
          apiKey={"AIzaSyCDXtmPd5JQhNUpps_d5Rz3FP4RIT5GgzU"}
          component={CustomMapField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="address"
        />
      </Grid>

      <Grid item xs={3}>
        <Typography component={"h4"}>
          Markera de takytor som solcellspanelerna ska monteras på
        </Typography>
        <Typography>
          Klicka på ett av takets hörn och navigera runt till samma startpunkt
        </Typography>
        <Typography>Markera endast den sydliga delen av taket</Typography>
        <Typography>För platta/flacka tak, markera hela takytan</Typography>
        <Typography>
          Klicka "Ytterligare takyta" för att lägga till flera takytor
        </Typography>
      </Grid>
    </Grid>
  );
};
export default MarkAddServiceForm;
