import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';

const ImageInput = styled.input({
    display: 'none',
});
const ImageGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
const ImageContainer = styled.div({
    position: 'relative',
    marginTop: '20px',
    width: 200,
    height: 200,
});
const StyledImage = styled.img({
    width: '100%',
    height: '100%',
});
const StyledIconButton = styled(IconButton)({
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
});

const StyledDeleteIcon = styled(DeleteIcon)({
    fontSize: '250%',
});
const StyledAddAPhotoIcon = styled(AddAPhotoIcon)({
    marginRight: '7px',
});
const CustomImageField = ({
    field, //name, values, onChange, onBlur, onSubmit
    form,
    ...props
}) => {
    const [images, setImages] = useState([]);

    const handleImageChange = event => {
        setImages([...images, ...event.target.files]);
        form.setFieldValue('images', [...images, ...event.target.files]);

    };

    const handleDeleteImage = index => {
        setImages(images.filter((_, i) => i !== index));
        form.setFieldValue(
            'images',
            images.filter((_, i) => i !== index)
        );
    };

    return (
        <>
            <Typography
                variant="h6"
                component="p"
                sx={{
                    paddingLeft: { sm: '2rem' },
                    mb: '1rem',
                }}
            >
                Uppladdning av flera bilder:
            </Typography>
            <ImageInput
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                    <StyledAddAPhotoIcon>
                        <AddAPhotoIcon />
                    </StyledAddAPhotoIcon>
                    <span>Upload Images</span>
                </Button>
            </label>
            <ImageGrid>
                <Grid container spacing={2}>
                    {images.map((image, index) => (
                        <Grid item xs={4} key={index}>
                            <ImageContainer  >
                                <StyledImage
                                    src={URL.createObjectURL(image)}
                                    alt="uploaded"
                                />
                                <StyledIconButton
                                    onClick={() => handleDeleteImage(index)}
                                >
                                    <StyledDeleteIcon>
                                        <DeleteIcon />
                                    </StyledDeleteIcon>
                                </StyledIconButton>
                            </ImageContainer>
                        </Grid>
                    ))}
                </Grid>
            </ImageGrid>
        </>
    );
};

export default CustomImageField;