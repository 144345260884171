import { Grid, useMediaQuery } from "@mui/material";
import "./product.section.home.scss";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../utils/custom.button";
import { useTheme } from "@emotion/react";
import ProductCardsSectionHome from "./product.cards.section.home";
import ProductSliderSectionHome from "./product.slider.section.home";

const ProductSectionHome = (props) => {
  const [showFullText, setShowFullText] = useState(false);
  const textLimit = 100; // Character limit for initial display
  const [activeIndex, setActiveIndex] = useState(null);
  const [slideIndex, setSlideIndex] = useState(1);
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <section id="productSection" className="productSection">
        <div className="productSectionHeader">
          <h1 className="productTitle">
            {props.homeData.products_section[0].title}
          </h1>
          <p className="productDescription">
            {props.homeData.products_section[0].description}
          </p>
        </div>

        {isMD && <ProductCardsSectionHome homeData={props.homeData} />}
        {!isMD && <ProductSliderSectionHome homeData={props.homeData} />}
      </section>
    </>
  );
};

export default ProductSectionHome;
