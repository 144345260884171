import { Grid } from "@mui/material";
import "./customer.service.contact.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const CustomerServiceContact = (props) => {
  return (
    <section className="customerServiceContactPage">
      {/* <div className="imageContactBackgroundImage">
        <img alt="" title="image" src={props.contactData.customer_service_section[0].imageContactBackgroundImage} />
      </div> */}
      <h2>{props.contactData.customer_service_section[0].title}</h2>
      <p>{props.contactData.customer_service_section[0].description}</p>
      {props.contactData.customer_service_section[0].workers.map(
        (worker, index) => (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="customerServiceContactCard"
            key={index}
          >
            <Grid item lg={3} md={4} xs={4}>
              <img
                className="customerServiceContactImage"
                alt=""
                src={worker.image}
              />
            </Grid>

            <Grid item lg={9} md={8} xs={8}>
              <h3>{worker.name}</h3>
              <h4>{worker.position} </h4>
              <a
                href={worker.emailLink}
                className="customerServiceContactEmail"
              >
                {worker.email}
              </a>
              <a
                href={worker.phoneLink}
                className="customerServiceContactPhone"
              >
                <br />
                {worker.phone}
              </a>
            </Grid>
          </Grid>
        )
      )}
      {props.contactData.customer_service_section[0].social_media.map(
        (social, index) => (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            className="customerServiceContactSocialMedia"
          >
            <Grid item lg={2} md={3} xs={3}>
              <img alt="" src={social.image} />
            </Grid>
            <Grid item lg={10} md={9} xs={9} className="descriptionSocialMedia">
              {social.description}
              <a href={social.link}>{social.linkName}</a>
            </Grid>
          </Grid>
        )
      )}
    </section>
  );
};

export default CustomerServiceContact;
