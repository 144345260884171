import { Grid, useMediaQuery } from "@mui/material";
import "./service.card.section.home.scss";
import React, { useState } from "react";
import CustomButton from "../../../../utils/custom.button";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const ServiceCardSectionHome = (props) => {
  const [showFullText, setShowFullText] = useState(false);
  const textLimit = 100; // Character limit for initial display
  const [activeIndex, setActiveIndex] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));
  const getDescriptionText = (description, index) => {
    if (showFullText && activeIndex === index) {
      return description;
    } else {
      return description.length > textLimit
        ? description.slice(0, textLimit) + "..."
        : description;
    }
  };

  const moveToService = (id) => {
    navigate("/services/" + id);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {!isLG &&
        props.homeData.services_section[0].service_list.map((card, index) => (
          <div
            key={index}
            onClick={() => moveToService(card.id)}
            className={`cardServiceSection ${
              activeIndex === index ? "cardServiceSectionActive" : ""
            }`}
          >
            <img
              alt=""
              className="cardServiceSectionImage"
              title="image"
              src={card.img}
            />
            <h2 className="cardServiceSectionTitle">{card.title}</h2>
            <p className="cardServiceSectionDescription">
              {getDescriptionText(card.description, index)}
            </p>
            <CustomButton
              id="home_service_section"
              //id="header_main_section_get_a_quote"
              //onClick={projectAdd}
              onClick={() => moveToService(card.id)}
              label="Läs mer"
              size="15px"
              width="130px"
              height="31px"
              color="#ffffff"
              background="#FF8718"
            />
          </div>
        ))}
    </>
  );
};

export default ServiceCardSectionHome;
