import React, { useEffect, useRef, useState } from "react";
import AppRouts from "./app.routs";

import Header from "./components/header/header.component";
import { Container, useMediaQuery } from "@mui/material";
import Theme from "./utils/theme";
import CustomTheme from "./utils/custom.theme";
import styled from "@emotion/styled";
import CartProvider from "./provider/cart.provider";
import NotificationsProvider from "./provider/notifications.provider";
import RolesProvider from "./provider/roles.provider";
import { ThemeProvider } from "@mui/material/styles";
import Footer from "./components/footer/footer.component";
import CircularProgress from "@mui/material/CircularProgress";

const RootStyled = styled.div({
  //backgroundColor: Theme.palette.background.default,
  color: Theme.palette.font.default,
});
const MainComponent = () => {
  const isSmallScreen = useMediaQuery("(min-width:600px)");
  const mainContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating an asynchronous operation
    setLoading(false);

    // setTimeout(() => {
    // }, 9000);
  }, []);
  return (
    <RootStyled>
      <RolesProvider>
        <NotificationsProvider>
          <CartProvider>
            {loading ? (
              <div className="spinner-overlay">
                <CircularProgress className="spinner" />
              </div>
            ) : (
              <ThemeProvider theme={CustomTheme}>
                {isSmallScreen ? (
                  <>
                    <Header
                      divRef={mainContainerRef}
                      title="Tolo Solar"
                      logo="/logo.png"
                    />
                    {/* <Container fixed>
                  </Container> */}
                    <div ref={mainContainerRef} className="mainContainer">
                      <AppRouts />
                    </div>
                    <Footer />
                  </>
                ) : (
                  <div>
                    <Header
                      divRef={mainContainerRef}
                      title="Tolo Solar"
                      logo="/logo.png"
                    />
                    <div ref={mainContainerRef} className="mainContainer">
                      <AppRouts />
                    </div>
                    <Footer />
                  </div>
                )}
              </ThemeProvider>
            )}
          </CartProvider>
        </NotificationsProvider>
      </RolesProvider>
    </RootStyled>
  );
};
export default MainComponent;
