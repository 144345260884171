
const hasCorrectChecksum = (input) => {
  const sum = input
  .split('')
  .reverse()
  .map(Number)
  .map((x, i) => i % 2 ? x * 2 : x)
  .map((x) => x > 9 ? x - 9 : x)
  .reduce((x, y) => x + y);
  return sum % 10 === 0;
};

const hasValidDate = (input) => {
  let [_, yearStr, monthStr, dayStr] = /^(\d{2})(\d{2})(\d{2})/.exec(input);

  const year = Number(yearStr);
  const month = Number(monthStr) - 1;
  let day = Number(dayStr);

  if (day > 60) { // coordination numbers ("samordningsnummer")
    day -= 60;
  }

  const date = new Date(year, month, day);

  const yearIsValid = String(date.getFullYear()).substr(-2) === yearStr;
  const monthIsValid = date.getMonth() === month;
  const dayIsValid = date.getDate() === day;

  return yearIsValid && monthIsValid && dayIsValid;
};
function validateSection(Yup, value) {
  if (value) {
  const val = value.replace(/[+-]/, '').slice(-10);  // to get the last 10 number or character 
  if (hasCorrectChecksum(val) && hasValidDate(val)) {
    return Yup.string(value).notRequired();
  }else{
    return Yup.string(value).required('Personnumer är obligatoriskt');
  }
  }
}
export const validatePersonalNumber = (Yup) => {

    return Yup.string()
    .required('Detta fält är obligatoriskt!')

    .matches(/^-?\d+$/, 'Fältet måste vara ett nummer')

    .min(5, "måste vara 10 eller 12 nummer")
    .max(15, "måste vara 10 eller 12 nummer")
    .matches(/^\d{6}(\d{2})?[+-]?\d{4}$/, "måste vara 10 eller 12 nummer")
    .test('validate-section', 'Fältet är ogiltigt', value => {
        return validateSection(Yup, value);
      })

    // Yup.addMethod(Yup.string, "isStrongPassword", function (message) {
    //     return this.test('isStrongPassword', message, value => {
    //         const { path, createError } = this;
    //         switch (Boolean(value)) {
    //             case !/^(?=.*[a-z])/.test(value):
    //                 return createError({ path, message: "password must include lowercase letter" });
    //             case !/^(?=.*[A-Z])/.test(value):
    //                 return createError({ path, message: "password must include uppercase letter" });
    //             case !/^(?=.*[0-9])/.test(value):
    //                 return createError({ path, message: "password must include digit" });
    //             case !/^(?=.*[!@#\$%\^&\*])/.test(value):
    //                 return createError({ path, message: "password must include special character" });
    //             default:
    //                 return true;
    //         }
    //     })
    // });

};

export default validatePersonalNumber