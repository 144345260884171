import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';

function valuetext(value) {
  return `${value} sek`;
}
const CustomSlider = ({ field, form, set_value, ...props }) => {
  const [value, setValue] = React.useState(form.initialValues[props.id]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    set_value(newValue);
    form.setFieldValue(field.id, event.target.value);

  };

  return (
    <>
      <Typography gutterBottom>{ props.label}</Typography>

      <Slider
        {...field}
        {...props}
        min={0}
        max={form.initialValues[props.id]}
        step={2}

        name={props.label}
        id={props.id}
        label={props.label}
        onChange={handleChange}

        size="small"
        defaultValue={value}
        value={value}
        aria-label="Small"
        valueLabelDisplay="auto"
        color="primary"

      />
      </>
  );
}
export default CustomSlider;