import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";

import { Container, Grid, Box, Typography, Stack } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormikResetPassword from "./formik.reset.password.component";
import CustomLink from "../../utils/custom.link";

const theme = createTheme();

const registerList = [
  { label: "Registrera dig online på bara några minuter!" },
  { label: "Få tillgång till dina priser och aktuellt lagersaldo." },
  { label: "Se din order-/fakturahistorik." },
  { label: "Skapa egna produktlistor och relationshandlingar." },
];

//type Props = RouteComponentProps<RouterProps>;

const ResetPassword = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          backgroundColor: { xs: "#fff", md: "#f4f4f4" },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid
            item
            sx={{ maxWidth: "70rem", width: "100%", backgroundColor: "#fff" }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                py: "6rem",
                px: "1rem",
              }}
            >
              <Grid
                item
                container
                justifyContent="space-between"
                rowSpacing={5}
                sx={{
                  maxWidth: { sm: "45rem" },
                  marginInline: "auto",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    borderRight: { sm: "1px solid #ddd" },
                    borderLeft: { sm: "1px solid #ddd" },
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    noValidate
                    autoComplete="off"
                    sx={{
                      paddingRight: { sm: "3rem" },
                      paddingLeft: { sm: "3rem" },
                    }}
                  >
                    <Typography variant="h6" component="p" sx={{}}>
                      Nytt lösenord
                    </Typography>

                    <FormikResetPassword />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
