import { Container, Grid } from "@mui/material";
import CustomButton from "../../../../utils/custom.button";
import "./main.section.details.project.scss";
import React from "react";

const DesktopMainSectionProjectsDetails = ({ backgroundImage }) => {
  function scrollToSection(sectionId) {
    setTimeout(() => {
      console.log(sectionId);
      const projectSection = document.getElementById(sectionId);

      if (projectSection) {
        projectSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }
  return (
    <div className="DesktopContainerProject">
      <img
        className="backgroundSectionImage"
        alt=""
        title="image"
        src={backgroundImage}
      />
    </div>
  );
};

export default DesktopMainSectionProjectsDetails;
