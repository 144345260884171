import { createTheme } from "@mui/material/styles";

const CustomTheme = createTheme({
  primary: {
    light: "#757ce8",
    main: "#0A7ACC",
    dark: "#002884",
    contrastText: "#fff",
  },
  secondary: {
    light: "#ff7961",
    main: "#F39200",
    dark: "#ba000d",
    contrastText: "#000",
  },
  danger: {
    light: "#ff5252",
    main: "#d50000",
    dark: "#ff1744",
    contrastText: "#000",
  },
  backgroundColor: {
    default: "black",
    dark: "black",
    light: "white",
  },
  fontFamily: '"Montserrat","Open Sans", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  h1: {
    fontSize: "2.125rem",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.43,
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 500,
    textTransform: "uppercase",
  },

  background: {
    default: "white",
    dark: "black",
    light: "white",
  },
  font: {
    default: "black",
    dark: "black",
    light: "white",
  },
  text: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },

    default: "white",
    type: "light",
  },
  spacing: (factor) => `${8 * factor}px`,
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

export default CustomTheme;
