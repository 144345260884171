import { Grid, Slider } from "@mui/material";

function ConsumptionAddProjectForm({ field, form, ...props }) {
  const marks = [
    {
      value: 0,
      label: '0kwh',
    },
    // {
    //   value: 12500,
    //   label: '12 500kwh',
    // },
    {
      value: 25000,
      label: '25 000kwh',
    },
    // {
    //   value: 37500,
    //   label: '37 500kwh',
    // },
    {
      value: 50000,
      label: '50 000kwh+',
    },
  ];
  function valuetext(value) {
    return `${value}kwh`;
  }
  return (
    <>
      {/* <p>Årlig elförbrukning:</p> */}
      <Grid style={{ marginLeft: '45px', marginRight: '45px' }}>
        <Slider
          id="annualConsumption"
          aria-label="Always visible"
          defaultValue={10000}
          getAriaValueText={valuetext}
          max={50000}
          step={100}
          marks={marks}
          valueLabelDisplay="on"
          onChange={(event) => form.setFieldValue(field.name, event.target.value)}
          {...props}
        />
      </Grid>
    </>
  );
}
export default ConsumptionAddProjectForm;
