import React, { useState } from "react";
import { emphasize, styled, Box, Breadcrumbs } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Chip from "@mui/material/Chip";
import eventBus from "../common/EventBus";
import { listCategories } from "../components/category/category.service";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const background_color =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    background_color,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(background_color, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(background_color, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

// const productCategories = [
//   { name: 'Category 1', link: '/category-1' },
//   { name: 'Category 2', link: '/category-2' }
// ];
//prop.product.id
function CustomBreadcrumbs({ props }) {
  const [categories, setCategories] = useState([]);
  //setProductCategories(productCategories)
  React.useEffect(() => {
    //{categories.map((option) => option.name)}
    listCategories().then(
      (response) => {
        console.log(response);
        //setCategories(response.data);
        //setCount(response.data.count);
        const currentCategory = props.product.category || 0;

        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index];
          if (element.parentId === currentCategory) {
            categories.push(element);
          }
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setCategories(_content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
      }
    );
  }, []);
  return (
    <Box m={1} pt={3}>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<HomeIcon fontSize="small" />}
          />
          {categories.map((category) => (
            <StyledBreadcrumb
              component="a"
              href={category.link}
              label={category.name}
            />
          ))}
        </Breadcrumbs>
      </div>
    </Box>
  );
}

export default CustomBreadcrumbs;
