import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import "./about.slider.component.scss";
import { useTheme } from "@emotion/react";

const AboutSlider = ({ aboutData }) => {
  const theme = useTheme();
  const images = aboutData.main_section[0].slider;
  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current slide index to move to the next slide
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Change slide every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Grid className="aboutSlider" id="slider">
      <figure>
        {images.map((image, index) => (
          <img
            alt=""
            className="cardAboutSectionImage"
            title="image"
            src={image}
            loading="lazy"
          />
        ))}
      </figure>
    </Grid>
  );
};

export default AboutSlider;
