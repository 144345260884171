import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function ccyFormat(num) {
  return `${parseFloat(num).toFixed(2)}`;
}


function TableOrderDetails(props) {

    return (
      <TableContainer >
      <Table aria-label="spanning table">
          <TableHead>
              <TableRow>
                  <TableCell align="center" colSpan={3}>
                  Detaljer
                  </TableCell>
                  <TableCell align="right">Pris</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Desc</TableCell>
                  <TableCell align="right">Antal.</TableCell>
                  <TableCell align="right">Enhet</TableCell>
                  <TableCell align="right">Sum</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            
              {props.products.map((product) => (
                  <TableRow key={product.id}>
                      <TableCell>{product.products[0].title}</TableCell>
                      <TableCell align="right">{product.quantity}</TableCell>
                      <TableCell align="right">ST</TableCell>
                      <TableCell align="right">{ccyFormat(product.quantityPrice)}</TableCell>
                  </TableRow>
              ))}
  
              <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}>Totalpris, exclusive moms:</TableCell>
                  <TableCell align="right">{ccyFormat(props.order.invoiceSubtotal)}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Moms:</TableCell>
                  <TableCell align="right">{`${(0.25 * 100).toFixed(0)} %`}</TableCell>
                  <TableCell align="right">{ccyFormat(props.order.invoiceTaxes)}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell colSpan={2}>Totalpris, inklusive moms:</TableCell>
                  <TableCell align="right">{ccyFormat(props.order.invoiceTotal)}</TableCell>
              </TableRow>
          </TableBody>
      </Table>
      </TableContainer>
    );
  }
  
  export default TableOrderDetails;