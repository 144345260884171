import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { getAnalytics, logEvent } from "firebase/analytics";

export const LinkItem = styled(Link)`
  text-decoration: none;
  &:hover {
    color: #f29F05;
  }
`

const NoStyleLink = ({ children, to, id }) => { 
  const analytics = getAnalytics(); 
  const handleClick = () => {
    //window.scrollTo({ top: 0, behavior: 'smooth' });
    logEvent(analytics, 'click', {
      event_name: id,
      to:to
    });
  }
  return (
    //   <a href={href}>{children}</a>
    <a
      href={to}
      onClick={handleClick}
    >
      {children}
    </a>
  )
}
  ;
export default NoStyleLink;