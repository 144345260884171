import { Grid } from "@mui/material";
import "./gallery.section.home.css";
import React, { useEffect, useRef, useState } from "react";
import MediaCards from "./media.gallery.section.home";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

const GallerySectionHome = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [disableAutoScroll, setDisableScroll] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000); // change the threshold as needed
    };
    handleResize(); // set the initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // Disable auto-scroll when the user interacts with the gallery container
    const galleryContainer = gallerySliderRef.current;

    const handleScroll = () => {
      setDisableScroll(true);
      // Re-enable auto-scroll after a delay (adjust as needed)
      // setTimeout(() => {
      //   setDisableScroll(false);
      // }, 2000);
    };

    galleryContainer.addEventListener("wheel", handleScroll);
    galleryContainer.addEventListener("touchstart", handleScroll);

    return () => {
      galleryContainer.removeEventListener("wheel", handleScroll);
      galleryContainer.removeEventListener("touchstart", handleScroll);
    };
  }, []);
  const gallerySliderRef = useRef(null);

  const [scrollInterval, setScrollInterval] = useState(null);

  // Function to handle automatic scrolling with partial scrolling
  const scrollGallery = () => {
    if (gallerySliderRef.current && gallerySliderRef.current.scrollWidth) {
      // Use the scrollGallery function to scroll by a partial amount (e.g., 5% of total scroll width)
      const scrollPercentage = 0.007;
      const duration = 3000;

      const scrollAmount =
        (gallerySliderRef.current.scrollWidth * scrollPercentage) / 100;
      // gallerySliderRef.current.scrollLeft += scrollAmount;
      // Scroll to the target position with smooth behavior
      gallerySliderRef.current.scrollTo({
        left: gallerySliderRef.current.scrollLeft + scrollAmount,
      });
      // Check if we have reached the right end of the gallery
      if (
        gallerySliderRef.current.scrollLeft +
          gallerySliderRef.current.clientWidth >=
        gallerySliderRef.current.scrollWidth
      ) {
        // If at the right end, scroll back to the left
        gallerySliderRef.current.scrollTo({
          left: 0,
        });
      }
    }
  };

  useEffect(() => {
    // Start the automatic scrolling interval
    console.log(disableAutoScroll);
    if (!disableAutoScroll) {
      const interval = setInterval(scrollGallery, 0.09); // Change the interval as needed
      setScrollInterval(interval);
      return () => {
        // Clear the interval when the component unmounts
        clearInterval(interval);
      };
    }
  }, [disableAutoScroll]);
  return (
    <section className="sliderSectionGallery">
      <div className="gallerySliderContainer" ref={gallerySliderRef}>
        {props.homeData.projects_section[0].product_list.map((item, k) => (
          <>
            {item.img.map((im, i) => (
              <MediaCards
                setDisableScroll={setDisableScroll}
                key={i}
                cards={im}
              />
            ))}
          </>
        ))}
      </div>
    </section>
  );
};

export default GallerySectionHome;
