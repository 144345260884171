import { Dialog, Grid } from "@mui/material";
import "./rating.section.home.scss";
import React, { useEffect, useRef, useState } from "react";
import CustomAvatar from "../../../../utils/custom.avatar";
import DialogRatingSectionHome from "./dialog.rating.section.home";

const CardRatingSectionHome = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     if (open) {
  //       e.preventDefault();
  //     }
  //   };
  //   window.addEventListener("wheel", handleScroll, { passive: false });
  //   window.addEventListener("touchmove", handleScroll, { passive: false });

  //   return () => {
  //     window.removeEventListener("wheel", handleScroll);
  //     window.removeEventListener("touchmove", handleScroll);
  //   };
  // }, [open]);
  return (
    <>
      <div onClick={handleOpen} className="ratingSlide">
        <Grid container>
          <img
            alt=""
            className="iconSlide"
            title="image"
            src={props.homeData.ratings_section[0].earthIcon}
          />
          <h3>{props.rate.subTitle}</h3>
        </Grid>
        <div className="cardRatingSlide">
          <div className="backgroundImageCardSlide">
            <img alt="" title="image" src={props.rate.background_image} />
          </div>

          <CustomAvatar className="profileImageCardSlide" review={props.rate} />
          <h2>{props.rate.title}</h2>
          
          <p>
            {props.rate.text[0].length > 110
              ? props.rate.text[0].slice(0, 110) + "..."
              : props.rate.text[0]}
          </p>
          <Grid className="starContainer">
            {Array.from({ length: 5 }).map((_, i) => (
              <img
                key={i}
                alt=""
                className="starImageCardSlide"
                title="image"
                src={props.homeData.ratings_section[0].starIcon}
              />
            ))}
          </Grid>
        </div>
      </div>
      <DialogRatingSectionHome
        open={open}
        homeData={props.homeData}
        handleClose={handleClose}
        rate={props.rate}
      />
    </>
  );
};

export default CardRatingSectionHome;
