import React, { useEffect, useState } from 'react';
import { RolesContext } from '../context';

const RolesProvider = (props) => {
  const [roles, setRoles] = useState([]);
  
  useEffect(() => {
    const rolesStorage = localStorage.getItem("roles");
    if (rolesStorage) {
      setRoles(JSON.parse(rolesStorage));
    }
  }, []);
  return (
    <RolesContext.Provider value={[roles, setRoles]}>
      {props.children}
    </RolesContext.Provider>
  );
};

export default RolesProvider;