import { Grid, useMediaQuery } from "@mui/material";
import "./product.cards.section.home.scss";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../utils/custom.button";
import { useTheme } from "@emotion/react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Route, useNavigate } from "react-router-dom";
const ProductCardsSectionHome = (props) => {
  let navigate = useNavigate();

  const moveToProduct = (id) => {
    navigate("/products/" + id);
    console.log(`Product's ${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <section id="productSection" className="productSectionCards">
        <Grid container className="">
          {props.homeData.products_section[0].product_list.map(
            (product, index) => (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={index}
                className="cardProductSlide"
              >
                <Grid item xs={4}>
                  <img
                    alt=""
                    className="cardProductSectionImage"
                    title="image"
                    src={product.img}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h2 className="cardProductSectionTitle">{product.title}</h2>
                </Grid>
                <Grid item xs={4}>
                  <CustomButton
                    id="home_product_section"
                    icon={<PlayArrowIcon name="arrow" />}
                    size="10px"
                    width="35px"
                    height="35px"
                    onClick={() => moveToProduct(product.id)}
                    padding="0px !important"
                    color="#ffffff"
                    background="#FF8718"
                    border_radius="50%"
                  />
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </section>
    </>
  );
};

export default ProductCardsSectionHome;
