import { Grid, useMediaQuery } from "@mui/material";
import "./project.section.home.scss";
import React from "react";
import CustomButton from "../../../../utils/custom.button";
import CustomLink from "../../../../utils/custom.link";
import LargeCardProjectSectionHome from "./large.card.project.section.home";
import SmallCardProjectSectionHome from "./small.card.project.section.home";
import { useTheme } from "@emotion/react";

const ProjectSectionHome = (props) => {
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  const shuffledProducts = shuffleArray(
    props.homeData.projects_section[0].product_list
  );

  const numberOfProductsToDisplay = 3;

  const displayedProducts = shuffledProducts.slice(
    0,
    numberOfProductsToDisplay
  );

  return (
    <>
      <div className="projectSection">
        <h1>
          VÅRA PROJEKT
          <CustomLink to="/projects">{"Visa mer  >"}</CustomLink>
        </h1>
        <hr />
        {displayedProducts.map((project, i) => (
          <React.Fragment key={i}>
            {isMD && <SmallCardProjectSectionHome project={project} />}
            {!isMD && <LargeCardProjectSectionHome project={project} />}
          </React.Fragment>
        ))}
        {!isMD && <hr />}
      </div>
    </>
  );
};

export default ProjectSectionHome;
