import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation, useNavigate } from "react-router-dom";

const LinkItem = styled(Link)(({ background_color, font_color }) => ({
  backgroundColor: background_color,
  color: font_color + " !important",
  textDecoration: "none",
  margin: "0px",
  "&:hover": {
    textDecoration: "none",
  },
}));

const CustomLink = ({
  children,
  to,
  id,
  className,
  closeMenu,
  onMouseEnter,
  onMouseLeave,
  section,
  indicatorLeave,
  background_color,
  font_color,
  index,
  setMenuHovered,
  onClick,
  style,
}) => {
  const analytics = getAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (event) => {
    // event.preventDefault();

    // navigate(to);
    if (to.includes("tel:")) {
      window.location.href = "tel:020899488";
    } else if (onClick) {
      onClick();
    } else if (section) {
      setTimeout(() => {
        //console.log(section);

        const productSection = document.getElementById(section);

        if (productSection) {
          productSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else if (closeMenu) {
      //closeMenu();
      if (closeMenu) {
        closeMenu();
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      //closeMenu();
      if (closeMenu) {
        closeMenu();
      }
    }
    if (indicatorLeave) {
      indicatorLeave(to);
    }
    logEvent(analytics, id, {
      event_name: id,
    });
  };
  const onEnter = () => {
    if(index){

      if(onMouseEnter){
        onMouseEnter()
      }
      if(setMenuHovered){
        setMenuHovered(index)
      }
    }

  };
  const onLeave = () => {
    if(index){

    if(onMouseLeave){
      onMouseLeave()
    }
    if(setMenuHovered){
      setMenuHovered(null)

    } }
  };
  return (
    <LinkItem
    onMouseEnter={() => onEnter()}
    onMouseLeave={() => onLeave()}
      
      className={className}
      background_color={background_color}
      font_color={font_color}
      to={to}
      onClick={handleClick}
    >
      {children}
    </LinkItem>
  );
};
export default CustomLink;
