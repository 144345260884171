import React, { useEffect, useState } from "react";
import MainSectionProducts from "./main/main.section.products";

import jsonBatteryProductsData from "../../../json/battery.products.page.json";
import jsonChargerProductsData from "../../../json/charger.products.page.json";
import jsonInverterProductsData from "../../../json/inverter.products.page.json";
import jsonPanelsProductsData from "../../../json/panels.products.page.json";
import StockSectionProducts from "./stock/stock.section.products";
import WhySectionProducts from "./why/why.section.products";
import ModelsSectionProducts from "./models/models.section.products";
import FAQsSectionProducts from "./FAQs/FAQs.section.products";
import { Container } from "@mui/material";
import GallerySectionProducts from "./gallery/gallery.section.products";
import "./products.component.scss";
import { useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
const Products = () => {
  const [productsData, setProductsData] = useState(jsonPanelsProductsData);
  const { id } = useParams();
  React.useEffect(() => {
    AOS.init({ duration: 2000 });
    // AOS.refresh();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (id === "1") {
      setProductsData(jsonPanelsProductsData);
    } else if (id === "2") {
      setProductsData(jsonInverterProductsData);
    } else if (id === "3") {
      setProductsData(jsonBatteryProductsData);
    } else if (id === "4") {
      setProductsData(jsonChargerProductsData);
    }
  }, [id]);
  return (
    <div className="ProductsPage">
      {/*
        <div>
          MainSectionProducts
          StockSectionProducts
          <WhySectionProducts/>
          <ModelsSectionProducts/>
          <GallerySectionProducts/>
          <FAQsSectionProducts/>
        </div>
      */}

      <MainSectionProducts productsData={productsData} />
      <div
        style={{
          backgroundColor: "white",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <Container fixed>
        {productsData.stock_section && (
            <StockSectionProducts productsData={productsData} />
          )}
          {productsData.why_section && (
            <WhySectionProducts productsData={productsData} />
          )}
          {productsData.models_section && (
            <ModelsSectionProducts productsData={productsData} />
          )}
          {productsData.FAQs_section && (
            <FAQsSectionProducts productsData={productsData} />
          )}
        </Container>
      </div>
    </div>
  );
};

export default Products;
