import { Grid, useMediaQuery } from "@mui/material";
import "./reason.section.home.scss";
import React from "react";
import { useTheme } from "@emotion/react";
import CardsDesktopReasonSectionHome from "./cards.desktop.reason.section.home";
import CardsMobileReasonSectionHome from "./cards.mobile.reason.section.home";

const ReasonSectionHome = (props) => {
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="reasonSection"
        spacing={5}
      >
        {!isMD && (
          <Grid item sx={12} md={6} className="reasonSectionImage">
            <div className="imageContainer">
              <img
                src={props.homeData.reason_section[0].image}
                className="centeredImage"
              />
            </div>
          </Grid>
        )}
        <Grid item sx={12} md={6} className="">
          <div className="">
            <h1 className="">{props.homeData.reason_section[0].title}</h1>
            <p className="">{props.homeData.reason_section[0].description}</p>

            {!isMD && (
              <CardsDesktopReasonSectionHome homeData={props.homeData} />
            )}
            {isMD && <CardsMobileReasonSectionHome homeData={props.homeData} />}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ReasonSectionHome;
