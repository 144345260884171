import "./style.css"

const PolicyPage = () => {

    return (
        <>
        
        </>
    );
};

export default PolicyPage;
