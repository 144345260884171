import { Button, Grid, Stack, TextField, Typography } from "@mui/material";

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import orderService from "../order/order.service";
import { useEffect, useState } from "react";
import CustomAutoField from "../../utils/custom.auto.field"
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { listUsers } from "../users/users.service";
import authService from "../../services/auth.service";
import CustomStaticField from "../../utils/custom.static.field";
import { useNavigate } from "react-router-dom";

function CreateOrder(props) {
  const [message, setMessage] = useState();
  const [success, setSuccessful] = useState();
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([]);
  const [selectedUser, setselectedUser] = useState([]);
  const [userr, setUser] = useState([]);
  const [username, setUsername] = useState('');
  let navigate = useNavigate()


  const [allow, setAllowed] = useState(false);

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setAllowed(user.roles.includes("superuser") || user.roles.includes("admin") || user.roles.includes("employ"));
      setUsername(user.username)
    }
  }, []);

  useEffect(() => {
    fetchUsers("");
  }, [username]);
  const fetchUsers = async (user) => {
    listUsers(user).then(
      (response) => {
        const users = response.data.users;
        setUsers(users);
        const filteredUsers = users.filter(u => u.username === username);
        setselectedUser(filteredUsers);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(message);
      }
    );
  };
  
  const filteredUsers = users.filter((item) => {
    return (
      item.username.toLowerCase().includes(username.toLowerCase()) ||
      item.email.toLowerCase().includes(username.toLowerCase()) ||
      item.contactPersonId.toLowerCase().includes(username.toLowerCase())

    );
  }) || users?.map((user) => user);

  function SubmitData(values) {
    let order = { product: props.cart, customer: values['customer'], invoiceSubtotal: props.invoiceSubtotal, invoiceTaxes: props.invoiceTaxes, invoiceTotal: props.invoiceTotal }
    orderService.registerOrder(order).then(
      (response) => {
        //console.log(response);
        setMessage(response.message);
        setSuccessful(true);
        fetchUsers(); // fetch the selected user
        navigate('/orders/'+ response.id);
        localStorage.removeItem("cart");
        window.location.reload()
      },
      (error) => {
        const resMessage =
          error.response.data.message ||
          (error.response && error.response && error.response.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSuccessful(false);
      }
    );
  }
  
  const formikProps = {
    initialValues: {
      customer: '',
    },
    validationSchema: Yup.object().shape({
      customer: Yup.string(),
    }),
    onSubmit: (values, event) => {
      SubmitData(values)
    }
  }
  return (
    <Formik {...formikProps}>
      {formik => (
        <Form >
          {!loading && (
            <Grid >

              {allow && (
                <Grid>
                  <Typography style={{ color: "orange" }}>{username}</Typography>
                  <Field
                    //value={props.formik.values.user}
                    set_value={setUsername}
                    freeSolo={true}
                    component={CustomAutoField}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="customer"
                    label="kund"
                    options={filteredUsers.map((user) => (user.username))}
                    formik={formik}
                    placeholder="kund"
                  />
                  <ErrorMessage
                    name="customer"
                    component="div"
                    className="alert alert-danger"
                  />
                </Grid>
              )
              }


              <Button type="submit" disabled={loading} variant="contained" color="success" endIcon={<ShoppingCartIcon />}>
                Beställa
              </Button>
              {selectedUser.map((user) => (
                <Grid key={user.id}>
                  <CustomStaticField label={'Förnamn'} defaultValue={user.firstName} />
                  <CustomStaticField label={'Efternamn'} defaultValue={user.lastName} />
                  <CustomStaticField label={'Gatuadress'} defaultValue={user.address} />
                  <CustomStaticField label={'Stad'} defaultValue={user.city} />
                  <CustomStaticField label={'postnummer'} defaultValue={user.postNumber} />
                  <CustomStaticField label={'E-post'} defaultValue={user.email} />
                  <CustomStaticField label={'Telefonnummer'} defaultValue={user.telephone} />
                </Grid>
              ))}

            </Grid>
          )}
          {message && (
            <div className="form-group">
              <div
                className={
                  loading ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default CreateOrder;
