import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Theme from "../../../utils/theme";
import {
  IconButton,
  alpha,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { patchRoles, patchUser, deleteUser } from "../users.service";
import Delete from "@mui/icons-material/Delete";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CustomDialogItem from "../../../utils/custom.dialog.Item";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import BadgeIcon from "@mui/icons-material/Badge";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((prop) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...prop}
  />
))(({}) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: Theme.spacing(1),
    minWidth: 180,
    // color:  Theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : Theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: Theme.palette.text.secondary,
        marginRight: Theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          Theme.palette.primary.main
          // Theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function UserStatus(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoles = (name, value) => {
    //props.value[props.name] = editedValue;
    console.log(name);
    props.setRoles([name]);
    patchRoles(props.user.id, [value]);
    //props.setValue(props.value)
    // Submit the form and update the value in the parent component's state
    handleClose();
  };
  const handleStatus = (name, value) => {
    //props.value[props.name] = editedValue;
    const editedUser = props.user;
    editedUser["accountStatus"] = name;
    props.setUser(editedUser);
    patchUser(props.user.id, { accountStatus: name });
    window.location.reload();
    //props.setValue(props.value)
    // Submit the form and update the value in the parent component's state
    handleClose();
  };
  const handleDelete = (name, value) => {
    deleteUser(props.user.id);
    navigate("/users/search");
  };

  return (
    <>
      <IconButton
        id="customized-button"
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        //disableElevation
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <CustomDialogItem
          label="Aktivera konto"
          value={7}
          name="Activated"
          handleSubmit={handleStatus}
          handleClose={handleClose}
          open={open}
          icon={<DoneOutlineIcon />}
        />
        <CustomDialogItem
          label="Inaktivera konto"
          value={8}
          name="Deactivated"
          handleSubmit={handleStatus}
          handleClose={handleClose}
          open={open}
          icon={<NoAccountsIcon />}
        />
        <Divider />

        <CustomDialogItem
          label="byta konto till superuser"
          value={0}
          name="superuser"
          handleSubmit={handleRoles}
          handleClose={handleClose}
          open={open}
          icon={<SupervisorAccountIcon />}
        />
        <CustomDialogItem
          label="byta konto till admin"
          value={1}
          name="admin"
          handleSubmit={handleRoles}
          handleClose={handleClose}
          open={open}
          icon={<AdminPanelSettingsIcon />}
        />
        <CustomDialogItem
          label="byta konto till installer"
          value={2}
          name="installer"
          handleSubmit={handleRoles}
          handleClose={handleClose}
          open={open}
          icon={<EngineeringIcon />}
        />
        <CustomDialogItem
          label="byta konto till employee"
          value={3}
          name="employee"
          handleSubmit={handleRoles}
          handleClose={handleClose}
          open={open}
          icon={<BadgeIcon />}
        />
        <CustomDialogItem
          label="byta konto till företagetkund"
          value={4}
          name="company"
          handleSubmit={handleRoles}
          handleClose={handleClose}
          open={open}
          icon={<ApartmentIcon />}
        />
        <CustomDialogItem
          label="byta konto till privatkund"
          value={5}
          name="privateCustomer"
          handleSubmit={handleRoles}
          handleClose={handleClose}
          open={open}
          icon={<AccessibilityIcon />}
        />
        <Divider />
        <CustomDialogItem
          label="Delete Account"
          value={6}
          name="Deleted"
          handleSubmit={handleDelete}
          handleClose={handleClose}
          open={open}
          icon={<Delete />}
        />
      </StyledMenu>
    </>
  );
}

export default UserStatus;
