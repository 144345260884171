import React, { useState } from "react";
import CustomLink from "../../../utils/custom.link";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./menu.mobile.link.style.scss";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ContactsIcon from "@mui/icons-material/Contacts";
import CategoryIcon from "@mui/icons-material/Category";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CustomSubMenuLink from "../../../utils/custom.sub.menu.link";
import CustomSubMenuLinkModal from "../../../utils/custom.sub.menu.link.modal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import QuizIcon from "@mui/icons-material/Quiz";
import HelpIcon from "@mui/icons-material/Help";
const MenuMobileLink = ({
  onClick,
  label,
  icon,
  keyMenuItem,
  to,
  closeMenu,
  subMenu,

  selectedModal,
  setSelectedModal,
}) => {
  const checkModal = (keyMenuItem) => {
    if (selectedModal === keyMenuItem) {
      setSelectedModal(null);
    } else {
      setSelectedModal(keyMenuItem);
    }
  };
  return (
    <div style={{ marginBottom: "7px", marginTop: "7px" }}>
      {Array.isArray(subMenu) && subMenu.length > 0 ? (
        <CustomSubMenuLink
          //key={index}
          // to={subMenuItem.to}
          // label={subMenuItem.label}

          selectedModal={selectedModal}
          closeMenu={closeMenu}
          id={keyMenuItem}
          subMenu={subMenu}
          className={"CustomSubMenuLink"}
        >
          <CustomLink
            className="menuMobileLink "
            id={keyMenuItem}
            to={subMenu ? "" : to}
            onClick={() => checkModal(keyMenuItem)}
            closeModal={subMenu ? closeMenu : ""}
          >
            <span className="menuMobileLinkIcon">
              {icon === "InfoIcon" && <InfoIcon />}
              {icon === "HomeIcon" && <HomeIcon />}
              {icon === "ContactsIcon" && <ContactsIcon />}
              {icon === "CategoryIcon" && <CategoryIcon />}
              {icon === "EngineeringIcon" && <EngineeringIcon />}
              {icon === "QuizIcon" && <QuizIcon />}
              {icon === "HelpIcon" && <HelpIcon />}
            </span>
            <span className="text">{label}</span>
            <span className="arrow">
              {selectedModal === keyMenuItem ? (
                <ArrowBackIosIcon />
              ) : (
                <ArrowForwardIosIcon />
              )}
            </span>
          </CustomLink>
        </CustomSubMenuLink>
      ) : (
        <CustomLink
          className="menuMobileLink"
          id={keyMenuItem}
          to={subMenu ? "" : to}
          //onClick={subMenu ? openModal : ""}
          closeMenu={closeMenu}
        >
          <span className="menuMobileLinkIcon">
            {icon === "InfoIcon" && <InfoIcon />}
            {icon === "HomeIcon" && <HomeIcon />}
            {icon === "ContactsIcon" && <ContactsIcon />}
            {icon === "CategoryIcon" && <CategoryIcon />}
            {icon === "EngineeringIcon" && <EngineeringIcon />}
            {icon === "QuizIcon" && <QuizIcon />}
            {icon === "HelpIcon" && <HelpIcon />}
          </span>
          <span className="text">{label}</span>
          <span className="arrow">
            <ArrowForwardIosIcon />
          </span>
        </CustomLink>
      )}
    </div>
  );
};

export default MenuMobileLink;
