import { ErrorMessage, Field } from "formik";
import CustomField from "../../../utils/custom.field";

const PersonalAddServiceForm = (props) => {
  return (
    <>
      <Field
        name="name"
        type="text"
        label="Namn"
        required={true}
        component={CustomField}
      />
      <ErrorMessage component="span" className="error-message" name="name" />
      <Field
        name="email"
        type="text"
        label="email"
        required={true}
        component={CustomField}
      />
      <ErrorMessage component="span" className="error-message" name="email" />
      <Field
        name="phone"
        type="text"
        label="Telefon"
        required={true}
        component={CustomField}
      />
      <ErrorMessage component="span" className="error-message" name="phone" />
      <Field
        name="description"
        type="textarea"
        label="Beskrivning"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="description"
      />
    </>
  );
};
export default PersonalAddServiceForm;
