import { Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import CustomMapField from "./service.add.map.component";

const MaterialAddServiceForm = (props) => {
    const handleAddressChange = (e) => {
        // setAddressValue("address", e.target.value);
    };
    return (
        <>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{
                    paddingLeft: { sm: '2rem' },
                    mb: '1rem',
                  }}
                >
                  Taktyp:
                </Typography>
        </>
    );
}
export default MaterialAddServiceForm;
