import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CheckIcon from '@mui/icons-material/Check'
import ListItemIcon from '@mui/material/ListItemIcon'

import {
  Container,
  Grid,
  Box,
  Typography,
  Stack
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import FormikLogin from './formik.login.component'
import CustomLink from '../../utils/custom.link'


const theme = createTheme()

const registerList = [
  { label: 'Registrera dig online på bara några minuter!' },
  { label: 'Få tillgång till dina priser och aktuellt lagersaldo.' },
  { label: 'Se din order-/fakturahistorik.' },
  { label: 'Skapa egna produktlistor och relationshandlingar.' },
]

//type Props = RouteComponentProps<RouterProps>;

const Login = () => {
  return (
    <ThemeProvider theme={theme}>

            <Grid
              container
              sx={{
                // boxShadow: { sm: '0 0 5px #ddd' },
                py: '6rem',
                px: '1rem',
              }}
            >
              <Grid
                item
                container
                justifyContent="space-between"
                rowSpacing={5}
                sx={{
                  maxWidth: { sm: '45rem' },
                  marginInline: 'auto',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ borderRight: { sm: '1px solid #ddd' } }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    noValidate
                    autoComplete="off"
                    sx={{ paddingRight: { sm: '3rem' } }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      sx={{
                        paddingLeft: { sm: '3rem' },
                        mb: '1.5rem',
                      }}
                    >
                      Befintlig ToloSolarkund
                    </Typography>
                    <FormikLogin />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{
                      paddingLeft: { sm: '3rem' },
                      mb: '1.5rem',
                      textAlign: 'center',
                    }}
                  >
                    Registrera konto
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ paddingLeft: { sm: '3rem' }, rowGap: '1rem' }}
                  >
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 500,
                        bgcolor: 'background.paper',
                      }}
                    >
                      {registerList.map((item) => (
                        <ListItem
                          key={item.label}
                          sx={{ py: 0, minHeight: 32 }}
                        >
                          <ListItemIcon sx={{ color: 'inherit' }}>
                            <CheckIcon color="success" />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: 'medium',
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Grid container justifyContent="center">
                    <Stack sx={{ mt: '3rem', textAlign: 'center' }}>
                      <Typography sx={{ fontSize: '0.9rem', mb: '1rem' }}>
                          Behöver ett konto?{' '}
                        <CustomLink id="" to="/register">Anmäl dig här</CustomLink>
                      </Typography>
                      <Typography sx={{ fontSize: '0.9rem' }}>
                          Glömde din{' '}
                        <CustomLink id="" to="/password/reset/request">Lösenord?</CustomLink>
                      </Typography>
                      
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
    </ThemeProvider>
  )
}

export default Login
