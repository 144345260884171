import React from "react";
import AddFeedback from "./add/add.feedback.component";
import ContactPage from "../contact/contact.component";

const FeedbackPage = () => {
  return (
    <>
    <ContactPage />
      {/* <AddFeedback /> */}
    </>
  );
};

export default FeedbackPage;
