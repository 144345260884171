import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_Server_URL;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

const ContactService = (name, subject, email, phone, description, type) => {
  return api
    .post(process.env.REACT_APP_Server_URL + "feedbacks", {
      name,
      subject,
      email,
      phone,
      description,
      type,
    })
    .then((response) => {
      if (response.data.accessToken) {
        //console.log(JSON.stringify(response.data));
      }

      return response.data;
    });
};
export default ContactService;
