import { Dialog, Grid, IconButton } from "@mui/material";
import "./dialog.rating.section.home.scss";
import React, { useEffect, useRef, useState } from "react";
import CustomAvatar from "../../../../utils/custom.avatar";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing(3)};
  right: ${(props) => props.theme.spacing(3)};
  z-index: 10;
  height: 30px;
  width: 30px;
`;
const DialogRatingSectionHome = (props) => {
  const containerRef = useRef(null);

  return (
    <>
      <Dialog
        className="dialogRatingSection"
        open={props.open}
        onClose={props.handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            margin: "0px",
            padding: "0px",
            backgroundColor: "transparent",
          },
        }}
      >
        <div className="ratingSlide">
          <div className="cardRatingSlide">
            <div className="backgroundImageCardSlide">
              <img alt="" title="image" src={props.rate.background_image} />
              <div className="cardRatingAddress">
                <img
                  alt=""
                  className="iconSlide"
                  title="image"
                  src={props.homeData.ratings_section[0].earthIcon}
                />
                <h3>{props.rate.subTitle}</h3>
              </div>
            </div>

            <CustomAvatar
              className="profileImageCardSlide"
              review={props.rate}
            />
            <h2>{props.rate.title}</h2>
            <div ref={containerRef} style={ {      marginBottom: '19px',
        minHeight: '16vh'}}>
            {props.rate.text.map(
              (text, index) => (
                <p>
            {text}
          </p>
              )
            )}
            </div>
            <Grid className="starContainer">
              {Array.from({ length: 5 }).map((_, index) => (
                <img
                  alt=""
                  className="starImageCardSlide"
                  title="image"
                  src={props.homeData.ratings_section[0].starIcon}
                  key={index} // Adding a unique key for each star image
                />
              ))}
            </Grid>
          </div>
          <IconButton
            edge="end"
            onClick={props.handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 8,
              right: 20,
              zIndex: 1,
              backgroundColor: "rgb(161, 169, 181, .5)",
              transform: "scale(0.8)",
            }}
          >
            <CloseIcon
              style={{
                color: "white",
                "&:hover": {
                  color: "#FF8718",
                },
              }}
            />
          </IconButton>
        </div>
      </Dialog>
    </>
  );
};

export default DialogRatingSectionHome;
