import axios from "axios";
import eventBus from "../../common/EventBus";
import authHeader from "../../services/auth-header";


axios.defaults.baseURL = process.env.REACT_APP_Server_URL;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      eventBus.dispatch("logout");

      // Log out the user here
      localStorage.removeItem('token');
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);
export const listFeedbacks = (name,
  lastName,
  email,
  phone,
  title,
  description) => {
  return api.get(process.env.REACT_APP_Server_URL + "feedbacks?"+
  "name="+name+
  "&lastName="+  lastName+
  "&email="+  email+
  "&phone="+  phone+
  "&title="+  title+
  "&description=" + description, { headers: authHeader() });
};

export const postFeedback = (
  name,
  lastName,
  email,
  phone,
  title,
  description) => {
  return api.post(process.env.REACT_APP_Server_URL + "feedbacks", {
    name,
    lastName,
    email,
    phone,
    title,
    description
  }, { headers: authHeader() })
  .then(response => {
    if (response.data.accessToken) {
      //console.log(JSON.stringify(response.data));
    }

    return response.data;
  });
};

export const getFeedback = (feedbackId) => {
  return api.get(process.env.REACT_APP_Server_URL + "feedback/"+ feedbackId, { headers: authHeader() });
};

export const deleteFeedback = (feedbackId) => {
  return api.delete(process.env.REACT_APP_Server_URL + "feedbacks/"+ feedbackId, { headers: authHeader() });
};

