import "./drop.down.menu";
import CustomLink from "../../../utils/custom.link";
import { Grid } from "@mui/material";
import "./drop.down.menu.scss";
import React, { useState } from "react";

const DropDownMenu = ({
  location,
  id,
  key,
  index,
  to,
  label,
  subMenu,
  // isActive,
  section,
  setMenuHovered
}) => {
  const getDescriptionText = (description, index) => {
    // if (showFullText && activeIndex === index) {
    //   return description;
    // } else {
    return description.length > 70
      ? description.slice(0, 70) + "..."
      : description;
    // }
  };
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const showDropDown = () => {
    setDropdownVisible(true);
    setMenuHovered(index)
  };
  const hideDropDown = () => {
    setDropdownVisible(false);
    setMenuHovered(null)
  };
  return (
    <div
      className="dropdown"
      onMouseEnter={() => showDropDown()
      
      }
      onMouseLeave={() => hideDropDown()}
    >
      <CustomLink
        className={"menuLink"}
        id={id}
        to={to}
        section={section}
      >
        {label}
      </CustomLink>
      <div id={`dropdown`} className={`dropdown-content ${isDropdownVisible ? "visible" : ""}`}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {subMenu.map((item, index) => (
            <React.Fragment key={index}>
              {/* <CustomLink
              className={
                "dropButton " +
                (isActive(location.pathname, item.to) ? "activeLink" : "")
              }
              id={item.id}
              to={item.to}
            >
              <img src={item.image} alt="" />
              {item.label}
            </CustomLink> */}
              <Grid className="dropDownItem" item xl={3} lg={3} md={6} xs={12}>
                <div onClick={() => hideDropDown(false)}>
                  <CustomLink
                    id={item.id}
                    to={item.to}
                    section={section}
                  >
                    <img alt="" title="image" src={item.image} />
                    <h2>{item.label}</h2>

                    <p> {getDescriptionText(item.description, index)}</p>
                  </CustomLink>
                </div>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
};
export default DropDownMenu;
