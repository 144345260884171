import * as React from "react";
import CustomField from "../../../utils/custom.field";

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PersonalAddProjectForm from "./personal.project.add.form.component";
import MarkAddProjectForm from "./mark.project.add.form.component";
import ConsumptionAddProjectForm from "./consumption.project.add.form.component";
import CustomSelectField from "../../../utils/custom.select.field";
// import AddProjectMap from "./project.add.map.component";
// import GoogleMapsLoader from './google.map.loader';

import CustomFieldDate from "../../../utils/custom.field.date";
import CustomButton from "../../../utils/custom.button";
import CustomImageSelect from "../../../utils/custom.image.select";
import SelectServiceQuote from "./sections/select.service";
import SelectAdviceQuote from "./sections/select.advice";
import dayjs from "dayjs";
import CustomMapField from "./project.add.map.component";

const roofMaterials = [
  {
    name: "Betong/tegel",
    src: "https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/quote/Betong-Tegel.png",
    id: 1,
  },
  {
    name: "TRP plåt",
    src: "https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/quote/Plåt-TRP.png",
    id: 2,
  },
  {
    name: "Falsad plåt",
    src: "https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/quote/Plåt-Falsad.png",
    id: 3,
  },
  {
    name: "Papp",
    src: "https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/quote/Papp.png",
    id: 4,
  },
];

const serviceTypes = [
  {
    name: "Takbyte",
    value: "Takbyte",
    label: "Takbyte",
    id: 1,
  },
  {
    name: "Solpaneler",
    value: "Solpaneler",
    label: "Solpaneler",
    id: 2,
  },
];

const ProjectAddForm = (props) => {
  const [roofMaterial, set_roofMaterial] = React.useState(1);
  const [serviceType, set_serviceType] = React.useState([]);
  const [serviceList, add_serviceList] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(null);
  //const [selectedImage, setSelectedImage] = React.useState(null);
  const [takShape, setTakShape] = React.useState("");
  const [advice, setAdvice] = React.useState();
  const currentDate = dayjs();

  // setRoofTypes(roofMaterials)
  const formikProps = {
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      description: "",
      roofMaterial: "",
      serviceType: [],
      takShape: "",
      annualConsumption: "10000",
      advice: "",
      availabilityTime: currentDate,
      // roofLength: "",
      // roofWidth: "",
      // mainFuse: '',
      // electricityCompany: '',
      // roofAngle: '',
      // proposeRoofArieas: '',

      // placementOfInverter: '',
      // diggingForCable: '',
      // energyStorage: '',
      // media: '',
      // moreInfo: '',
      // contacted: '',
      // offerSent: '',
      // followUpDescr: '',
      // location: {
      //   lat: 37.7749,
      //   lng: -122.4194
      // }
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required(""),
      phone: Yup.string().required(""),
      email: Yup.string()
        .email("Detta är inte ett giltigt e-postmeddelande.")
        .required("Detta fält är obligatoriskt!"),
      address: Yup.string().required(
        "Fyll i din fullständiga adress och välja den."
      ),
      description: Yup.string(),
      roofMaterial: Yup.string(),
      serviceType: Yup.array(),
      takShape: Yup.string(),
      availabilityTime: Yup.array().nullable(),
      advice: Yup.string(),
      // roofLength: Yup.string(),
      // roofWidth: Yup.string(),
      //annualConsumption: Yup.string(),
      // mainFuse: Yup.string(),
      // electricityCompany: Yup.string(),
      // roofAngle: Yup.string(),
      // proposeRoofArieas: Yup.string(),

      // placementOfInverter: Yup.string(),
      // diggingForCable: Yup.string(),
      // energyStorage: Yup.string(),
      // media: Yup.string(),
      // moreInfo: Yup.string(),
      // contacted: Yup.string(),
      // offerSent: Yup.string(),
      // followUpDescr: Yup.string(),
    }),
    onChange: (values, event) => {},
    onSubmit: (values, event) => {
      console.log(values);
      props.handleSubmit(values);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  };

  return (
    <Formik {...formikProps}>
      {(formik) => (
        <Form>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={5}
          >
            <Grid item xs={12} md={6}>
              {/* <GoogleMapsLoader apiKey="AIzaSyCDXtmPd5JQhNUpps_d5Rz3FP4RIT5GgzU" /> */}
              <PersonalAddProjectForm />
              <Field
                name="availabilityTime"
                type="date"
                label="När jag önskar bli kontaktad"
                required={false}
                component={CustomFieldDate}
                multiline
                rows={6}
              />
              <ErrorMessage
                component="span"
                className="error-message"
                style={{ color: "red" }}
                name="availabilityTime"
              />
              {/* advice, setAdvice */}
              <p style={{ paddingTop: "24px" }}>
                Klicka i nedan om du önskar fylla i information på förhand om
                ditt tak och din elförbrukning - eller bara önskar komma i
                kontakt med oss.
              </p>
              <Field
                name="advice"
                label="Advice"
                required={true}
                advice={advice}
                setAdvice={setAdvice}
                component={SelectAdviceQuote}
              />
              <ErrorMessage
                component="span"
                className="error-message"
                name="advice"
              />

              {advice === "false" && (
                <>
                  <h2>Välj tjänst</h2>
                  <Field
                    name="serviceType"
                    label="Service Type"
                    required={true}
                    set_value={add_serviceList}
                    options={serviceTypes}
                    set_serviceType={set_serviceType}
                    serviceType={serviceType}
                    component={SelectServiceQuote}
                  />
                  <ErrorMessage
                    component="span"
                    className="error-message"
                    name="serviceType"
                  />

                  {(formik.values.serviceType.includes("Takbyte") ||
                    formik.values.serviceType.includes("Solpaneler")) && (
                    <>
                      <h2>Taktyp</h2>
                      <Field
                        component={CustomSelectField}
                        type="select"
                        name="roofMaterial"
                        label="Taktyp"
                        categories={roofMaterials}
                        set_CategoryName={set_roofMaterial}
                        placeholder="roof type"
                      />
                      <ErrorMessage
                        component="span"
                        className="error-message"
                        name="roofMaterial"
                      />

                      <h2>Form på tak</h2>
                      <Field
                        component={CustomImageSelect}
                        type="select"
                        name="takShape"
                        label="Takshape"
                        images={props.quoteData.takShapes} //takShape, setTakShape
                        setSelectedImage={setTakShape}
                        selectedImage={takShape}
                        placeholder="Takshape"
                      />
                      <ErrorMessage
                        component="span"
                        className="error-message"
                        name="takShape"
                      />
                      {/* <Field
                        name="roofLength"
                        type="text"
                        label="Höjd"
                        required={false}
                        component={CustomField}
                      />
                      <ErrorMessage component="span" className="error-message"name="roofLength" />
                      <Field
                        name="roofWidth"
                        type="text"
                        label="Bredd"
                        required={false}
                        component={CustomField}
                      />
                      <ErrorMessage component="span" className="error-message"name="roofWidth" /> */}
                      {/* <Field
                component={CustomSelectField}
                type="select"
                name="serviceType"
                label="Service Type"
                required={true}
                categories={serviceTypes}
                // set_Category={set_serviceType}
                set_CategoryName={set_serviceType}
                placeholder="roof type"
              />
              <ErrorMessage component="span" className="error-message"name='serviceType' /> */}
                    </>
                  )}

                  {formik.values.serviceType.includes("Solpaneler") && (
                    <>
                      <h2>Elförbrukning/år</h2>
                      <Field
                        name="annualConsumption"
                        label="Elförbrukning"
                        required={true}
                        component={ConsumptionAddProjectForm}
                      />
                      <ErrorMessage
                        component="span"
                        className="error-message"
                        name="annualConsumption"
                      />
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="address"
                label="address"
                type="text"
                apiKey={"AIzaSyCDXtmPd5JQhNUpps_d5Rz3FP4RIT5GgzU"}
                component={CustomMapField}
              />
              <ErrorMessage
                component="span"
                className="error-message"
                style={{ color: "red" }}
                name="address"
              />
              {/* <Field
            name="serviceType"
            label="Service Type"
            required={true}
            component={ServiceType} /> */}
              <h2>Särskilda önskemål</h2>
              <Field
                name="description"
                type="textarea"
                label="Beskrivning"
                required={false}
                onClick={() => {}}
                component={CustomField}
                multiline
                rows={6}
              />
              <ErrorMessage
                component="span"
                className="error-message"
                name="description"
              />
              {/* <HeightAddProjectForm />
          <MaterialAddProjectForm /> */}
              <div style={{ paddingTop: "20px" }}>
                <CustomButton
                  id="quote_form_submit"
                  type="submit"
                  label="Skicka"
                  size="24px"
                  width="164px"
                  height="54"
                  color="#FFFFFF"
                  // background="background-image: linear-gradient(to right, #FBB034, #ffdd00);"
                  background="#FFA300"
                  disabled={props.loading}
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default ProjectAddForm;
