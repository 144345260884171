import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./custom.sub.menu.link.scss";
import { Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomLink from "./custom.link";
const LinkItem = styled(Link)(({ background_color, font_color }) => ({
  backgroundColor: background_color,
  color: font_color + " !important",
  textDecoration: "none",
  margin: "0px",
  "&:hover": {
    textDecoration: "none",
  },
}));

const CustomSubMenuLink = ({
  children,
  to,
  id,
  className,
  closeMenu,
  onMouseEnter,
  onMouseLeave,
  section,
  indicatorLeave,
  background_color,
  font_color,
  onClick,
  label,
  closeModal,
  subMenu,
  selectedModal,
}) => {
  return (
    <>
      {children}
      {selectedModal === id && (
        <div
          className=""
          style={{
            marginLeft: "20px",
            marginBottom: "29px",
            // borderBottom: "3px solid #E5E5E5",
          }}
        >
          {subMenu.map((subMenuItem, index) => (
            <div
              key={index}
              style={{
                paddingBottom: "11px",
                paddingTop: "11px",
                borderBottom: "1px solid #E5E5E5",
              }}
            >
              <CustomLink
                className="menuMobileLink subMenuMobileLink"
                id={index}
                to={subMenuItem.to}
                //onClick={subMenu ? openModal : ""}
                //closeModal={subMenu ? closeModal : ""}
                closeMenu={closeMenu}
              >
                <span className="text">{subMenuItem.label}</span>
                <span className="arrow">
                  <ArrowForwardIosIcon />
                </span>
              </CustomLink>
            </div>
            // <LinkItem
            //   key={index}
            //   onMouseEnter={onMouseEnter}
            //   onMouseLeave={onMouseLeave}
            //   className={className}
            //   background_color={background_color}
            //   font_color={font_color}
            //   to={to}
            //   //onClick={handleClick}
            // >
            //   <span className="text">{subMenuItem.label}</span>
            //   <span className="arrow">
            //     <ArrowForwardIosIcon />
            //   </span>
            // </LinkItem>
          ))}
        </div>
      )}
    </>
  );
};
export default CustomSubMenuLink;
