import { Grid, useMediaQuery } from "@mui/material";
import "./service.section.home.scss";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../utils/custom.button";
import { useTheme } from "@emotion/react";
import ServiceCardSectionHome from "./service.card.section.home";
import ServiceSliderSectionHome from "./service.slider.section.home";
import ServiceOwlSectionHome from "./service.owl.section.home";

const ServiceSectionHome = (props) => {
  const [showFullText, setShowFullText] = useState(false);
  const textLimit = 100; // Character limit for initial display
  const [activeIndex, setActiveIndex] = useState(null);
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));
  const getDescriptionText = (description, index) => {
    if (showFullText && activeIndex === index) {
      return description;
    } else {
      return description.length > textLimit
        ? description.slice(0, textLimit) + "..."
        : description;
    }
  };
  // useEffect(() => {
  //   const onTouchMove = (e) => {
  //     if (e.touches.length > 1) return;
  //     const touch = e.touches[0] || e.changedTouches[0];
  //     const startX = touch.pageX;

  //     const handleTouchMove = (e) => {
  //       const touch = e.touches[0] || e.changedTouches[0];
  //       const endX = touch.pageX;

  //       if (endX < startX) {
  //         e.preventDefault();
  //       }
  //     };

  //     document.body.addEventListener("touchmove", handleTouchMove, {
  //       passive: false,
  //     });

  //     return () => {
  //       document.body.removeEventListener("touchmove", handleTouchMove);
  //     };
  //   };

  //   document.body.addEventListener("touchmove", onTouchMove, {
  //     passive: false,
  //   });

  //   return () => {
  //     document.body.removeEventListener("touchmove", onTouchMove);
  //   };
  // }, []);
  return (
    <>
      <section id="serviceSection" className="serviceSection">
        <div className="serviceSectionHeader">
          <h1 className="serviceTitle">
            {props.homeData.services_section[0].title}
          </h1>
          <p href="#" className="serviceDescription">
            {props.homeData.services_section[0].description}
          </p>
        </div>

        <div className="serviceSectionContainer">
          <img
            className="serviceSectionImage"
            alt=""
            title="image"
            src={props.homeData.services_section[0].image}
          />
          <Grid container className="listServiceSection">
            {/* {isLG && <ServiceSliderSectionHome homeData={props.homeData} />} */}
            {isLG && <ServiceOwlSectionHome homeData={props.homeData} />}
            {!isLG && <ServiceCardSectionHome homeData={props.homeData} />}
          </Grid>
        </div>
      </section>
    </>
  );
};

export default ServiceSectionHome;
