import { Grid, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useTheme } from "@emotion/react";
import DesktopMainSectionProjectsDetails from "./desktop.main.section.details.project";
import MobileMainSectionProjectsDetails from "./mobile.main.section.details.project";

const MainSectionProjectsDetails = ({ backgroundImage }) => {
  let navigate = useNavigate();
  const [openProjectAdd, setOpenProjectAdd] = React.useState(false);
  const projectAdd = () => {
    //setOpenProjectAdd(true);
    navigate("/project/add", {
      state: { scrollToTop: true },
    });
  };
  const closeProjectAdd = () => {
    setOpenProjectAdd(false);
  };
  const learnMore = () => {
    navigate("/about");

    //setOpenProjectAdd(false);
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <section className="mainSectionProjectDetails">
      {isMobile ? (
        <MobileMainSectionProjectsDetails
          backgroundImage={backgroundImage}
          projectAdd={projectAdd}
          closeProjectAdd={closeProjectAdd}
          learnMore={learnMore}
        />
      ) : (
        <DesktopMainSectionProjectsDetails
          backgroundImage={backgroundImage}
          projectAdd={projectAdd}
          closeProjectAdd={closeProjectAdd}
          learnMore={learnMore}
        />
      )}
    </section>
  );
};

export default MainSectionProjectsDetails;
