// import { Box, Container, Grid, Typography } from "@mui/material";
// import React from "react";
import AuthService from "../../../services/auth.service";
import "./style.css";

// import DetailsEditProfile from "./details.edit.profile.component";
// import MainEditProfile from "./main.edit.profile.component";

// const EditProfile = () => {
//   const currentUser = AuthService.getCurrentUser();

//   return (
//     <>
//     <Box
//       component="main"
//       sx={{
//         flexGrow: 1,
//         py: 8
//       }}
//     >
//       <Container maxWidth="lg">
//         <Typography
//           sx={{ mb: 3 }}
//           variant="h2"
//         >
//           Account
//         </Typography>
//         <Grid
//           container
//           spacing={3}
//         >
//           <Grid
//             item
//             lg={4}
//             md={6}
//             xs={12}
//           >
//             <MainEditProfile user={currentUser}/>
//           </Grid>
//           <Grid
//             item
//             lg={8}
//             md={6}
//             xs={12}
//           >
//             <DetailsEditProfile user={currentUser}/>
//           </Grid>
//         </Grid>
//       </Container>
//     </Box>
//       {/* <header className="jumbotron">
//         <h3>
//           <strong>Edit Profile</strong>
//         </h3>
//       </header>
//       <p>
//         <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
//         {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
//       </p>
//       <p>
//         <strong>Id:</strong> {currentUser.id}
//       </p>
//       <p>
//         <strong>E-post:</strong> {currentUser.email}
//       </p>
//       <strong>Authorities:</strong>
//       <ul>
//         {currentUser.roles &&
//           currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
//       </ul> */}
//     </>
//   );
// };
import { useState, useEffect } from "react";

import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import eventBus from "../../../common/EventBus";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Theme from "../../../utils/theme";
import {
  Box,
  Tabs,
  Typography,
  Tab,
  Divider,
  Avatar,
  Card,
  Grid,
  Chip,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { getUser } from "../../users/users.service";
import UserPersonalDetails from "../../users/details/user.detail.personal.component";
import UserOrders from "../../users/details/user.detail.orders.component";
import UserHistory from "../../users/details/user.detail.history.component";

const GridStyled = styled(Grid)({
  minHeight: "60vh",
});

const TitleStyled = styled(Typography)({
  fontWeight: 500,
});
const TitleItem = styled(Typography)({
  marginTop: Theme.spacing(2),
});

const ChipStyled = styled(Chip)({
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffff",
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: "content-box",
          padding: 3,
          fontSize: "1.125rem",
        },
      },
    },
  },
});

function EditProfile(props) {
  const currentUser = AuthService.getCurrentUser();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState("2");
  const [user, setUser] = useState([]);
  const [orders, setOrders] = useState([]);
  const [username, setUsername] = useState("");
  const [roles, setRoles] = useState([]);
  const { id } = 0;
  useEffect(() => {
    getUser(currentUser["id"]).then(
      (response) => {
        console.log(response);
        setUser(response.data.user);
        setUsername(response.data.user["username"]);
        setRoles(response.data.roles);
        setOrders(response.data.orders);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setUser(_content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
      }
    );
  }, [id]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleEdit = (event) => {};

  return (
    <>
      <div className="profile">
        <Box className="profileHeader" sx={{ p: 1, display: "flex" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className="leftProfileHeader">
              <Avatar
                className="profileAvatar"
                variant="rounded"
                sx={{ width: 70, height: 70 }}
              >
                {username.toUpperCase().charAt(0)}
              </Avatar>
            </div>
            <div className="rightProfileHeader">
              <ChipStyled
                className="profileHeaderChip"
                label={user.accountStatus + " - " + roles + ""}
                size="small"
              />

              <h1>{" " + user.firstName + " " + user.lastName} </h1>

              <h3>
                <LocationOnIcon sx={{ color: "grey" }} />
                {user.city + ", " + user.country}
              </h3>
            </div>

            <Grid item lg={1} justifyContent="flex-end">
              <Grid>
                {/* <EditUser />                 */}

                {/* <UserStatus user={user} setUser={setUser} setRoles={setRoles} /> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <GridStyled>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                centered
                aria-label="lab API tabs example"
              >
                <Tab value="1" label="Order" />
                <Tab value="2" label="Personlig information" />
                <Tab value="3" label="ADRESS" />
              </Tabs>
            </Box>
            <TabPanel value="1">
              <UserOrders orders={orders} setOrders={setOrders} />
            </TabPanel>
            <TabPanel value="2">
              <UserPersonalDetails user={user} setUser={setUser} />
            </TabPanel>
            <TabPanel value="3">
              <UserHistory user={user} setUser={setUser} />
            </TabPanel>
          </TabContext>
        </GridStyled>
      </div>
    </>
  );
}

export default EditProfile;
