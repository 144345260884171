import axios from "axios";
import { environment } from "../environments/environment";



class AuthService {
  login(username, password) {
    return axios
      .post(process.env.REACT_APP_Server_URL + "signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  resetPassword(password,token) {
    return axios
      .post(process.env.REACT_APP_Server_URL + "password/reset", {
        token,
        password
      })
      .then(response => {
        return response.data;
      });
  }
  requestPassword(email) {
    return axios
      .post(process.env.REACT_APP_Server_URL + "password/reset/request", {
        email
      })
      .then(response => {
        return response.data;
      });
  }
  requestUsername(email) {
    return axios
      .post(process.env.REACT_APP_Server_URL + "username/request", {
        email
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }

  register(firstName,
    lastName,
    telephone,
    email,
    username,
    password,
    contactPersonId,
    companyOrgNumber) {
    return axios.post(process.env.REACT_APP_Server_URL + "signup", {
      firstName,
      lastName,
      telephone,
      email,
      username,
      password,
      contactPersonId,
      companyOrgNumber
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
