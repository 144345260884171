import { Grid } from "@mui/material";
import "./mobile.main.section.FAQss.scss";
import React, { useEffect, useState } from "react";

const MobileMainSectionFAQss = (props) => {
  const [zIndex, setZIndex] = useState(1);

  const handleScroll = () => {
    //console.log(window.scrollY);
    if (window.scrollY >= 150) {
      setZIndex(-1);
    } else {
      setZIndex(1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log(props.FAQssData.main_section);
  return (
    <Grid className="mobileMainSectionFAQs">
      <div className="videoContainer" style={{ zIndex: -1 }}>
        <video
          src={props.FAQssData.main_section.video}
          type="video/mp4"
          className="mainSectionVideo"
          autoPlay
          loop
          muted
          playsInline
          style={{ transform: "translateZ(0)", position: "fixed" }}
        />
      </div>
      {/* <div className="mainSectionImageMask"></div> */}
      {/* <img
        className="mainSectionImage"
        alt=""
        title="image"
        src={props.FAQssData.main_section.videoGif}
      /> */}
      {/* <img
        className="mainSectionImage"
        alt=""
        title="image"
        src={props.FAQssData.main_section.image}
      />*/}

      <div className="mainSectionContainerMobile" style={{ zIndex: zIndex }}>
        <h1 className="mainTitle">
          {props.FAQssData.main_section.title[0]}
          <span> {props.FAQssData.main_section.title[1]} </span>
        </h1>

        <p className="mainSubTitle">
          {props.FAQssData.main_section.description_Mobile}
        </p>
      </div>
      <Grid className="welcomeMainSection">
        <h3>VÄLKOMMEN TILL TOLO SOLAR</h3>
      </Grid>
      {/* <AddProject closeProjectAdd={closeProjectAdd} openProjectAdd={openProjectAdd} /> */}
    </Grid>
  );
};

export default MobileMainSectionFAQss;
