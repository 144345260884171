import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
//import 'react-quill/dist/quill.bubble.css'; // import the styles

const RichEditorField = ({ field, form, ...props }) => {

    const [value, setValue] = React.useState(field.value);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean'],
            ['paste']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'code-block'
    ]

    const handleChange = (value) => {
        setValue(value);
        form.setFieldValue(field.name, value);
    }

    return (
        <ReactQuill
            value={value}
            {...props}
            name={field.name}
            
            onChange={handleChange} 
            modules={modules}
            formats={formats}
        />
    )
}

export default RichEditorField
