import * as React from "react";
import ServiceAddForm from "./service.add.form.component";
import {
  Box,
  Button,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { postService } from "../service.service";

const steps = [
  "Markera",
  "kompassriktning",
  "Takets lutning",
  "Höjd till takfot",
  "Taktyp",
  "Elförbrukning",
  "Personal",
];

const AddService = (props) => {
  const [message, setMessage] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const handleSubmit = (values) => {
    setLoading(true);
    setMessage("");
    const {
      name,
      lastName,
      email,
      phone,
      annualConsumption,
      mainFuse,
      electricityCompany,
      roofMaterial,
      roofAngle,
      proposeRoofArieas,
      roofLength,
      roofWidth,
      placementOfInverter,
      diggingForCable,
      energyStorage,
      media,
      moreInfo,
      contacted,
      offerSent,
      followUpDescr,
    } = values;
    postService(
      name,
      lastName,
      email,
      phone,
      annualConsumption,
      mainFuse,
      electricityCompany,
      roofMaterial,
      roofAngle,
      proposeRoofArieas,
      roofLength,
      roofWidth,
      placementOfInverter,
      diggingForCable,
      energyStorage,
      media,
      moreInfo,
      contacted,
      offerSent,
      followUpDescr
    ).then(
      (response) => {
        setMessage(response.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
      }
    );
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </>
      ) : (
        <>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <Box sx={{ pt: 10 }}>
            <ServiceAddForm
              loading={loading}
              handleSubmit={handleSubmit}
              activeStep={activeStep}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
      <Container
        maxWidth={false}
        sx={{
          paddingTop: { sm: "3rem" },
          backgroundColor: { xs: "#fff", md: "#fff" },
        }}
      >
        {/* <ServiceAddForm handleClose={handleClose} handleSubmit={handleSubmit} /> */}
        {loading && <span className="spinner-border spinner-border-sm"></span>}
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
      </Container>
    </Box>
  );
};
export default AddService;
