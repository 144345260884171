import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormControlLabel, Checkbox, Button } from "@mui/material";
import CustomField from "../../utils/custom.field";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { Typography } from "@mui/material";

import isPersonalNumber from "../../utils/personal.number.js";
import { validatePassword } from "../../utils/validate.password";
import { validatePersonalNumber } from "../../utils/validate.personal.number";

const FormikRegister = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // HandleRegister.Post({
  //   username: '',
  //   password: '',
  // })
  // form props
  function HandleRegister(formValue) {
    setLoading(true);
    setMessage("");
    //console.log(formValue)
    const {
      firstName,
      lastName,
      telephone,
      email,
      username,
      password,
      contactPersonId,
      companyOrgNumber,
    } = formValue;
    AuthService.register(
      firstName,
      lastName,
      telephone,
      email,
      username,
      password,
      contactPersonId,
      companyOrgNumber
    ).then(
      (response) => {
        setMessage(response.data.message);
        navigate("/profile");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //console.log(resMessage)
        setMessage(resMessage);
        setLoading(false);
      }
    );
  }

  const formikProps = {
    initialValues: {
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      username: "",
      password: "",
      contactPersonId: "",
      companyOrgNumber: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Detta fält är obligatoriskt!"),
      lastName: Yup.string().required("Detta fält är obligatoriskt!"),
      telephone: Yup.string().required("Detta fält är obligatoriskt!"),
      email: Yup.string()
        .email("Detta är inte ett giltigt e-postmeddelande.")
        .required("Detta fält är obligatoriskt!"),
      username: Yup.string().required("Detta fält är obligatoriskt!"),
      password: validatePassword(Yup),
      contactPersonId: validatePersonalNumber(Yup),
      companyOrgNumber: Yup.string()
        .required("Detta fält är obligatoriskt!")
        .min(10, "måste vara 10 nummer")
        .max(10, "måste vara 10 nummer"),
    }),
    onSubmit: (values) => {
      //console.log(values)
      HandleRegister(values);
    },
  };

  return (
    <Formik {...formikProps}>
      <Form>
        <Field
          name="firstName"
          type="text"
          label="Förnamn"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="firstName"
        />
        <Field
          name="lastName"
          type="text"
          label="Efternamn"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="lastName"
        />
        <Field
          name="telephone"
          type="text"
          label="Telefon"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="telephone"
        />
        <Field
          name="email"
          type="email"
          label="E-postadress"
          required={true}
          component={CustomField}
        />
        <ErrorMessage component="span" className="error-message" name="email" />
        <Field
          name="username"
          type="text"
          label="Användarnamn"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="username"
        />
        <Field
          name="password"
          label="Lösenord"
          type="password"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="password"
        />

        <Typography variant="h6" component="p" sx={{}}>
          Företagsuppgifter:
        </Typography>
        <p>
          Ditt personnummer kommer inte att lagras och kommer endast att
          användas i syfte att skapa ditt kundkonto/kundnummer.
        </p>
        <Field
          name="contactPersonId"
          type="text"
          label="Personnummer till företagets"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="contactPersonId"
        />
        <Field
          name="companyOrgNumber"
          type="text"
          label="Organisationsnummer (10 siffror)"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="companyOrgNumber"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Kom ihåg mig"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          Sign In
        </Button>
      </Form>
    </Formik>
  );
};

export default FormikRegister;
