import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import "./style.scss";
import { getAnalytics, logEvent } from "firebase/analytics";

const StyledButton = styled(Button)(
  ({
    font_color,
    border_radius,
    padding,
    margin,
    background,
    height,
    width,
    border,
    size,
    transform,
  }) => ({
    width: width + "!important",
    minWidth: width + "!important",
    height: height + " !important",
    // display: 'flex',
    display: "inlineBlock",
    borderRadius: border_radius ? border_radius : "96px 96px 96px 96px",
    // border: '0px solid #000000',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: padding ? padding : "12px 24px",
    margin: margin ? margin : "12px 24px",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: size,
    lineHeight: "120%",
    backgroundColor: background,
    textTransform: "none",
    color: font_color,
    border: "1px solid " + (border ? border : background ? background : "#fff"),
    position: "relative",
    overflow: "hidden",
    // transition: 'color 0.1s ease-in-out',
    transition: "transform 400ms  ease-in-out",

    "&:hover": {
      transition: "transform 400ms ease-in-out",

      transform: "scale(1.05)",
      //animation: 'customButtonAnimation 3s both',
      color: font_color,
      backgroundColor: background,
      fontSize: size,
      letterSpacing: transform ? "" : "2.5px",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "-100%",
      width: "50%",
      height: "100%",
      transform: "skewx(45deg) translateX(0)",
      transition: "1s",
      background: "rgba(255,255,255,0.2)",
    },
    "&:hover:before": {
      //animation: 'customButtonAnimation 3s both',

      transform: transform ? "" : "skewx(45deg) translateX(550%)",
    },
  })
);

const CustomButton = ({
  children,
  onClick,
  id,
  border_radius,
  padding,
  margin,
  label,
  color,
  background,
  height,
  width,
  icon,
  border,
  size,
  type,
  disabled,
  transform,
}) => {
  const analytics = getAnalytics();

  function triggerIt() {
    if (typeof onClick === "function") {
      onClick();
    }
    logEvent(analytics, id, {
      event_name: id,
    });
  }
  return (
    <div className="customButtonContainer">
      <StyledButton
        disabled={disabled}
        type={type}
        color="primary"
        aria-label="add"
        onClick={triggerIt}
        font_color={color}
        background={background}
        height={height}
        width={width}
        border={border}
        size={size}
        border_radius={border_radius}
        padding={padding}
        margin={margin}
        transform={transform}
      >
        {label ? label : icon ? icon : ""}
      </StyledButton>
    </div>
  );
};
export default CustomButton;
