import React, { useState } from "react";
import MediaCards from "../../../home/sections/gallery/media.gallery.section.home";
import "./FAQs.section.products.scss";
import { Grid, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTheme } from "@emotion/react";
const FAQsSectionProducts = (props) => {
  const [showContent, setShowContent] = useState(false);

  const handleHeaderClick = () => {
    setShowContent(!showContent);
  };
  return (
    <section className="FAQsSectionProducts">
     <a href="/FAQs" style={{textDecoration:"none"}}><h1>{props.productsData.FAQs_section[0].title}</h1></a> 
      <Grid container className="">
        {props.productsData.FAQs_section[0].FAQ_list.map((item, i) => (
          <FAQCard key={i} item={item} />
        ))}
      </Grid>
    </section>
  );
};
const FAQCard = ({ item }) => {
  const [showContent, setShowContent] = useState(false);
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const handleHeaderClick = () => {
    setShowContent(!showContent);
  };

  return (
    <>
      <Grid item xs={12} className="cardFAQs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="cardHeader"
          onClick={handleHeaderClick}
        >
          <Grid item xs={9}>
            <h2>{item.title}</h2>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              className=""
              style={{
                width: isMD ? "22px" : "35px",
                height: isMD ? "22px" : "35px",
                borderRadius: "50%",
                backgroundColor: "#1C96DF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: isMD ? "15px" : "16px",

                zIndex: 1,
              }}
            >
              {showContent ? (
                <RemoveIcon
                  style={{
                    fontSize: isMD ? "18px" : "22px",

                    color: "white",
                    transition: "opacity 2s",
                    opacity: showContent ? 1 : 0,
                    transform: "rotate(-45deg)",
                  }}
                />
              ) : (
                <AddIcon
                  style={{
                    fontSize: isMD ? "18px" : "22px",
                    color: "white",
                    transition: "opacity 2s",
                    opacity: showContent ? 0 : 1,
                  }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {showContent && (
        <div
          className="cardContent"
          style={{
            transition: "opacity 4s",
            opacity: showContent ? 1 : 0,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={0.5}
          >
            <Grid item xs={2}>
              <img
                className="iconFAQsProductsSectionImage"
                style={{
                  maxWidth: isMD ? "35px" : "48px",
                  maxHeight: isMD ? "48px" : "70px",
                }}
                alt=""
                title="image"
                src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/products/light.png"
              />
            </Grid>
            <Grid item xs={10}>
              <p>{item.description}</p>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
export default FAQsSectionProducts;
