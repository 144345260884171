import { Typography, Container, ListItem, Grid, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import orderService from "../order.service";
import { printOrder } from "../order.service";

import { useEffect, useState, useRef } from "react";
import HeaderOrderDetails from "./header.details.order.component";
import FooterOrderDetails from "./footer.details.order.component";
import TableOrderDetails from "./table.details.order.component";
import ProductsOrderDetails from "./products.details.order.component";
import TermsOrderDetails from "./terms.details.order.component";
import PrintIcon from "@mui/icons-material/Print";
import OrderStatus from "./status.details.order.component";
import jsPDF from "jspdf";
import domToImage from "dom-to-image";
import SignatureOrderDetails from "./signature.details.order.component";
import LastOrderDetails from "./last.details.order.component";
import FileSaver from "file-saver";

function OrderDetails(props) {
  const { id } = useParams();
  const [order, setOrder] = useState([]);
  const [userOrder, setUserOrder] = useState([]);
  const [products, setproducts] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    orderService.getOrder(id).then(
      (response) => {
        //console.log(response.data);
        setOrder(response.data);
        setUserOrder(response.data.users[0]);
        setproducts(response.data.orderProducts);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.data ||
          error.toString();
        //console.log(error.response.data);
        setMessage(_content);
      }
    );
  }, [id]);

  const componentRef = useRef();
  const footerRef = useRef();
  const headerRef = useRef();
  const contentPage = document.getElementById("contentPage"); //useRef();
  const firstPage = document.getElementById("firstPage"); //useRef();
  const secondPage = document.getElementById("secondPage"); //useRef();
  const thirdPage = document.getElementById("thirdPage"); //useRef();
  const lastPage = document.getElementById("lastPage"); //useRef();
  const footer = document.getElementById("footer"); //useRef();

  const convertPageToImage = async (page) => {
    try {
      const dataUrl = await domToImage.toPng(page);
      return dataUrl;
    } catch (error) {
      console.error(`Failed to convert ${page.id} to image:`, error);
      return null;
    }
  };

  const generatePDF = async () => {
    printOrder(id).then(
      (response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank"; // open PDF in a new tab/window
        a.download =
          "ToloSolar_" + order.status + "#" + order.tracking_code + ".pdf";
        a.click();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.data ||
          error.toString();
        //console.log(error.response);
        setMessage(_content);
      }
    );

    // setLoading(true)

    // const doc = new jsPDF("p", "mm", "a4");
    // const margins = { top: 10, bottom: 10, left: 10, right: 10 };
    // const pageWidth = doc.internal.pageSize.width - margins.left - margins.right;
    // const pageHeight = doc.internal.pageSize.height - margins.top - margins.bottom;
    // console.log(pageHeight)
    // // Convert the first page to an image
    // const footerDataUrl = await convertPageToImage(footer);

    // const firstPageDataUrl = await convertPageToImage(firstPage);
    // if (firstPageDataUrl) {
    //   const imgWidth = pageWidth;

    //   const imgHeight = imgWidth / (firstPage.offsetWidth / firstPage.offsetHeight);
    //   doc.addImage(firstPageDataUrl, "JPEG", margins.left, margins.top, imgWidth, imgHeight);

    //   if (footerDataUrl) {
    //     doc.addImage(footerDataUrl, "JPEG", margins.left, 277, imgWidth, 10);
    //   }
    // }

    // // Add a new page for the second page
    // doc.addPage();

    // // Convert the second page to an image
    // const secondPageDataUrl = await convertPageToImage(secondPage);
    // if (secondPageDataUrl) {
    //   const imgWidth = pageWidth;
    //   const imgHeight = imgWidth / (secondPage.offsetWidth / secondPage.offsetHeight);
    //   doc.addImage(secondPageDataUrl, "JPEG", margins.left, margins.top, imgWidth, imgHeight);
    //   if (footerDataUrl) {
    //     doc.addImage(footerDataUrl, "JPEG", margins.left, 277, imgWidth, 10);
    //   }
    // }

    // // Add a new page for the last page
    // doc.addPage();
    // const ThirdPageDataUrl = await convertPageToImage(thirdPage);
    // if (ThirdPageDataUrl) {
    //   const imgWidth = pageWidth;
    //   const imgHeight = imgWidth / (thirdPage.offsetWidth / thirdPage.offsetHeight);
    //   doc.addImage(ThirdPageDataUrl, "JPEG", margins.left, margins.top, imgWidth, imgHeight);
    //   if (footerDataUrl) {
    //     doc.addImage(footerDataUrl, "JPEG", margins.left, 277, imgWidth, 10);
    //   }
    // }

    // // Add a new page for the last page
    // doc.addPage();

    // // Convert the last page to an image
    // const lastPageDataUrl = await convertPageToImage(lastPage);
    // if (lastPageDataUrl) {
    //   const imgWidth = pageWidth;
    //   const imgHeight = imgWidth / (lastPage.offsetWidth / lastPage.offsetHeight);
    //   doc.addImage(lastPageDataUrl, "JPEG", margins.left, margins.top, imgWidth, imgHeight);
    //   if (footerDataUrl) {
    //     doc.addImage(footerDataUrl, "JPEG", margins.left, 277, imgWidth, 10);
    //   }
    // }
    // //doc.output('blob')
    // doc.save("ToloSolar_"+order.status+ "#"+order.tracking_code+".pdf");
    // setLoading(false);
  };

  // // Add content to the first page
  // doc.text('Page 1', 10, 10);

  // // Add a new page to the document
  // doc.addPage();

  // // Add content to the second page
  // doc.text('Page 2', 10, 10);

  // // Set the header and footer content
  // doc.setHeader('Header content', { width: '100%' });
  // doc.setFooter('Footer content', { width: '100%' });

  // // Save the PDF document
  // doc.save('document.pdf');
  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={2}>
          <Button
            disabled={loading}
            onClick={generatePDF}
            variant="contained"
            color="success"
            endIcon={<PrintIcon />}
          >
            Skriva Ut
          </Button>
        </Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}>
          <OrderStatus order={order} setOrder={setOrder} />
        </Grid>
      </Grid>
      <Grid
        ref={componentRef}
        style={{
          padding: "10pt",
        }}
      >
        <Grid id="firstPage">
          <Grid>
            <HeaderOrderDetails order={order} userOrder={userOrder} />
          </Grid>
          <Grid>
            <TableOrderDetails order={order} products={products} />
          </Grid>
          <Grid>
            <SignatureOrderDetails />
          </Grid>
        </Grid>
        <Grid id="secondPage">
          <ProductsOrderDetails products={products} />
        </Grid>
        <Grid id="thirdPage">
          <TermsOrderDetails order={order} />
        </Grid>
        <Grid id="lastPage">
          <LastOrderDetails order={order} />
        </Grid>
        <Grid
          id="footer"
          ref={footerRef}
          style={{
            height: "50px",
          }}
        >
          <FooterOrderDetails order={order} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default OrderDetails;
