// import { useEffect } from 'react';
// import DateTime from 'react-datetime';
// import moment from 'moment';

// import 'react-datetime/css/react-datetime.css';

// const CustomFieldDate = ({
//     field, //name, values, onChange, onBlur, onSubmit
//     form: { touched, errors, setFieldValue },
//     ...props
// }) => {
//   useEffect(() => {
//     const date = moment().hour(12).toDate();
//     setFieldValue(props.name, date);
//   }, [setFieldValue, props.name]);

//   return (
//     <>
//       <DateTime
//         inputProps={{ name: props.name }}
//         value={moment(field.value).toDate()}
//         onChange={(newValue) => setFieldValue(props.name, moment(newValue).toDate())}
//         dateFormat="YYYY-MM-DD"
//         timeFormat="h:mm A"
//       />

//       {touched[field.name] && errors[field.name] ? (
//         <span>{errors[field.name]}</span>
//       ) : null}
//     </>
//   );
// };

// export default CustomFieldDate;

import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { TextField } from "@mui/material";

// const CustomFieldDate = ({
//   field, //name, values, onChange, onBlur, onSubmit
//   form: { touched, errors, setFieldValue },
//   ...props
// }) => {
//   // useEffect(() => {
//   //   const date = new Date();
//   //   date.setHours(10);
//   //   setFieldValue(props.name, date);
//   // }, [setFieldValue, props.name]);
//   return (
//     <>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DateTimePicker
//           label={props.label}
//           sx={{ width: "100%" }}
//           value={field.value}
//           onChange={(newValue) => setFieldValue(props.name, newValue)}
//           renderInput={(params) => (
//             <TextField fullWidth sx={{ width: "100%" }} {...params} />
//           )}
//           //ampm
//           //disableFuture
//         />
//       </LocalizationProvider>
//       {touched[field.name] && errors[field.name] ? (
//         <span>{errors[field.name]}</span>
//       ) : null}
//     </>
//   );
// };
const CustomFieldDate = ({
  field, //name, value, onChange, onBlur, onSubmit
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  useEffect(() => {
    const date = dayjs().set("hour", 10); // Use dayjs to set the hour
    setFieldValue(field.name, date.format("YYYY-MM-DDTHH:mm:ss")); // Format the date as ISO 8601 string
  }, [setFieldValue, field.name]);

  const handleDateChange = (newValue) => {
    if (newValue) {
      const formattedDate = newValue.format("YYYY-MM-DDTHH:mm:ss"); // Format the selected date
      setFieldValue(field.name, formattedDate);
    } else {
      setFieldValue(field.name, null);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={props.label}
          sx={{ width: "100%" }}
          value={field.value ? dayjs(field.value) : null} // Convert field value to dayjs object
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField fullWidth sx={{ width: "100%" }} {...params} />
          )}
          // ampm
          // disableFuture
        />
      </LocalizationProvider>
      {touched[field.name] && errors[field.name] ? (
        <span>{errors[field.name]}</span>
      ) : null}
    </>
  );
};
export default CustomFieldDate;
