import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { postProject } from "../project.service";
import "./style.scss";
import ProjectAddForm from "./project.add.form.component";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Player } from "@lottiefiles/react-lottie-player";

const steps = [
  "Markera",
  "kompassriktning",
  "Takets lutning",
  "Höjd till takfot",
  "Taktyp",
  "Elförbrukning",
  "Personal",
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing(3)};
  right: ${(props) => props.theme.spacing(3)};
  z-index: 10;
  height: 30px;
  width: 30px;
`;

const AddProject = (props) => {
  const [message, setMessage] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const handleSubmit = (values) => {
    setLoading(true);
    setMessage("");
    const {
      name,
      email,
      phone,
      address,
      description,
      roofMaterial,
      serviceType,
      annualConsumption,
    } = values;
    postProject(
      name,
      email,
      phone,
      address,
      description,
      roofMaterial,
      serviceType,
      annualConsumption
    ).then(
      (response) => {
        setMessage(response.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
      }
    );
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={props.openProjectAdd}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
      // maxWidth={isMobile ? 'sm' : 'lg'}

      scroll="body"
      disablebackdropclick={"true"}
      // tap={true}
    >
      <CloseButton
        onClick={props.closeProjectAdd}
        aria-label="delete"
        size="small"
      >
        <CloseIcon fontSize="inherit" />
      </CloseButton>

      <Grid container alignItems="flex-start" className="projectAddContainer">
        <Grid item sm={12} md={6} className="projectAddLeftRow">
          <ProjectAddForm handleSubmit={handleSubmit} />
        </Grid>
        <Grid item sm={12} md={6} className="projectAddRightRow">
          <div className="fixedImage">
            <Player
              src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/solar-house.json"
              className="player"
              loop
              autoplay
            />
            {/* <img title="image" alt="" src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/project/add.project.svg" /> */}
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default AddProject;
