import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomChip from './custom.chip.component';
function UserPersonalDetails(props) {
  //orders
  return (

    <Grid container direction="row"
      justifyContent="flex-start"
      alignItems="center">
      {props.orders ? (
        // <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item lg={6} xs={12} sx={{  paddingX: { sm: '1rem' } }}>
          {props.orders?.map((order, index) => (
            <>
            order {index}
              {/*    
            <ProductCard product={product} index={index} key={index} /> 
            */}
            </>
          ))}
        </Grid>
      ) : (
        <Typography>Det finns inget orders för att visa!</Typography>
      )}
    
    </Grid>
  );
}

export default UserPersonalDetails;