import * as React from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import ProductAddBar from "./product.add.bar.component";
import ProductAddForm from "./product.add.form.component";
import CustomFab from "../../../utils/custom.fab";
import { Container, Grid } from "@mui/material";
import { postProduct } from "../../../services/product.service";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddProduct = (props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    //console.log(values)
    setLoading(true);
    setMessage("");
    //console.log(formValue)
    const {
      title,
      description,
      Make,
      category,
      images,
      price,
      quantity_stock,
      discount_percent,
      discount_datetime_start,
      discount_datetime_end,
      height,
      width,
      html_body,
    } = values;
    postProduct(
      title,
      description,
      Make,
      category,
      images,
      price,
      quantity_stock,
      discount_percent,
      discount_datetime_start,
      discount_datetime_end,
      height,
      width,
      html_body
    ).then(
      (response) => {
        setMessage(response.data);
        //console.log(response.data)
        setOpen(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //console.log(resMessage)
        setMessage(resMessage);
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <CustomFab onClick={handleClickOpen}></CustomFab>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <ProductAddBar handleClose={handleClose} handleSubmit={handleSubmit} />
        <Container
          maxWidth={false}
          sx={{
            paddingTop: { sm: "3rem" },
            backgroundColor: { xs: "#fff", md: "#f4f4f4" },
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            rowSpacing={5}
            sx={{
              maxWidth: { sm: "45rem" },
              marginInline: "auto",
            }}
          >
            <ProductAddForm
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
};
export default AddProduct;
