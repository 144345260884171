import { Box, Typography } from "@mui/material";
import eventBus from "../../common/EventBus";
import AddUser from "./add/user.add.component";
import { listUsers } from "./users.service";
//import Stack from '@mui/material/Stack';
import UsersTable from "./users.table.component";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SearchField from "./mainbar/search.field.component";
import { useState, useEffect } from "react";


function UserSearch() {
  const [users, setUsers] = useState([]);
  const [user, setUser] =  useState('');
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchUsers("");
  }, []);
  useEffect(() => {
    if (!user
    ) return;
    fetchUsers(user);
  }, [user,
  ]);
  
  const fetchUsers = async (user) => {
    listUsers(user).then(
        (response) => {
          setUsers(response.data.users);
          console.log(response.data.users)
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(message);
        }
      );
  };

  const filteredUsers = users.filter((item) => {
    return (
      item.username.toLowerCase().includes(user.toLowerCase()) ||
      item.email.toLowerCase().includes(user.toLowerCase())  ||
      item.contactPersonId.toLowerCase().includes(user.toLowerCase())
      
    );
  }) || users?.map((user) => user);

  const formikProps = {
    initialValues: {
      user: "",
    },
    validationSchema: Yup.object().shape({}),
    onChange: (values, event) => {
      setUsers(values.user);
    }
  }

  return (
    <>
      <Formik {...formikProps}>
        {formik => (
          <Form>
             <SearchField users={filteredUsers} formik={formik} setUser={setUser} />
             {/* <Typography> Sök på användarn genom användarnamn, personnummer eller e-post</Typography> */}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: '100%', mb: 1, paddingY: 3 }}>
        <UsersTable users={filteredUsers} setUsers={setUsers} />
      </Box>
      <AddUser />
    </>
  );
}

export default UserSearch;
