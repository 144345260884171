import * as React from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import UserAddBar from "./user.add.bar.component";
import UserAddForm from "./user.add.form.component";
import CustomFab from "../../../utils/custom.fab";
import { Container, Grid } from "@mui/material";
import generatePassword from "../../../utils/generate.random.password.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { validatePassword } from "../../../utils/validate.password";
import { validatePersonalNumber } from "../../../utils/validate.personal.number";

import authService from "../../../services/auth.service";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUser = (props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const formikProps = {
    initialValues: {
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      username: "",
      contactPersonId: "",
      companyOrgNumber: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Detta fält är obligatoriskt!"),
      lastName: Yup.string().required("Detta fält är obligatoriskt!"),
      telephone: Yup.string().required("Detta fält är obligatoriskt!"),
      email: Yup.string()
        .email("Detta är inte ett giltigt e-postmeddelande.")
        .required("Detta fält är obligatoriskt!"),
      username: Yup.string().required("Detta fält är obligatoriskt!"),
      contactPersonId: validatePersonalNumber(Yup),
      companyOrgNumber: Yup.string()
        .required("Detta fält är obligatoriskt!")
        .min(10, "måste vara 10 nummer")
        .max(10, "måste vara 10 nummer"),
    }),
    onSubmit: (values) => {
      //console.log(values)
      props.handleSubmit(values);
    },
  };

  function handleSubmit(formValue) {
    //setLoading(true)
    setMessage("");
    //console.log(formValue)
    const {
      firstName,
      lastName,
      telephone,
      email,
      username,
      contactPersonId,
      companyOrgNumber,
    } = formValue;
    const password = generatePassword();
    authService
      .register(
        firstName,
        lastName,
        telephone,
        email,
        username,
        password,
        contactPersonId,
        companyOrgNumber
      )
      .then(
        (response) => {
          setMessage(response.data.message);
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          //console.log(resMessage)
          setMessage(resMessage);
          setLoading(false);
        }
      );
  }

  return (
    <div>
      <CustomFab onClick={handleClickOpen}></CustomFab>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Formik {...formikProps}>
          {(formik) => (
            <Form>
              <UserAddBar
                handleClose={handleClose}
                handleSubmit={handleSubmit}
              />
              <Container
                maxWidth={false}
                sx={{
                  paddingTop: { sm: "3rem" },
                  backgroundColor: { xs: "#fff", md: "#f4f4f4" },
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  rowSpacing={5}
                  sx={{
                    maxWidth: { sm: "45rem" },
                    marginInline: "auto",
                  }}
                >
                  <UserAddForm
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                  />
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
export default AddUser;
