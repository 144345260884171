import axios from "axios";
import eventBus from "../../common/EventBus";
import authHeader from "../../services/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_Server_URL;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      eventBus.dispatch("logout");

      // Log out the user here
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);
export const listProjects = (title, category, Make, price, height, width) => {
  return api.get(
    process.env.REACT_APP_Server_URL +
      "projects?" +
      "title=" +
      title +
      "&category=" +
      category +
      "&Make=" +
      Make +
      "&price=" +
      price +
      "&height=" +
      height +
      "&width=" +
      width,
    { headers: authHeader() }
  );
};

export const postProject = (
  name,
  email,
  phone,
  address,
  description,
  roofMaterial,
  serviceType,
  annualConsumption,
  takShape,
  advice,
  availabilityTime
  // name,
  // lastName,
  // email,
  // phone,
  // annualConsumption,
  // mainFuse,
  // electricityCompany,
  // roofMaterial,
  // roofAngle,
  // proposeRoofArieas,
  // roofLength,
  // roofWidth,
  // placementOfInverter,
  // diggingForCable,
  // energyStorage,
  // media,
  // moreInfo,
  // contacted,
  // offerSent,
  // followUpDescr,
) => {
  return api
    .post(
      process.env.REACT_APP_Server_URL + "projects",
      {
        name,
        email,
        phone,
        address,
        description,
        roofMaterial,
        serviceType,
        annualConsumption,
        takShape,
        advice,
        availabilityTime,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.accessToken) {
        //console.log(JSON.stringify(response.data));
      }

      return response.data;
    });
};

export const getProject = (projectId) => {
  return api.get(process.env.REACT_APP_Server_URL + "project/" + projectId, {
    headers: authHeader(),
  });
};

export const deleteProject = (projectId) => {
  return api.delete(
    process.env.REACT_APP_Server_URL + "projects/" + projectId,
    { headers: authHeader() }
  );
};
