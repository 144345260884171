// import { getProducts } from "../../services/product.service";

import * as React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
//import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { ErrorMessage, Field } from "formik";
import CustomAutoField from "../../../../utils/custom.auto.field";

const SearchField = (props) => {
  //console.log(props.formik.values.title);

    return (
        <Grid>
            <Grid item xs={12}>
            {/* <p>{props.formik.values.title}</p> */}
                   <Field
                    freeSolo={true}
                    component={CustomAutoField}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.title}
                    onBlur={props.formik.handleBlur}
                    set_value={props.set_title}

                    name="title"
                    label="Sök"
                    options={props.results?.map((option) => option.title)}
                    formik={props.formik}
                    placeholder="Sök"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="alert alert-danger"
                  /> 
            </Grid>
        </Grid>
    );
}

export default SearchField;
