import PostCategory from "../category.post.component";
import SearchResult from "./result.component";


function CategoriesSearch() {  
  return (
    <>
    <SearchResult />
    <PostCategory />
    </>
  );
}

export default CategoriesSearch;
