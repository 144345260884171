import { Chip, Grid, Paper, Typography } from "@mui/material";

function formatDate(datee) {
  if (datee) {
    const date = new Date(datee);

    return date.toLocaleString("sv-SE").slice(0, 10);
  }
}
function formatAndIncreaseDate(datee) {
  if (datee) {
    const date = new Date(datee);

    date.setDate(date.getDate() + 14);
    return date.toLocaleString("sv-SE").slice(0, 10);
  }
}

function HeaderOrderDetails(props) {
  const cardStyle = {
    position: "relative",
    // other styles for the card component
  };
  const fixedDivStyle = {
    position: "absolute",
    top: "10%",
    right: "5%",
    transform: "translate(-50%, -50%)",
    // other styles for the fixed div
  };
  return (
    <Grid
      style={cardStyle}
      sx={{
        p: 3,
        margin: "auto",
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid style={fixedDivStyle}>
        <Chip label={props.order.status} />

        {/* your fixed div content here */}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={4}>
          <img alt="Tolo Solar" width={62} height={24} src={"/logo.png"} />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="p"
            component="p"
            style={{
              paddingTop: "51pt",
            }}
          >
            Nyckelfärdig offert, Solpanel - tak #{props.order.tracking_code}
          </Typography>
          <Typography
            variant="h3"
            component="h3"
            style={{
              paddingTop: "41pt",
            }}
          >
            Kund:
          </Typography>
          <Typography
            variant="p"
            component="p"
            style={{
              paddingTop: "0",
            }}
          >
            {props.userOrder.firstName + " " + props.userOrder.lastName}
          </Typography>
          <Typography variant="p" component="p">
            {props.userOrder.address}
          </Typography>
          <Typography variant="p" component="p">
            {props.userOrder.postNumber + " " + props.userOrder.city}
          </Typography>{" "}
          {/*  */}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          paddingTop: "25pt",
          textAlign: "left",
        }}
      >
        {" "}
        <Grid item xs={3}>
          <Typography variant="p" component="p">
            Offert datum
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
            }}
            variant="h3"
            component="h3"
          >
            Giltigt till
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="p" component="p">
            {formatDate(props.order.updatedAt)}
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
            }}
            variant="h3"
            component="h3"
          >
            {formatAndIncreaseDate(props.order.updatedAt)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderOrderDetails;
