import TextField from '@mui/material/TextField'

const CustomField = ({
    field, //name, values, onChange, onBlur, onSubmit
    form: { touched, errors },
    set_value,
    ...props
}) => {
  
  return (
    <>
    {/* <label htmlFor={field.name}>{props.labelName}</label> */}
    <TextField
          type={props.type}
          name={props.name}
          id={props.name}
          label={props.label}
          autoComplete={props.name}
          placeholder={props.placeholder}
          error={touched[props.name] && Boolean(errors[props.name])}
          autoFocus={props.autoFocus}
          InputLabelProps={props.InputLabelProps}
          required={props.required}
          fullWidth
          multiline={props.multiline}
          rows={props.rows}
          disabled={props.disabled}
          onChange={event => {
            console.log(event.target.value)
            
            if (set_value) {
              set_value(event.target.value);
            }
        }}
        style={{ fontFamily: 'Montserrat' }}

          margin="normal" //dense normal
          variant="outlined" // standard filled outlined
          color="warning" // warning secondary success
          {...field}
        />
    {/* {errors[field.name] && touched[field.name] ?
        <span>{ errors[field.name]}</span>  
    :null} */}
    </>
  )
}

export default CustomField
