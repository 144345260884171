import "./style.css"

const JobsPage = () => {

    return (
        <>
        
        </>
    );
};

export default JobsPage;
