import "./main.contact.scss"

const MainContact = (props) => {

  return (
    <section className="mainContactPage">
      <div className="imageContactBackgroundImage">
        <img alt="" title="image" src={props.contactData.main_section[0].imageContactBackgroundImage} />
      </div>
      <div className="contactPageSecondTitle">
      <h1>
          {props.contactData.main_section[0].title}
        </h1>
        <h2 >
          {props.contactData.main_section[0].title2}
        </h2>
      </div>
    </section>
  );
};

export default MainContact;
