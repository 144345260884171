import { Grid, useMediaQuery } from "@mui/material";
import "./project.details.slider.component.scss";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

const ProjectDetailsSlider = ({ images }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [slideIndex, setSlideIndex] = useState(1);
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  const numSlides = images.length;
  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;

      // Handle wrap-around when reaching the end or beginning of slides
      if (newIndex > numSlides) {
        newIndex = 1;
      } else if (newIndex < 1) {
        newIndex = numSlides;
      }

      return newIndex;
    });
  };

  const currentSlide = (n) => {
    setSlideIndex(n);
  };

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  const showSlides = (n) => {
    let i;
    const slides = document.getElementsByClassName("cardProjectSlide");
    const dots = document.getElementsByClassName("dot");

    if (n > slides.length) {
      setSlideIndex(1);
    } else if (n < 1) {
      setSlideIndex(slides.length);
    }

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = i === n - 1 ? "block" : "none";
    }

    for (i = 0; i < dots.length; i++) {
      dots[i].classList.remove("active");
    }

    dots[n - 1].classList.add("active");
  };

  return (
    <Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className="ProjectDetailsSlider"
      >
        <Grid item xs={1}>
          <button className="projectPrevButton" onClick={() => plusSlides(-1)}>
            ❮
          </button>
        </Grid>

        <Grid item xs={10} className="projectSlider">
          {images.map((image, index) => (
            <div key={index} className="cardProjectSlide fade">
              <Grid
                container
                className={`cardProjectSection ${
                  activeIndex === index ? "cardProjectSectionActive" : ""
                }`}
                // onMouseEnter={() => handleMouseEnter(index)}
                // onMouseLeave={() => handleMouseLeave(index)}
              >
                <img
                  alt=""
                  className="cardProjectSectionImage"
                  title="image"
                  src={image}
                />
              </Grid>
            </div>
          ))}
        </Grid>
        <Grid item xs={1}>
          <button className="projectNextButton" onClick={() => plusSlides(1)}>
            ❯
          </button>
        </Grid>
      </Grid>
      <Grid
        className="slideshow-dots"
        style={{
          textAlign: "center",
          alignItems: "center",
          paddingTop: "73px",
        }}
      >
        {images.map((img, index) => (
          <span
            key={index}
            className={`dot ${slideIndex === index + 1 ? "active" : ""}`}
            onClick={() => currentSlide(index + 1)}
          ></span>
        ))}
      </Grid>
    </Grid>
  );
};

export default ProjectDetailsSlider;
