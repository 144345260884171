import React, { useEffect, useState } from 'react';
import { NotificationsContext } from '../context';

const NotificationsProvider = (props) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    const notificationsStorage = localStorage.getItem("notifications");
    if (notificationsStorage) {
      setNotifications(JSON.parse(notificationsStorage));
    }
  }, []);
  return (
    <NotificationsContext.Provider value={[notifications, setNotifications]}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;