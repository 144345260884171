// import { getProducts } from "../../services/product.service";

import * as React from "react";
import Paper from '@mui/material/Paper';

//import Stack from '@mui/material/Stack';
import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import EditUser from "./edit/user.edit.component";

function UsersTable(props) {
  function formatDate(date) {
    if (date) {
      return date.slice(0, 10)

    }
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell align="left"></TableCell> */}
            <TableCell align="center">
              id</TableCell>
            <TableCell align="center">Username</TableCell>
            <TableCell align="center">Company Name</TableCell>
            <TableCell align="center">firstName</TableCell>
            <TableCell align="center">Last Name</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Personnummer</TableCell>
            <TableCell align="center">Telephone</TableCell>
            <TableCell align="center">Account Status</TableCell>
            <TableCell align="center">Org Number</TableCell>
            <TableCell align="center">moms registrerings nummer</TableCell>
            <TableCell align="center">address</TableCell>
            <TableCell align="center">Post Number</TableCell>
            <TableCell align="center">City</TableCell>
            <TableCell align="center">Country</TableCell>
            <TableCell align="center">Job Title</TableCell>
            <TableCell align="center">Employment Date</TableCell>

            {/* <TableCell align="center">Secondary Address</TableCell>
                <TableCell align="center">Secondary City</TableCell>
                <TableCell align="center">Secondary Country</TableCell>
                <TableCell align="center">Secondary Post Number</TableCell> */}

            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Updated At</TableCell>
            <TableCell align="center">Delete Date</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>

          {props.users.map((user) => (

            <TableRow
              component={Link} to={`/users/${user.id}/`} key={user.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ textDecoration: 'none' }}
            >
              {/* <TableCell align="left">
                <EditUser props={user} />
              </TableCell> */}
              <TableCell align="center" component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell align="center">{user.username}</TableCell>
              <TableCell align="center">{user.companyName}</TableCell>
              <TableCell align="center">{user.firstName}</TableCell>
              <TableCell align="center">{user.lastName}</TableCell>
              <TableCell align="center">{user.email}</TableCell>
              <TableCell align="center">{user.contactPersonId}</TableCell>
              <TableCell align="center">{user.telephone}</TableCell>
              <TableCell align="center">
              <Chip label={user.accountStatus} />
              </TableCell>
              <TableCell align="center">{user.companyOrgNumber}</TableCell>
              <TableCell align="center">{user.moms_VAT_nr}</TableCell>
              <TableCell align="center">{user.address}</TableCell>
              <TableCell align="center">{user.postNumber}</TableCell>
              <TableCell align="center">{user.city}</TableCell>
              <TableCell align="center">{user.country}</TableCell>
              <TableCell align="center">{user.job_title}</TableCell>
              <TableCell align="center">{user.employmentDate}</TableCell>

              {/* <TableCell align="center">{user.secondaryAddress}</TableCell>
                <TableCell align="center">{user.secondaryCity}</TableCell>
                <TableCell align="center">{user.secondaryCountry}</TableCell>
                <TableCell align="center">{user.secondaryPostNr}</TableCell> */}

              <TableCell align="center">{formatDate(user.createdAt)}</TableCell>
              <TableCell align="center">{formatDate(user.updatedAt)}</TableCell>
              <TableCell align="center">{formatDate(user.deleteDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UsersTable;