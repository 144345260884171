import * as React from "react";
import CustomField from "../../../utils/custom.field";

import { Grid, Slider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from "yup";
import PersonalAddServiceForm from "./personal.service.add.form.component";
import MaterialAddServiceForm from "./material.service.add.form.component";
import MarkAddServiceForm from "./mark.service.add.form.component";
import SlopeAddServiceForm from "./slope.service.add.form.component";
import ConsumptionAddServiceForm from "./consumption.service.add.form.component";
import DirectionAddServiceForm from "./direction.service.add.form.component";
import HeightAddServiceForm from "./height.service.add.form.component";
// import AddServiceMap from "./service.add.map.component";
// import GoogleMapsLoader from './google.map.loader';

const ServiceAddForm = (props) => {
  const formikProps = {
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      //annualConsumption: '',
      mainFuse: '',
      electricityCompany: '',
      roofMaterial: '',
      roofAngle: '',
      proposeRoofArieas: '',
      //roofLength: '',
      //roofWidth: '',
      placementOfInverter: '',
      diggingForCable: '',
      energyStorage: '',
      media: '',
      moreInfo: '',
      contacted: '',
      offerSent: '',
      followUpDescr: '',
      location: {
        lat: 37.7749,
        lng: -122.4194
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Detta fält är obligatoriskt!"),
      lastName: Yup.string().required("Detta fält är obligatoriskt!"),
      phone: Yup.string().required("Detta fält är obligatoriskt!"),
      annualConsumption: Yup.string().required("Detta fält är obligatoriskt!"),
      mainFuse: Yup.string().required("Detta fält är obligatoriskt!"),
      electricityCompany: Yup.string(),
      roofMaterial: Yup.string(),
      roofAngle: Yup.string(),
      proposeRoofArieas: Yup.string(),
      roofLength: Yup.string(),
      roofWidth: Yup.string(),
      placementOfInverter: Yup.string(),
      diggingForCable: Yup.string(),
      energyStorage: Yup.string(),
      media: Yup.string(),
      moreInfo: Yup.string(),
      contacted: Yup.string(),
      offerSent: Yup.string(),
      followUpDescr: Yup.string(),
    }),
    onSubmit: (values, event) => {
      console.log(values)
      props.handleSubmit(values);
    }
  }
  
  return (
    <Formik {...formikProps}>
      {formik => (
        <Form >
          {/* <GoogleMapsLoader apiKey="AIzaSyCDXtmPd5JQhNUpps_d5Rz3FP4RIT5GgzU" /> */}
          {props.activeStep === 0 && (
            <MarkAddServiceForm />

          )}
          {props.activeStep === 1 && (
            <DirectionAddServiceForm />

          )}
          {props.activeStep === 2 && (
            <SlopeAddServiceForm />

          )}
          {props.activeStep === 3 && (
            <HeightAddServiceForm />
          )}
          {props.activeStep === 4 && (
            <MaterialAddServiceForm />

          )}
          {props.activeStep === 5 && (
            <ConsumptionAddServiceForm />
          )}
          {props.activeStep === 6 && (
            <PersonalAddServiceForm />

          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={props.loading}
          >
            Skicka
          </Button>
        </Form>
      )}
    </Formik>

  );
}
export default ServiceAddForm;
