/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
// import AdbIcon from "@mui/icons-material/Adb";
// import logo from "../../logo.svg";
import "./style.css";
import CustomButton from "../../utils/custom.button";
import { useNavigate } from "react-router-dom";
// import AddProject from "../project/add/project.add.component";
import NoStyleLink from "../../utils/custom.link";
import { Container, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import LanguageIcon from "@mui/icons-material/Language";
// import CopyrightIcon from '@mui/icons-material/Copyright';
// import Typography from '@mui/material/Typography';
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { getAnalytics, logEvent } from "firebase/analytics";

function Footer(props) {
  const analytics = getAnalytics();

  // const [openProjectAdd, setOpenProjectAdd] = React.useState(false);
  let navigate = useNavigate();

  const projectAdd = () => {
    navigate("/project/add", {
      state: { scrollToTop: true }, // Pass a flag to indicate scrolling to the top
    });
  };
  // const closeProjectAdd = () => {
  //     setOpenProjectAdd(false);
  // };
  return (
    <>
      <section className="lastSection">
        <h1 className="lastTitle">LÅT OSS PRATA OM DITT PROJEKT</h1>
        <CustomButton
          id="footer_get_a_quote"
          onClick={projectAdd}
          label="FÅ EN OFFERT"
          size="17px"
          width="209px"
          height="46px"
          color="#ffffff"
          background="#FF8718"
        />
      </section>
      <footer>
        <Container fixed>
          <Grid container className="footer">
            <Grid item xs={12} md={4}>
              <div className="footerDetails">
                <div className="footerLogo">
                  <img
                    title="image"
                    src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/logo/logo.footer.svg"
                    alt=""
                  />
                </div>
                <p>
                  Vi finns här för att bli era allierade i processen kring att
                  skapa grön energi.
                </p>
                <div className="footerContact">
                  <NoStyleLink id="footer_phone_number" to={""}>
                    <a href="tel:020899488" title="">
                      <CallIcon
                        style={{ color: "#757575", fontSize: "23px" }}
                      />

                      <span>020 899 488</span>
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_email" to={""}>
                    <a href="mailto:hello@tolosolar.se" title="">
                      <AlternateEmailIcon
                        style={{ color: "#757575", fontSize: "23px" }}
                      />
                      <span>hello@tolosolar.se</span>
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_website_link" to={""}>
                    <a href="tolosolar.se" title="">
                      <LanguageIcon
                        style={{ color: "#757575", fontSize: "23px" }}
                      />
                      <span>tolosolar.se</span>
                    </a>
                  </NoStyleLink>
                </div>
                <div className="footerIcons">
                  <NoStyleLink id="footer_facebook" to={""}>
                    <a
                      href="https://www.facebook.com/profile.php?id=100087619932415"
                      title=""
                    >
                      <FacebookIcon
                        style={{ color: "#1C96DF", fontSize: "24px" }}
                      />
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_twitter" to={""}>
                    <a href="https://twitter.com" title="">
                      <TwitterIcon
                        style={{ color: "#1C96DF", fontSize: "24px" }}
                      />
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_linkedin" to={""}>
                    <a
                      href="https://www.linkedin.com/company/tolosolar/"
                      title=""
                    >
                      <LinkedInIcon
                        style={{ color: "#1C96DF", fontSize: "24px" }}
                      />
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_instagram" to={""}>
                    <a href="https://www.instagram.com/tolosolar" title="">
                      <InstagramIcon
                        style={{ color: "#1C96DF", fontSize: "24px" }}
                      />
                    </a>
                  </NoStyleLink>
                </div>
              </div>
            </Grid>
            <Grid className="solenergi2Footer">
              <div className="solenergi2Footer">
                <img
                  title="image"
                  src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/medlem.png"
                  alt=""
                />
              </div>
            </Grid>
            <Grid xs={12} item md={4}>
              <div className="thirdFooterMenu">
                <h3 className="footerSubTitle">Användbara länkar</h3>
                <ul className="footerUl">
                  <NoStyleLink id="footer_about" to={"/about"}>
                    <a href="/about" title="">
                      Mer om oss
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_contact" to={"/contact"}>
                    <a href="submit" title="">
                      Kontakta oss
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_jobs" to={"/jobs"}>
                    <a href="submit" title="Vill du jobba med oss?">
                      Vi anställer
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_terms" to={"/terms"}>
                    <a href="submit" title="">
                      Villkor
                    </a>
                  </NoStyleLink>
                  {/* <NoStyleLink id="" to={"/login"}>

                                    <a href="submit" title="">
                                        Login
                                    </a>
                                </NoStyleLink> */}
                  <NoStyleLink id="footer_FAQs" to={"/FAQs"}>
                    <a href="submit" title="">
                      FAQ
                    </a>
                  </NoStyleLink>
                </ul>
              </div>
            </Grid>
            <Grid xs={12} item md={4}>
              <div className="fourthFooterMenu">
                <h3 className="footerSubTitle">Kontakta oss</h3>
                <div className="footerUl">
                  <NoStyleLink id="footer_tolo_solar_ab" to={""}>
                    <a href="/about" title="">
                      TOLO SOLAR AB
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_address" to={""}>
                    <a
                      href="https://www.google.com/maps/place/Tolo+Solar/@55.5628843,13.072671,17z/data=!4m6!3m5!1s0x4653a136e846cc51:0x4e44c64099749397!8m2!3d55.5630211!4d13.0719419!16s%2Fg%2F11kbfys49j"
                      title=""
                    >
                      Sporregatan 23, MALMÖ
                    </a>
                  </NoStyleLink>
                  <a>Org.nr 559403-0933</a>
                  <NoStyleLink id="footer_contact_us_email" to={""}>
                    <a href="mailto:hello@tolosolar.se" title="">
                      E-post: hello@tolosolar.se
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_contact_us_phone_number" to={""}>
                    <a href="tel:020 899 488" title="">
                      Växel: 020 899 488
                    </a>
                  </NoStyleLink>
                </div>
                <div className="solenergiFooter">
                  <img
                    title="image"
                    src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/medlem.png"
                    alt=""
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="footerCopyright">
            <p className="copyRightText">
              {/* <CopyrightIcon style={{ color: '#ffff', fontSize: '14px' }}/>  */}
              © 2022 Tolo Solar AB. All rights reserved.
            </p>
            <div className="copyRightList">
              <NoStyleLink id="footer_policy" to={"/policy"}>
                <a href="privacy" title="privacy policy" className="">
                  Privacy Policy
                </a>
              </NoStyleLink>
              <NoStyleLink id="footer_terms" to={"/terms"}>
                <a href="terms" title="Terms of Service" className="">
                  Terms of Service
                </a>
              </NoStyleLink>
              <NoStyleLink id="footer_cookies" to={"/cookies"}>
                <a href="cookies" title="Cookies Settings" className="">
                  Cookies Settings
                </a>
              </NoStyleLink>
            </div>
          </div>
        </Container>
        {/*<AddProject closeProjectAdd={closeProjectAdd} openProjectAdd={openProjectAdd} /> */}
      </footer>
    </>
  );
}

export default Footer;
