import { Grid, useMediaQuery } from "@mui/material";
import "./description.about.scss";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTheme } from "@emotion/react";

const DescriptionAbout = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    //AOS.refresh();
  }, []);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <section
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className="descriptionAboutPage"
    >
      <h1>{props.aboutData.description_section[0].title}</h1>
      <p>{props.aboutData.description_section[0].description}</p>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        className="descriptionAboutPageSectionLeft "
      >
        <Grid item xs={12} md={6}>
          <img
            alt=""
            title="image"
            src={props.aboutData.description_section[0].subImage1}
          />
        </Grid>

        <Grid
          data-aos={isMobile ? "fade-up" : "fade-left"}
          className="detailsAbout"
          item
          xs={12}
          md={6}
        >
          <h2>{props.aboutData.description_section[0].subTitle1}</h2>
          <p>{props.aboutData.description_section[0].subDescription1}</p>
          <p>{props.aboutData.description_section[0].subDescription2}</p>
          <p>{props.aboutData.description_section[0].subDescription3}</p>
          <div className="purpleBoxAbout">
            <p>{props.aboutData.description_section[0].subDescription4}</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        className="descriptionAboutPageSectionRight"
      >
        <Grid
          data-aos="fade-right"
          className="detailsAbout"
          item
          xs={12}
          md={6}
        >
          <h2>{props.aboutData.description_section[0].subTitle2}</h2>
          <p>{props.aboutData.description_section[0].subDescription5}</p>
          <p>{props.aboutData.description_section[0].subDescription6}</p>
          <div className="purpleBoxAbout">
            <p>{props.aboutData.description_section[0].subDescription7}</p>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            alt=""
            title="image"
            src={props.aboutData.description_section[0].subImage2}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default DescriptionAbout;
