import { Typography, Container, ListItem, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from "react-router-dom";
import eventBus from "../../common/EventBus";
import orderService from "./order.service";
// import { SeverityPill } from '../severity-pill';

function OrdersPage(props) {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    orderService.getListOrders().then(
      (response) => {
        console.log(response.data);
        setOrders(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(_content);

        setError(_content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
      }
    );
  }, []);
  function convertDate(date) {
    let d = new Date(date).toLocaleDateString("sv-SE");
    return d
  }
  return (
    <Container maxWidth="lg">

      <Card {...props}>
        <CardHeader title="Latest Orders" />
        <Grid>
          <Box >
            <Table>
              <TableHead>
                <TableRow>

                  <TableCell>
                    Order Ref
                  </TableCell>
                  <TableCell>
                    Customer
                  </TableCell>
                  <TableCell sortDirection="desc">
                    Date
                    {/* <Tooltip
                      enterDelay={300}
                      title="Sort"
                    >
                      <TableSortLabel
                        active
                        direction="desc"
                      >
                        Date
                      </TableSortLabel>
                    </Tooltip> */}
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell>
                    Freight Name
                  </TableCell>
                  <TableCell>
                    Freight Price
                  </TableCell>
                  <TableCell>
                    Invoice Subtotal
                  </TableCell>
                  <TableCell>
                    Invoice Taxes
                  </TableCell>
                  <TableCell>
                    Invoice Total
                  </TableCell>
                  <TableCell>
                    Payment Method
                  </TableCell>
                  <TableCell>
                    Tracking Code
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow
                    component={Link} to={`/orders/${order.id}/`}
                    hover
                    key={order.id}
                    style={{ textDecoration: 'none' }}
                  >
                    <TableCell>
                      {order.id}
                    </TableCell>
                    <TableCell>
                      {order.users[0]['firstName'] + " " + order.users[0]['lastName']}
                    </TableCell>
                    <TableCell>
                      {convertDate(order.createdAt)}
                      {/* {format(order.createdAt, 'dd/MM/yyyy')} */}
                    </TableCell>
                    <TableCell>
                      {order.status}
                      {/* <SeverityPill
                    color={(order.status === 'delivered' && 'success')
                    || (order.status === 'refunded' && 'error')
                    || 'warning'}
                  >
                    {order.status}
                  </SeverityPill> */}
                    </TableCell>
                    <TableCell>
                      {order.freight_name}
                    </TableCell>
                    <TableCell>
                      {order.freight_price === '0' ? "Gratis" : order.freight_price}
                    </TableCell>
                    <TableCell>
                      {order.invoiceSubtotal}
                    </TableCell>
                    <TableCell>
                      {order.invoiceTaxes}
                    </TableCell>
                    <TableCell>
                      {order.invoiceTotal}
                    </TableCell>
                    <TableCell>
                      {order.payment_method}
                    </TableCell>
                    <TableCell>
                      {order.tracking_code}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowRightIcon fontSize="small" />}
            size="small"
            variant="text"
          >
            View all
          </Button>
        </Box>
      </Card>
      {error}
    </Container>
  );
}

export default OrdersPage;
