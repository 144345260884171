import axios from "axios";
import eventBus from "../../common/EventBus";
import { environment } from "../../environments/environment";
import authHeader from "../../services/auth-header";

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      eventBus.dispatch("logout");

      // Log out the user here
      localStorage.removeItem('token');
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);

class OrderService {
  getListOrders() {
    return api.get(process.env.REACT_APP_Server_URL + "orders", { headers: authHeader() });
  }
  registerOrder(cart) {
    return api.post(process.env.REACT_APP_Server_URL + "orders", {
      cart
    }, { headers: authHeader() })
    .then(response => {
      if (response.data.accessToken) {
        console.log(JSON.stringify(response.data));
      }

      return response.data;
    });
  }
//

  getListproducts(orderId) {
    return api.get(process.env.REACT_APP_Server_URL + "orders/"+ orderId, { headers: authHeader() });
  }
  registerProduct(orderId, productName) {
    return api.post(process.env.REACT_APP_Server_URL + "orders/"+ orderId, {
        productName
    }, { headers: authHeader() })
    .then(response => {
      if (response.data.accessToken) {
        console.log(JSON.stringify(response.data));
      }

      return response.data;
    });
  }
  getOrder = (id) => {
    return api.get(process.env.REACT_APP_Server_URL + "orders/"+ id, { headers: authHeader() });
  };
}
export const patchOrder = (id, data) => {
    return api.post(process.env.REACT_APP_Server_URL + "orders/"+ id, {
      data
  }, { headers: authHeader() });
};
export const printOrder = (id) => {
  return api.get(process.env.REACT_APP_Server_URL + "orders/print/"+ id, { headers: authHeader(),
    responseType: 'arraybuffer' // Add responseType here
 });
};

export const deleteOrder = (id) => {
  return api.delete(process.env.REACT_APP_Server_URL + "orders/"+ id, { headers: authHeader() });
};

export default new OrderService();
