import axios from "axios";
import eventBus from "../../common/EventBus";
import authHeader from "../../services/auth-header";


axios.defaults.baseURL = process.env.REACT_APP_Server_URL;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      eventBus.dispatch("logout");

      // Log out the user here
      localStorage.removeItem('token');
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);
export const listServices = (title,
  category,
  Make,
  price,
  height,
  width) => {
  return api.get(process.env.REACT_APP_Server_URL + "services?"+
  "title="+title+
  "&category="+  category+
  "&Make="+  Make+
  "&price="+  price+
  "&height="+  height+
  "&width=" + width, { headers: authHeader() });
};

export const postService = (
  name,
  lastName,
  email,
  phone,
  annualConsumption,
  mainFuse,
  electricityCompany,
  roofMaterial,
  roofAngle,
  proposeRoofArieas,
  roofLength,
  roofWidth,
  placementOfInverter,
  diggingForCable,
  energyStorage,
  media,
  moreInfo,
  contacted,
  offerSent,
  followUpDescr,) => {
  return api.post(process.env.REACT_APP_Server_URL + "services", {
    name,
    lastName,
    email,
    phone,
    annualConsumption,
    mainFuse,
    electricityCompany,
    roofMaterial,
    roofAngle,
    proposeRoofArieas,
    roofLength,
    roofWidth,
    placementOfInverter,
    diggingForCable,
    energyStorage,
    media,
    moreInfo,
    contacted,
    offerSent,
    followUpDescr,
  }, { headers: authHeader() })
  .then(response => {
    if (response.data.accessToken) {
      //console.log(JSON.stringify(response.data));
    }

    return response.data;
  });
};

export const getService = (serviceId) => {
  return api.get(process.env.REACT_APP_Server_URL + "service/"+ serviceId, { headers: authHeader() });
};

export const deleteService = (serviceId) => {
  return api.delete(process.env.REACT_APP_Server_URL + "services/"+ serviceId, { headers: authHeader() });
};
