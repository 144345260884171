import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";

const CardsMobileReasonSectionHome = (props) => {
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container className="reasonSectionCardsMobile">
      {props.homeData.reason_section[0].reason_list.map((reason, index) => (
        <Grid
          key={index}
          className="card"
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          style={{paddingTop:'10px'}}
        >
            <img alt=""src={props.homeData.reason_section[0].icon} className="" />
            <h2>{reason.title}</h2>
        </Grid>
      ))}
      <Grid
        container
        direction="column"
        justifyContent="flex-center"
        alignItems="center"
        className="imageContainer"
      >
        <img alt='' src={props.homeData.reason_section[0].mainImage} className="" />
      </Grid>
    </Grid>
  );
};

export default CardsMobileReasonSectionHome;
