import { Grid, ListItem, Typography } from "@mui/material";

function TermsOrderDetails(props) {
  return (
    <>
      <Typography
        variant="h1"
        component="h1"
        style={{
          paddingTop: "4pt",
          textIndent: "0pt",
          textAlign: "center",
          backgroundColor: "#FEC44C",
        }}
      >
        ÖVRIGA VILLKOR
      </Typography>
      <br />
      <Typography
        variant="h4"
        component="h4"
        style={{
          paddingTop: "5pt",
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "left",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        Omfattning komplett och nyckelfärdig
      </Typography>
      <Typography
        variant="p"
        component="p"
        className="s11"
        style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Tolo Solar AB{" "}
        </span>
        <span className="p">
          åtar sig att för beställarens räkning genomföra installation av
          solcellsanläggning på beställarens fastighet till fast pris enligt
          offererat totalpris. Åtagande inkluderar:
        </span>
      </Typography>
      <ul id="l2">
        <ListItem data-list-text="-">
          <Typography
            variant="p"
            component="p"
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            - Projektering, inklusive framtagning av underlag för
            dimensionering, förslag på layout och montering,
            produktionsberäkning, kostnadsberäkning samt lönsamhetskalkyl.
          </Typography>
          <br />
        </ListItem>
        <ListItem data-list-text="-">
          <Typography
            variant="p"
            component="p"
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            - Frakt och leverans av specificerade produkter, inklusive allt
            erforderligt installations- och montagematerial till beställarens
            fastighet och upplyft av takmaterial till takplan.
          </Typography>
          <br />
        </ListItem>
        <ListItem data-list-text="-">
          <Typography
            variant="p"
            component="p"
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            - Montage och installation av specificerade produkter, inklusive
            solcellspaneler, växelriktare, samt erforderligt installations- och
            montagematerial. Montage och installation förutsätter att
            fastighetens tak och elcentral är i normalt utförande.
          </Typography>
          <br />
        </ListItem>
        <ListItem data-list-text="-">
          <Typography
            variant="p"
            component="p"
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            - Uppkoppling av växelriktare mot internet för övervakning av
            elproduktion (förutsätter stark wifi-mottagning vid växelriktare).
          </Typography>
          <br />
        </ListItem>
        <ListItem data-list-text="-">
          <Typography
            variant="p"
            component="p"
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            - Observera att det åligger beställaren/kunden själv att efterse
            eventuella hinder för installering av solpanel. Exempelvis bygglov
            eller övriga bestämmelser såsom K-märkning, regler i
            bostadsrättsförening etc.
          </Typography>
          <br />
        </ListItem>
        <ListItem data-list-text="-">
          <Typography
            variant="p"
            component="p"
            style={{
              paddingLeft: "41pt",
              textIndent: "2pt",
              textAlign: "left",
            }}
          >
            - Som en extra service ser vi även över det befintliga taket, före
            installation. Behövs det förstärkas, lämnar vi en utförlig rapport
            om var det behövs förstärkas, om det ska ske i ett senare skede. Vi
            kan även ge ett prisförslag på åtgärd om vi ska åtgärda det i
            samband med installationen av solpanel.
          </Typography>
          <br />
        </ListItem>
        <ListItem data-list-text="-">
          <Typography
            variant="p"
            component="p"
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            - Du som kund står för utförandet av eventuella grävarbeten samt
            tillkommande försäkringsåtagande.
          </Typography>
        </ListItem>
      </ul>
      <br />
      <Typography
        variant="h4"
        component="h4"
        style={{
          paddingLeft: "41pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        Leverans
      </Typography>
      <Typography
        variant="p"
        component="p"
        style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}
      >
        Normalt ca 4-8 veckor efter att bygglov (om erforderligt) och föranmälan
        till nätägaren godkänts. Efter godkännande planeras installationsdatum
        tillsammans med beställaren. Observera att det inte går att installera
        under vissa väderförhållanden vilket kan påverka leveransdatum.
      </Typography>
      <br />
      <Typography
        variant="h4"
        component="h4"
        style={{
          paddingLeft: "41pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        Garantier
      </Typography>
      <Typography
        variant="p"
        component="p"
        style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}
      >
        Samtliga produkt- och effektgarantier utfärdas av tillverkare.
        Eventuella garantiärenden hanteras av Tolo Solar AB. Garanti på
        installationsarbetet är 10 år.
      </Typography>
      <br />
      <Typography
        variant="h4"
        component="h4"
        style={{
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "left",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        Drifttest
      </Typography>
      <Typography
        variant="p"
        component="p"
        style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}
      >
        Med "drifttest" avses att anläggningen har testats i drift och att
        färdiganmälan har skickats in till nätägaren för byte av elmätaren.
        Eventuell handläggningstid utav nätägaren för att komma ut och byta
        elmätaren kan Tolo Solar AB inte påverka och påverkar inte de angivna
        betalningsvillkoren.
      </Typography>
      <br />

      <Typography
        variant="h4"
        component="h4"
        style={{
          paddingTop: "3pt",
          paddingLeft: "41pt",
          textIndent: "0pt",
          lineHeight: "13pt",
          textAlign: "left",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        Dokumentation
      </Typography>
      <Typography
        variant="p"
        component="p"
        style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}
      >
        På begäran skickas elektronisk dokumentation och produktblad avseende
        specificerade produkter, inklusive solcellspaneler, växelriktare,
        optimerare, montagesystem, elmätare, laddbox, etc. På begäran kan F-
        skattebevis och försäkringsbevis uppvisas.
      </Typography>
      <br />
    </>
  );
}

export default TermsOrderDetails;
