import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

import CustomButton from "../../../utils/custom.button";
import CustomLink from "../../../utils/custom.link";
import CallButton from "../call.button.component";
import DropDownMenu from "./drop.down.menu";

import "./../header.style.scss";
import "./menu.style.scss";
import HeaderMenu from "./menu.component";

function DesktopHeader(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const lastScrollTop = useRef(0);

  const headerClassName = scrollPosition > 180 ? " fixed-header " : "";
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset;
      setScrollPosition(currentScrollTop);
      setIsScrolledUp(currentScrollTop < lastScrollTop.current);
      lastScrollTop.current = currentScrollTop;
    };
    const handleTouchMove = (event) => {
      const currentScrollTop = event.touches[0].pageY;
      setScrollPosition(currentScrollTop);
      setIsScrolledUp(currentScrollTop < lastScrollTop.current);
      lastScrollTop.current = currentScrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleTouchMove);
    };

    // indicatorLocation
  }, []);

  return (
    <div
      className={
        props.isMobile
          ? "header fixed-header"
          : `header ${headerClassName} ${
              isScrolledUp ? "scroll-up-header" : ""
            }`
      }
    >
      <Grid
        className="desktopHeader"
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <div className="menuBar">
          <div
            className="leftBar"
            style={{ display: "flex", justifyContent: "bottom" }}
          >
            <button
              style={{
                // backgroundColor: "#39C2FB",
                marginRight: "14px",
                // height: isLarge ? (isMobile ? "30px" : "40px") : "46px",
                // width: isLarge ? (isMobile ? "30px" : "41px") : "47px",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={props.toggleMobileMenu}
              title="Toggle"
              className="toggleButton"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              {/* <MenuIcon
                  style={{
                    color: "#FFFFFF",
                    fontSize: isLarge ? (isMobile ? "28px" : "31px") : "31px",

                    padding: "0px",
                    margin: "0px",
                  }}
                /> */}
            </button>
            <CustomLink id="header_logo_button" to="/">
              <img
                style={{
                  height: props.isLarge
                    ? props.isMobile
                      ? "36px"
                      : "40px"
                    : "46px",
                }}
                title="image"
                alt=""
                src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/logo/logo.svg"
              />
            </CustomLink>
          </div>
          <ul className="rightBar">
            <HeaderMenu headerData={props.headerData} />
            {/* <li className="searchRight">
                <SearchInput
                  border_radius="50%"
                  id="header_call_phone"
                  padding="0px"
                  onClick={routeToPhone}
                  icon={<SearchOutlinedIcon style={{ fontSize: "25px" }} />}
                  width="46px"
                  height="46px"
                  color="#5D5A88"
                  background="#D9D9D9"
                />
              </li> */}
            <li className="phoneRight">
              <CallButton
                border_radius="50%"
                id="header_call_phone"
                padding="0px"
                onClick={props.routeToPhone}
                icon={<LocalPhoneOutlinedIcon style={{ fontSize: "25px" }} />}
                width="46px"
                height="46px"
                color="#F5F2EB"
                background="#1C96DF"
              />
            </li>
            <li className="quoteRight">
              <CustomButton
                id="header_get_a_quote"
                onClick={props.projectAdd}
                label="FÅ EN OFFERT"
                padding={
                  props.isLarge
                    ? props.isMobile
                      ? "3px 9px"
                      : "12px 24px"
                    : "12px 24px"
                }
                size={
                  props.isLarge ? (props.isMobile ? "11px" : "13px") : "19px"
                }
                width={
                  props.isLarge ? (props.isMobile ? "120px" : "180px") : "249px"
                }
                height={
                  props.isLarge ? (props.isMobile ? "36px" : "40px") : "46px"
                }
                transform={"disable"}
                color="#FFFFFF"
                background="#FF8718"
              />
            </li>
          </ul>
        </div>
      </Grid>
    </div>
  );
}

export default DesktopHeader;
