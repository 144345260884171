import { Chip, Grid, Paper, Typography } from "@mui/material";


function LastOrderDetails(props) {
    return (
        <Grid>
            <Typography variant="h4" component="h4"
                style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "13pt",
                    textAlign: "left",
                    textDecoration: "underline",
                    fontWeight: 'bold'
                }}
            >
                Ångerrätt
            </Typography>
            <Typography variant="p" component="p" style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>
                *Vi förbehåller oss rätten att fakturera 30 procent av totaltpriset vid
                ånger 7 dagar efter signerat avtal.
            </Typography>
            <br />
            <Typography variant="h4" component="h4" style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left",textDecoration: "underline",
                    fontWeight: 'bold' }}>
                Betalningsvillkor:
            </Typography>
            <Typography variant="p" component="p" style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>
                30 procent vid beställning, 40 procent efter leverans av material och 30
                procents betalning efter färdigställd installation, drifttest och
                färdiganmälan till nätägaren. 10 dagars betalningsperiod från
                fakturadatum. PDF skickas till beställarens e-postadress.
            </Typography>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="left"
                style={{ paddingTop: "250pt", paddingBottom: "350pt" }}
            >    <Grid item xs={6}>
                    <Typography variant="p" component="p"
                        className="s1"
                        style={{
                            paddingTop: "5pt",
                            paddingLeft: "73pt",
                            textIndent: "0pt",
                            textAlign: "left",
                        }}
                    >
                        Kundens signatur:
                    </Typography>

                </Grid>
                <Grid item xs={6}>
                    <Typography variant="p" component="p"
                        className="s1"
                        style={{
                            paddingTop: "5pt",
                            paddingLeft: "73pt",
                            textIndent: "0pt",
                            textAlign: "left",
                        }}
                    >
                        Datum:
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LastOrderDetails;