import axios from "axios";
import eventBus from "../common/EventBus";
import { environment } from "../environments/environment";
import authHeader from "./auth-header";

axios.defaults.baseURL = process.env.REACT_APP_Server_URL;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      eventBus.dispatch("logout");

      // Log out the user here
      localStorage.removeItem('token');
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);
export const listProducts = (title,
  category,
  Make,
  price,
  height,
  width) => {
  return api.get(process.env.REACT_APP_Server_URL + "products?"+
  "title="+title+
  "&category="+  category+
  "&Make="+  Make+
  "&price="+  price+
  "&height="+  height+
  "&width=" + width, { headers: authHeader() });
};

export const postProduct = (
  title,
  description,
  Make,
  category,
  images,
  price,
  quantity_stock,
  discount_percent,
  discount_datetime_start,
  discount_datetime_end,
  height,
  width,
  html_body) => {
  return api.post(process.env.REACT_APP_Server_URL + "products", {
    title,
    description,
    Make,
    category,
    images,
    price,
    quantity_stock,
    discount_percent,
    discount_datetime_start,
    discount_datetime_end,
    height,
    width,
    html_body
  }, { headers: authHeader() })
  .then(response => {
    if (response.data.accessToken) {
      //console.log(JSON.stringify(response.data));
    }

    return response.data;
  });
};

export const getProduct = (productId) => {
  return api.get(process.env.REACT_APP_Server_URL + "product/"+ productId, { headers: authHeader() });
};

export const deleteProduct = (productId) => {
  return api.delete(process.env.REACT_APP_Server_URL + "products/"+ productId, { headers: authHeader() });
};