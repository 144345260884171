import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import MobileMenu from "./mobile/mobile.menu.component";
import jsonHeaderData from "../../json/header.component.json";

import "./header.style.scss";
import DesktopHeader from "./desktop/desktop.header.component";

function Header(props) {
  const [headerData, setHeaderData] = useState(jsonHeaderData);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  let navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const projectAdd = () => {
    navigate("/project/add");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const routeToPhone = () => {
    window.location.href = "tel:020899488";
  };
  const toggleMobileMenu = () => {
    if (showMobileMenu === false) {
      setShowMobileMenu(!showMobileMenu);
    }
    if (showMobileMenu === true) {
      setShowMobileMenu(!showMobileMenu);
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (showMobileMenu) {
        e.preventDefault();
      }
    };
    window.addEventListener("wheel", handleScroll, { passive: false });
    window.addEventListener("touchmove", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, [showMobileMenu]);

  return (
    <>
      <DesktopHeader
        routeToPhone={routeToPhone}
        headerData={headerData}
        projectAdd={projectAdd}
        isMobile={isMobile}
        isLarge={isLarge}
        toggleMobileMenu={toggleMobileMenu}
      />
      {showMobileMenu && (
        <MobileMenu
          toggleB={toggleMobileMenu}
          projectAdd={projectAdd}
          routeToPhone={routeToPhone}
          isMobile={isMobile}
          headerData={headerData}
        />
      )}
    </>
  );
}

export default Header;
