
import React from 'react';
import './style.css'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const ProjectRequited = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
    AOS.refresh();
  }, []);
  React.useLayoutEffect(() => {
    const lastSection = document.querySelector('.lastSection');
    lastSection.style.display = 'none';
    return () => {
      lastSection.style.display = ''; // Reset the display property
    };
  }, []);
  return (
    <div data-aos="fade-up" className='quoteRequestedPage'>
      <div className='quoteRequestedPageDetails'>
        <h2>
        Tack för din förfrågan!
        </h2>
        <p>
        Vi har mottagit din förfrågan och återkommer till dig inom kort. 
        </p>
        <p>
        Ha en strålande dag!
          </p>
          <p>
        Team Tolo Solar          </p>
        <div>
        <img title="image" alt="" src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/logo/logo.svg" style={{height: '39px'}}/>
      

        </div>
        <div>
      </div>

      </div>
      <div className='quoteRequestedPageImage'>
        <img src='/img/requited/arrow.svg' alt='' /> 
      </div>
    </div>
  );
}
export default ProjectRequited;
