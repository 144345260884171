import { Grid } from '@mui/material';
import CustomChip from './custom.chip.component';
function UserPersonalDetails(props) {
  return (
      <Grid container direction="row"
        justifyContent="flex-start"
        alignItems="center">
        <Grid item lg={6} xs={12} sx={{  paddingX: { sm: '1rem' } }}>
          <CustomChip setValue={props.setUser} name="username" label={"Username: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="companyOrgNumber" label={"Org Number: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="contactPersonId" label={"Personnummer: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="companyName" label={"Company Name: "} value={ props.user} />
        </Grid>
        <Grid item lg={6} xs={12} sx={{  paddingX: { sm: '1rem' } }}>
          <CustomChip setValue={props.setUser} name="telephone" label={"Telephone: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="email" label={"E-post: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="moms_VAT_nr" label={"Moms Registrerings Nummer: "} value={ props.user} />
          <CustomChip setValue={props.setUser} name="job_title" label={"Job Title: "} value={ props.user} />
          <CustomChip name="createdAt" label={"Registering Date: "} value={ props.user} />
        </Grid>
      </Grid>
  );
}

export default UserPersonalDetails;