import * as React from "react";
import { useState } from "react";
import { Container, Grid } from "@mui/material";
import { postProject } from "../project.service";
import ProjectAddForm from "./project.add.form.component";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import MainQuote from "./sections/main.quote";
import jsonQuoteData from "../../../json/quote.page.json";
import "./style.scss";
import { useLocation } from "react-router-dom";

const AddProject = () => {
  const [quoteData, setQuoteData] = useState(jsonQuoteData);

  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    }
  }, [location]);
  const handleSubmit = (values) => {
    setLoading(true);
    setMessage("");
    const {
      name,
      email,
      phone,
      address,
      description,
      roofMaterial,
      serviceType,
      annualConsumption,
      takShape,
      advice,
      availabilityTime,
    } = values;
    postProject(
      name,
      email,
      phone,
      address,
      description,
      roofMaterial,
      serviceType,
      annualConsumption,
      takShape,
      advice,
      availabilityTime
    ).then(
      (response) => {
        setMessage(response.data);
        navigate("/project/requited");
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
      }
    );
  };
  React.useLayoutEffect(() => {
    const lastSection = document.querySelector(".lastSection");
    lastSection.style.display = "none";
    return () => {
      lastSection.style.display = ""; // Reset the display property
    };
  }, []);
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo({ top: 0, behavior: "smooth" });

    // const image = document.querySelector('.fixedImageee');
    // const imageOffsetTop = image.offsetTop + 1000;

    // function handleScroll() {
    //   if (window.scrollY >= imageOffsetTop) {
    //     image.style.position = 'static';
    //   } else {
    //     image.style.position = 'fixed';
    //   }
    // }

    // window.addEventListener('scroll', handleScroll);

    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);
  return (
    <>
      <Grid data-aos="fade-down">
        <MainQuote quoteData={quoteData} />
      </Grid>
      <Container
        fixed
        container
        alignItems="flex-start"
        className="projectAddContainer"
      >
        <Grid
          data-aos="fade-right"
          item
          sm={12}
          md={12}
          className="projectAddLeftRow"
        >
          <h1>FÅ EN OFFERT</h1>
          <p>Fyll i formuläret nedan så återkommer vi till dig inom kort.</p>
          <ProjectAddForm quoteData={quoteData} handleSubmit={handleSubmit} />
        </Grid>
        {/* <Grid item sm={12} md={6} className="projectAddRightRow">
        <div className="fixedImageee">
          <Player
            src='/img/solar-house.json'
            className="player"
            loop
            autoplay
          />
        </div>
      </Grid> */}
      </Container>
    </>
  );
};

export default AddProject;
