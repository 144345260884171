import React from "react";
// import React, { useState, useEffect } from "react";

import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import MainComponent from "./main.component";
import "./index.css";
import TagManager from "react-gtm-module";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

const hotjarScript = `
(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:3663813,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;

const tagManagerArgs = {
  gtmId: 'GTM-WC8LTB3J'
}

TagManager.initialize(tagManagerArgs)

root.render(
  <React.StrictMode>
    <Helmet>
      <title>ToloSolar</title>
      <meta
        name="keywords"
        content="Solpanelsinstallation, Solcellsanläggning, byta tack, tolosolar, tolo solar, Solpaneler, solcell möntor, solcell installation,växelrikatre, växel riktare, inverter, battery, batteri, ladbox, billadbox, ev laddare, Förnybar energi, Solenergi, Fotovoltaiska system, Solkraft, Solinstallation, Solprodukter, Solteknik, Ren energi, Hållbar energi, Solström, Solmoduler, Solceller, Solomvandlare, Nätövervakning, Solfinansiering, Solrabatter, Frånkopplade system, Nätanslutna system, Solbatterilagring, Solar panels, Renewable energy, Solar energy, Photovoltaic systems, Solar power, Solar installation, Solar solutions, Solar technology, Clean energy, Sustainable energy, Solar electricity, Solar modules, Solar cells, Solar inverters, Net metering, Solar financing, Solar rebates, Off-grid systems, Grid-tied systems, Solar battery storage."
      />

      <meta
        name="description"
        content="Fyll ditt hem med solsken. Snabb leverans, god kvalitet och bra service."
      />
    </Helmet>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MainComponent />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
const scriptElement = document.createElement("script");
scriptElement.innerHTML = hotjarScript;
document.head.appendChild(scriptElement);

reportWebVitals();
