import React, { useEffect, useRef, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import CustomButton from "../../../../utils/custom.button";
import "./rating.owl.section.home.scss";
import CardRatingSectionHome from "./card.rating.section.home";
import { isSafari } from "react-device-detect";

const RatingOwlSectionHome = (props) => {
  const textLimit = 100; // Character limit for initial display
  const ratingList = props.homeData.ratings_section[0].ratings_list;
  const getDescriptionText = (description, index) => {
    return description.length > textLimit
      ? description.slice(0, textLimit) + "..."
      : description;
  };

  const [prevActive, setPrevActive] = useState(2);
  const [autoSlide, setAutoSlide] = useState(true);

  const touchStartX = useRef(null);
  const autoSlideInterval = useRef(null);
  const [active, setActive] = useState(1);

  // Function to start the auto-slide
  const startAutoSlide = () => {
    clearInterval(autoSlideInterval.current);
    if (autoSlide) {
      autoSlideInterval.current = setInterval(() => {
        moveToLeft(); // Slide to the right (previous item)
      }, 5000); // Change the slide interval as needed (e.g., 5000ms = 5 seconds)
    }
  };

  // Function to stop the auto-slide
  const stopAutoSlide = () => {
    clearInterval(autoSlideInterval.current);
  };

  // Add useEffect to start and stop auto-slide based on active state
  useEffect(() => {
    startAutoSlide();
    return () => {
      stopAutoSlide();
    };
  }, [active]);
  const startTouchTracking = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  // Initialize flags using useRef
  const hasSetActiveBeenCalled = useRef(false);
  const hasMovedLeft = useRef(false);

  const sensitivityThreshold = 50;

  const trackTouchMove = (event) => {
    if (!touchStartX.current) {
      return;
    }

    const touchCurrentX = event.touches[0].clientX;
    const deltaX = touchStartX.current - touchCurrentX;

    if (deltaX > sensitivityThreshold && !hasSetActiveBeenCalled.current) {
      moveToLeft();
      setAutoSlide(false);
      hasSetActiveBeenCalled.current = true;
    } else if (deltaX < -sensitivityThreshold && !hasMovedLeft.current) {
      moveToRight();
      setAutoSlide(false);

      hasMovedLeft.current = true;
    } else {
    }
  };

  const endTouchTracking = () => {
    touchStartX.current = null;
    hasSetActiveBeenCalled.current = false;
    hasMovedLeft.current = false; // Reset the flags when touch ends
  };
  const positionItem = (item, location) => {
    // if (isSafari) {
    // Apply Safari-specific styles or fallback styles
    // Apply common styles for non-Safari browsers
    if (location === "center") {
      item.style.transform = "translate(-50%, 0%)";
      item.style.zIndex = 1;
      item.style.filter = "none";
      item.style.opacity = 1;
      item.style.scale = 1;
    } else if (location === "right") {
      item.style.transform = `translate(25%, 0%) scale(0.9) perspective(16px) rotateY(-1deg)`;
      item.style.zIndex = -3;
      item.style.filter = "blur(5px)";
      item.style.opacity = 0.6;
    } else if (location === "left") {
      item.style.transform = `translate(-125%, 0%) scale(0.9) perspective(16px) rotateY(1deg)`;
      item.style.zIndex = -3;
      item.style.filter = "blur(5px)";
      item.style.opacity = 0.6;
    }
    // } else {
    //   // Apply common styles for non-Safari browsers
    //   if (location === "center") {
    //     item.style.transform = "translate(-50%, 0%)";
    //     item.style.zIndex = 1;
    //     item.style.filter = "none";
    //     item.style.opacity = 1;
    //     item.style.scale = 1;
    //   } else if (location === "right") {
    //     item.style.transform = `translate(0%, 0%) scale(0.9) perspective(16px) rotateY(-1deg)`;
    //     item.style.zIndex = -3;
    //     item.style.filter = "blur(5px)";
    //     item.style.opacity = 0.6;
    //   } else if (location === "left") {
    //     item.style.transform = `translate(-100%, 0%) scale(0.9) perspective(16px) rotateY(1deg)`;
    //     item.style.zIndex = -3;
    //     item.style.filter = "blur(5px)";
    //     item.style.opacity = 0.6;
    //   }
    // }
  };

  const loadShow = () => {
    const items = document.querySelectorAll(".owlCardRatingSection");
    if (items.length > 0) {
      items.forEach((item, index) => {
        //console.log(index, items.length - 1, active, prevActive);
        if (index === active && active !== items.length - 1 && active !== 0) {
          positionItem(item, "center");
        } else if (active === 0 && index == items.length - 1) {
          positionItem(items[0], "center");
          //positionItem(items[prevActive], "right");
          positionItem(items[items.length - 1], "left");
        } else if (active !== items.length && index !== -1 && index > active) {
          positionItem(item, "right");
        } else if (active !== items.length && active !== -1 && index < active) {
          positionItem(item, "left");
        } else if (active === items.length - 1 && active !== 0) {
          positionItem(item, "center");
          //positionItem(items[prevActive], "left");
          positionItem(items[0], "right");
        }
      });
    }
  };

  const moveToLeft = () => {
    setPrevActive(prevActive);

    setActive((prevActive) => (prevActive + 1) % ratingList.length);
  };

  const moveToRight = () => {
    setPrevActive(prevActive);

    setActive(
      (prevActive) => (prevActive - 1 + ratingList.length) % ratingList.length
    );
  };

  useEffect(() => {
    loadShow();
  }, [active]);
  const numCards = props.homeData.ratings_section[0].ratings_list.length;

  return (
    <div className="owlRatingSection">
      <div className="cards">
        {ratingList.map((rate, index) => (
          <div
            onTouchStart={startTouchTracking}
            onTouchMove={trackTouchMove}
            onTouchEnd={endTouchTracking}
            key={index}
            className="owlCardRatingSection"
          >
            <CardRatingSectionHome homeData={props.homeData} rate={rate} />
          </div>
        ))}
      </div>
      <div className="rating-slideshow-dots">
        {numCards <= 5 &&
          Array.from({ length: numCards }).map((_, index) => (
            <span
              key={index}
              className={`ratingDot ${active === index ? "ratingActive" : ""}`}
              onClick={() => setActive(index)}
            ></span>
          ))}
      </div>
      {/* <button onClick={moveToRight}>Previous</button>
      <button onClick={moveToLeft}>Next</button> */}
    </div>
  );
};

export default RatingOwlSectionHome;
