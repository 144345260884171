import { Player } from '@lottiefiles/react-lottie-player';


import React from "react";

const AnimatedSection = () => {
    return (
      <section className="AnimatedSection">
        <Player
        src='/img/animated.svg.json'
        className="player"
        loop
        autoplay
      />
      </section>
    );
}

export default AnimatedSection;
