import React from "react";
import { Container, Grid, Box, Typography, Stack } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import FormikResetPasswordRequest from "./formik.reset.password.request.component";
import CustomLink from "../../utils/custom.link";

const theme = createTheme();

//type Props = RouteComponentProps<RouterProps>;

const ResetPasswordRequest = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          backgroundColor: { xs: "#fff", md: "#f4f4f4" },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid
            item
            sx={{ maxWidth: "70rem", width: "100%", backgroundColor: "#fff" }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                py: "6rem",
                px: "1rem",
              }}
            >
              <Grid
                item
                container
                justifyContent="space-between"
                rowSpacing={5}
                sx={{
                  maxWidth: { sm: "45rem" },
                  marginInline: "auto",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    borderRight: { sm: "1px solid #ddd" },
                    borderLeft: { sm: "1px solid #ddd" },
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    noValidate
                    autoComplete="off"
                    sx={{
                      paddingRight: { sm: "3rem" },
                      paddingLeft: { sm: "3rem" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      sx={{
                        paddingLeft: { sm: "3rem" },
                        mb: "1.5rem",
                      }}
                    >
                      Glömt lösenord
                    </Typography>
                    <p>
                      Vänligen ange e-postadressen för ditt konto. En länk för
                      att ändra ditt lösenord kommer att skickas till dig via
                      e-post
                    </p>
                    <FormikResetPasswordRequest />
                  </Box>
                  <Stack sx={{ mt: "1rem", textAlign: "center" }}>
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      Glömde din{" "}
                      <CustomLink id="" to="/username/request">
                        Användarnamn?
                      </CustomLink>
                    </Typography>
                    <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
                      Behöver ett konto?{" "}
                      <CustomLink id="" to="/register">
                        Anmäl dig här
                      </CustomLink>
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordRequest;
