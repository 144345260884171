import { useState } from "react";
import * as React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CustomField from "../../../utils/custom.field";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Field, ErrorMessage } from "formik";

const UserAddForm = (props) => {
  return (
    <>
      <Field
        name="firstName"
        type="text"
        label="Förnamn"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="firstName"
      />
      <Field
        name="lastName"
        type="text"
        label="Efternamn"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="lastName"
      />
      <Field
        name="telephone"
        type="text"
        label="Telefon"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="telephone"
      />
      <Field
        name="email"
        type="email"
        label="E-postadress"
        required={true}
        component={CustomField}
      />
      <ErrorMessage component="span" className="error-message" name="email" />
      <Field
        name="username"
        type="text"
        label="Användarnamn"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="username"
      />
      <Field
        name="contactPersonId"
        type="text"
        label="Personnummer till företagets"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="contactPersonId"
      />
      <Field
        name="companyOrgNumber"
        type="text"
        label="Organisationsnummer (10 siffror)"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="companyOrgNumber"
      />
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        type="submit"
        disabled={props.loading}
      >
        Submit
      </Button>
    </>
  );
};
export default UserAddForm;
