import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const CustomAutoField = ({ field, form,set_value, ...props }) => {


    // additional state or logic can go here
    const [value, setValue] = React.useState(null);
    //console.log(form.values[field.name])
    return (
        <>
            <Autocomplete
                isOptionEqualToValue={(option, value) => option.value === value.value}
                freeSolo={props.freeSolo}
                {...field}
                {...props}
                disableClearable
                value={value || null}
                onChange={(event, value) => {
                    form.setFieldValue(field.name, value);
                    if (form.onChange) {
                        form.onChange(event, value);

                    }
                    if (set_value) {
                        set_value(value);
                    }
                }}
                options={props.options}

                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus={props.freeSolo}
                        label={props.label}
                        id={props.name}
                        name={props.name}
                        type="text"
                        fullWidth
                        // error={touched[props.name] && Boolean(errors[props.name])}
                        margin="normal" //dense normal
                        variant="outlined" // standard filled outlined
                        value={value || null}
                        onChange={(event, newValue) => {
                            //console.log(event.target.value)
                            setValue(event.target.value);
                            form.setFieldValue(field.name, event.target.value);
                            if (set_value) {
                                set_value(event.target.value);
                            }
                            
                        }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}

                    />
                )}

            />
        </>
    );
};

export default CustomAutoField;