import styled from "@emotion/styled";
import { ButtonBase, Grid, Paper, Typography } from "@mui/material";
import CustomLink from "../../../utils/custom.link";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
function ProductsOrderDetails(props) {
  return (
    <Grid
      style={{
        marginTop: "11pt",
      }}
    >
      <Typography
        variant="h1"
        component="h1"
        style={{
          paddingTop: "4pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Products
      </Typography>

      {props.products.map((product, index) => (
        <Grid
          sx={{
            p: 3,
            margin: "auto",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
          key={index}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 128, height: 128 }}>
                <Img alt="complex" src="/logo.png" />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {product.products[0].title}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Full resolution 1920x1080 • JPEG
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Make of: {product.products[0].Make}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ID: {product.products[0].id}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.products[0].description}
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomLink id="" to={"/product/" + product.id}>
                    mer detaljer
                  </CustomLink>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="div">
                  {product.products[0].price}SEK
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default ProductsOrderDetails;
