import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Theme from "../../../utils/theme";
import { IconButton, alpha, Menu, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Delete from "@mui/icons-material/Delete";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CustomDialogItem from "../../../utils/custom.dialog.Item";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { useNavigate } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Shop2Icon from "@mui/icons-material/Shop2";
import { deleteOrder, patchOrder } from "../order.service";

const StyledMenu = styled((prop) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...prop}
  />
))(({}) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: Theme.spacing(1),
    minWidth: 180,
    // color:  Theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : Theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: Theme.palette.text.secondary,
        marginRight: Theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          Theme.palette.primary.main
          // Theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function OrderStatus(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStatus = (name, value) => {
    //props.value[props.name] = editedValue;
    const editedOrder = props.order;
    editedOrder["status"] = name;
    props.setOrder(editedOrder);
    patchOrder(props.order.id, { status: name });
    window.location.reload();
    //props.setValue(props.value)
    // Submit the form and update the value in the parent component's state
    handleClose();
  };
  const handleDelete = (name, value) => {
    deleteOrder(props.order.id);
    navigate("/orders");
  };

  return (
    <>
      <IconButton
        id="customized-button"
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        //disableElevation
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <CustomDialogItem label="Aktivera Order" value={7} name="Activated" handleSubmit={handleStatus} handleClose={handleClose} open={open} icon={<DoneOutlineIcon />} /> */}
        <CustomDialogItem
          label="byta status till faktura"
          value={7}
          name="Faktura"
          handleSubmit={handleStatus}
          handleClose={handleClose}
          open={open}
          icon={<ReceiptIcon />}
        />
        <CustomDialogItem
          label="byta status till beställa"
          value={8}
          name="Beställa"
          handleSubmit={handleStatus}
          handleClose={handleClose}
          open={open}
          icon={<Shop2Icon />}
        />
        <Divider />
        <CustomDialogItem
          label="Inaktivera Order"
          value={8}
          name="Deactivated"
          handleSubmit={handleStatus}
          handleClose={handleClose}
          open={open}
          icon={<RemoveShoppingCartIcon />}
        />
        <CustomDialogItem
          label="Ta bort Order"
          value={6}
          name="Deleted"
          handleSubmit={handleDelete}
          handleClose={handleClose}
          open={open}
          icon={<Delete />}
        />
      </StyledMenu>
    </>
  );
}

export default OrderStatus;
