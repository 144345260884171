import { Grid } from "@mui/material";
import "./project.section.home.scss";
import React from "react";
import CustomButton from "../../../../utils/custom.button";
import CustomLink from "../../../../utils/custom.link";
import { useNavigate } from "react-router-dom";

const SmallCardProjectSectionHome = (props) => {
  const navigate = useNavigate();
  const moveToProject = (project) => {
    navigate("/projects/" + project.id, { state: project });
    // console.log(project);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        className="smallCardProjectSectionHome"
        onClick={() => moveToProject(props.project)}
      >
        <Grid item sx={{ flex: 4 }}>
          <img
            alt=""
            className="mainImageProjectSectionHome"
            title="image"
            src={props.project.img[0]}
          />
        </Grid>
        <Grid item sx={{ flex: 8 }}>
          <Grid
            container
            direction="column"
            justifyContent="space-center"
            alignItems="center"
            className="descriptionProjectSectionHome"
          >
            <h2>{props.project.address}</h2>
            {/* <h3>{props.project.address}</h3> */}

            {/* <h4>TJÄNSTER:</h4> */}

            <Grid>
              <CustomButton
                id="home_project_section"
                //id="header_main_section_get_a_quote"
                //onClick={projectAdd}
                onClick={() => moveToProject(props.project)}
                label="Visa projekt"
                size="8px"
                width="133px"
                height="18px"
                color="#ffffff"
                background="#FF8718"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SmallCardProjectSectionHome;
