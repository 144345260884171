import React, { useState } from "react";
import CustomButton from "../../../utils/custom.button";
import CustomLink from "../../../utils/custom.link";
import { Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import MenuMobileLink from "./menu.mobile.link.component";
import AddIcon from "@mui/icons-material/Add";
import "./mobile.menu.component.scss";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import NoStyleLink from "../../../utils/custom.link";
import AnimatedSection from "../../home/sections/animated.section.home";

function MobileMenu(props) {
  const [selectedModal, setSelectedModal] = useState(null);
  React.useEffect(() => {
    AOS.init({ duration: 500 });
    // AOS.refresh();
  }, []);

  return (
    <Grid container className="menuMobileHeader" justify="flex-start">
      <Grid item className="menuMobileLinks" data-aos="fade-right">
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item style={{ margin: "15px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
              className="menuMobileLinksFirstSection"
            >
              <Grid style={{}} xs={6} item>
                <CustomLink id="header_logo_button_mobile" to="/">
                  <img
                    style={{
                      height: "43px",
                    }}
                    title="image"
                    alt=""
                    src="https://tolosolar-buket.s3.eu-north-1.amazonaws.com/public/img/logo/logo.svg"
                  />
                </CustomLink>
                {/* <ButtonMobileLink
                    id="header_get_a_quote_mobile"
                    label="020 899 488"
                    to={"tel:020899488"}
                    closeMenu={props.toggleB}
                    font_color={"white"}
                    icon={<CallIcon />}
                  ></ButtonMobileLink> */}
              </Grid>
              <Grid xs={6} item style={{}}>
                <div className="footerIcons">
                  <NoStyleLink id="footer_facebook" to={""}>
                    <a
                      href="https://www.facebook.com/profile.php?id=100087619932415"
                      title=""
                    >
                      <FacebookIcon
                        style={{ color: "#392d53", fontSize: "20px" }}
                      />
                    </a>
                  </NoStyleLink>
                  {/* <NoStyleLink id="footer_twitter" to={""}>
                      <a href="https://twitter.com" title="">
                        <TwitterIcon style={{ color: "#392d53", fontSize: "20px" }} />
                      </a>
                    </NoStyleLink> 
                  */}
                  <NoStyleLink id="footer_linkedin" to={""}>
                    <a
                      href="https://www.linkedin.com/company/tolosolar/"
                      title=""
                    >
                      <LinkedInIcon
                        style={{ color: "#392d53", fontSize: "20px" }}
                      />
                    </a>
                  </NoStyleLink>
                  <NoStyleLink id="footer_instagram" to={""}>
                    <a href="https://www.instagram.com/tolosolar" title="">
                      <InstagramIcon
                        style={{ color: "#392d53", fontSize: "20px" }}
                      />
                    </a>
                  </NoStyleLink>
                </div>
              </Grid>
            </Grid>
            <hr
              style={{
                margin: "0px",
                backgroundImage:
                  "linear-gradient(340deg, #fbb034 8%, #ffdd00 100%)",
              }}
            />

            {/* 
              <ButtonMobileLink
                id="header_get_a_quote_mobile"
                to={"/project/add"}
                label="FÅ EN OFFERT"
                closeMenu={props.toggleB}
                icon={<AddIcon />}
                font_color={"white"}
              ></ButtonMobileLink> 
              */}
            <CustomButton
              id="header_get_a_quote_mobile"
              to={"/project/add"}
              label="FÅ EN OFFERT"
              icon={<AddIcon />}
              onClick={function () {
                props.toggleB();
                props.projectAdd();
              }}
              color={"#fff"}
              background={"#ff8717"}
              border_radius={"25px 25px 25px 25px"}
              margin={"10px 10px 10px 10px"}
            ></CustomButton>
            <h2>MENU</h2>
            {props.headerData.menu.map((menu, index) => {
              if (index === 0) {
                // This is the first item (MENU), you can choose to render it differently or ignore it
                return (
                  <div key={index}>
                    {/* Render the MENU heading differently or leave it empty */}
                    {/* Example: */}
                    {/* <h2 style={{ color: 'red' }}>MENU</h2> */}
                  </div>
                );
              }

              return menu.subMenu ? (
                <MenuMobileLink
                  key={index}
                  keyMenuItem={index}
                  to={menu.to}
                  label={menu.label}
                  subMenu={menu.subMenu}
                  icon={menu.icon}
                  closeMenu={props.toggleB}
                  selectedModal={selectedModal}
                  setSelectedModal={setSelectedModal}
                />
              ) : (
                <MenuMobileLink
                  key={index}
                  keyMenuItem={index}
                  to={menu.to}
                  label={menu.label}
                  icon={menu.icon}
                  closeMenu={props.toggleB}
                />
              );
            })}

            <h2>ANNAT</h2>
            <MenuMobileLink
              key={10}
              icon={"QuizIcon"}
              closeMenu={props.toggleB}
              id="fgd"
              to={"/FAQs"}
              label="FAQ"
            />
            <MenuMobileLink
              key={11}
              icon={"HelpIcon"}
              closeMenu={props.toggleB}
              id="gfg"
              to={"/contact"}
              label="Support"
            />
            {/* <Grid container item className="menuMobileLinksFooter">
          <Grid xs={6} item style={{ borderRight: "1px solid black" }}>
            Till Privat
          </Grid>
          <Grid xs={6} item>
            Till Företag
          </Grid>
        </Grid> */}
          </Grid>
          <Grid item>
            <AnimatedSection />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className="menuClose"
        item
        onClick={props.toggleB}
        data-aos="fade-right"
      >
        <CustomButton
          className="menuCloseButton"
          id="header_get_a_quote_mobile"
          onClick={props.toggleB}
          icon={<MenuIcon />}
          size="35px"
          width="55px"
          height="55px"
          color="#FFFFFF"
          border="0px"
          margin="0px"
          border_radius="0px 0px 10px 0px"
          background="rgb(57, 194, 251)"
        />
      </Grid>
    </Grid>
  );
}

export default MobileMenu;
