import { Grid } from "@mui/material";
import "./project.section.home.scss";
import React from "react";
import CustomButton from "../../../../utils/custom.button";
import CustomLink from "../../../../utils/custom.link";
import { useNavigate } from "react-router-dom";

const LargeCardProjectSectionHome = (props) => {
  const navigate = useNavigate();
  const moveToProject = (project) => {
    navigate("/projects/" + project.id, { state: project });
    // console.log(project);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <Grid item sx={{ flex: 4 }}>
          <img alt="" className="" title="image" src={props.project.img[0]} />
        </Grid>
        <Grid item sx={{ flex: 6 }}>
          <h2>{props.project.address}</h2>
          {props.project.description.map((des, i) => (
            <p>{des}</p>
          ))}
        </Grid>
        <Grid item sx={{ flex: 2 }}>
          <Grid container direction="column" justifyContent="space-between">
            {/* <Grid item sx={{ height: "50%", marginBottom: "35px" }}>
              <h3>{props.project.address}</h3>
            </Grid> */}
            <Grid item sx={{ height: "50%" }}>
              <CustomButton
                //id="header_main_section_get_a_quote"
                //onClick={projectAdd}
                id="home_project_section"
                onClick={() => moveToProject(props.project)}
                label="Visa projekt"
                size="16px"
                width="169px"
                height="55px"
                color="#ffffff"
                background="#FF8718"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LargeCardProjectSectionHome;
