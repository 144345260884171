import { styled } from '@mui/material/styles';
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { Card, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';

const StyledFab = styled(Fab)({
  position: 'fixed',
  width: 60,
  height: 60,
  bottom: 40,
  right: 40,
});
//handleOpen

function CustomDialogItem(props) {
  const [open, setOpen] = useState(false);
  const [editedValue, setEditedValue] = useState();
  const handleChange = (event) => {
    setEditedValue(event.target.value)

    //setEditedValue(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose()

  };
  const handleOpen = () => {
    setOpen(true);
  };


  const handleSubmit = () => {
    props.handleSubmit(props.name,props.value);
  };
  return (
    <>
    <MenuItem onClick={handleOpen} disableRipple>
      {props.icon}
      {props.label}
    </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.label}</DialogTitle>
        <DialogContent >
          <Typography>Är du säker på att du vill {props.label}!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nej</Button>
          <Button onClick={handleSubmit} color="primary">Ja</Button>
        </DialogActions>
      </Dialog>
    </>
)
};
export default CustomDialogItem;