import { Grid, Slider } from "@mui/material";


const ConsumptionAddServiceForm = (props) => {
  const marks = [
    {
      value: 0,
      label: '0kwh',
    },
    {
      value: 12500,
      label: '12 500kwh',
    },
    {
      value: 25000,
      label: '25 000kwh',
    },
    {
      value: 37500,
      label: '37 500kwh',
    },
    {
      value: 50000,
      label: '50 000kwh',
    },
  ];
  function valuetext(value) {
    return `${value}kwh`;
  }
  return (
    <>
      <p>Årlig elförbrukning:</p>
      <Grid >
        <Slider
          id="annualConsumption"
          aria-label="Always visible"
          defaultValue={10000}
          getAriaValueText={valuetext}
          max={50000}
          step={100}
          marks={marks}
          valueLabelDisplay="on"
        />
      </Grid>
    </>
  );
}
export default ConsumptionAddServiceForm;
