import { Container, Grid } from "@mui/material";
import "./main.section.FAQss.scss";
import React from "react";

const DesktopMainSectionFAQss = (props) => {
  function scrollToSection(sectionId) {
    setTimeout(() => {
      console.log(sectionId);
      const FAQsSection = document.getElementById(sectionId);

      if (FAQsSection) {
        FAQsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }
  return (
    <div className="DesktopContainerFAQs">
      <div className="videoContainer">
        <video
          src={props.FAQssData.main_section.video}
          type="video/mp4"
          className="mainSectionVideo"
          autoPlay
          loop
          muted
          playsInline
          style={{
            transform: "translateZ(0)",
            objectFit: "cover",
            width: "100%",
            height: "400px",
          }}
        />
      </div>
      <Container fixed className="mainSectionContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid xs={6} className="left">
            <h1>{props.FAQssData.main_section.title[0]}</h1>
            <h1>{props.FAQssData.main_section.title[1]}</h1>
            <p>{props.FAQssData.main_section.description}</p>
          </Grid>
          {props.FAQssData.main_section.imageMain && (
            <Grid xs={6} className="right">
              <img
                className="mainSectionImage"
                alt=""
                title="image"
                src={props.FAQssData.main_section.imageMain}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default DesktopMainSectionFAQss;
