//import SectionFour from "./sections/section.four.home";
// import SectionFive from "./sections/section.five.home";
//import SectionSix from "./sections/section.six.home";
import { useEffect, useState } from "react";
import FAQsAbout from "./sections/fAQs.about";
import GrowthAbout from "./sections/growth.about";
import MainAbout from "./sections/main/main.section.about";
import NewsletterAbout from "./sections/newsletter.about";
import TeamAbout from "./sections/team.about";
import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import jsonAboutData from "../../json/about.page.json";
import DescriptionAbout from "./sections/description.about";
import ValuationAbout from "./sections/valuation.about";
import AnimatedSection from "../home/sections/animated.section.home";
import { Container, Grid, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import AboutSlider from "./sections/slider/about.slider.component";

const AboutPage = () => {
  const [aboutData, setAboutData] = useState(jsonAboutData);
  const location = useLocation();
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    AOS.init({ duration: 2000 });
    //AOS.refresh();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // Check if the variable is defined
  if (typeof aboutData !== "undefined") {
    // Display the value of the variable
    // to show about us just uncomment the commented the "TeamAbout" in return block
    
    return (
      <>
        <Grid data-aos="fade-up">
          {/* {!isSM && <AboutSlider aboutData={aboutData} />} */}
          <AboutSlider aboutData={aboutData} />
          {/* <MainAbout aboutData={aboutData} /> */}
        </Grid>
        <Container
          fixed
          style={{
            borderRadius: isSM ? "23px 23px 0px 0px" : "",
            backgroundColor: isSM ? "white" : "",
            marginTop: isSM ? "325px" : "",
            //marginTop: "-15px",
            position: "relative",
          }}
        >
          <Grid data-aos="fade-up">
            <DescriptionAbout aboutData={aboutData} />
          </Grid>
          <Grid>
            <ValuationAbout aboutData={aboutData} />
          </Grid>
          
          <Grid data-aos="fade-up">
            {/* <TeamAbout aboutData={aboutData} /> */}
          </Grid>
          <Grid data-aos="fade-out">
            <AnimatedSection />
          </Grid>
        </Container>
        {/* <SectionFive />*/}
        {/* <SectionFour />
          <SectionSix />  */}
      </>
    );
  } else {
    // Display an error message
    return <h1>The page is loading</h1>;
  }
};

export default AboutPage;
