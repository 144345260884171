import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  // Get the current location object from the router
  const location = useLocation();

  // Use the useLayoutEffect hook to scroll to top whenever the location changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Pass location as a dependency to re-run the effect when it changes

  return null; // This component does not render anything
}

export default ScrollToTop;
