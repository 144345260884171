import { ErrorMessage, Field } from "formik";
import CustomField from "../../../utils/custom.field";
import CustomMapField from "./service.add.map.component";

const HeightAddServiceForm = (props) => {
  const handleAddressChange = (e) => {
    // setAddressValue("address", e.target.value);
  };
  return (
    <>
      <Field
        name="roofLength"
        label="Höjd"
        type="number"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="roofLength"
      />
      <Field
        name="roofWidth"
        label="Bredd"
        type="number"
        required={true}
        component={CustomField}
      />
      <ErrorMessage
        component="span"
        className="error-message"
        name="roofWidth"
      />
    </>
  );
};
export default HeightAddServiceForm;
