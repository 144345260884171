import { Grid, useMediaQuery } from "@mui/material";
import "./rating.section.home.scss";
import React, { useEffect, useRef, useState } from "react";
import CustomAvatar from "../../../../utils/custom.avatar";
import CustomLink from "../../../../utils/custom.link";
import CardRatingSectionHome from "./card.rating.section.home";
import { useTheme } from "@emotion/react";
import RatingOwlSectionHome from "./rating.owl.section.home";
import RatingSliderSectionHome from "./rating.slider.section.home";

const RatingSectionHome = (props) => {
  const [activeDotNum, setActiveDotNum] = useState(0);
  const wrapperRef = useRef(null);
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <section id="ratingSection" className="ratingSection">
        <h1 className="ratingTitle">
          {props.homeData.ratings_section[0].title}
        </h1>
        {/* <p className="ratingDescription">
          {props.homeData.ratings_section[0].description}
        </p> */}
        {!isMD && <RatingSliderSectionHome homeData={props.homeData} />}
        {isMD && <RatingOwlSectionHome homeData={props.homeData} />}

        <div className="allReviews">
          <CustomLink>Läs alla recensioner</CustomLink>
        </div>
        <hr />
      </section>
    </>
  );
};

export default RatingSectionHome;
