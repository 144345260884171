import React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const CustomImage = styled.img`
  height: 100%;
  width: 100%;
  max-height: 123px;
  max-width: 123px;
  border: 1px solid #000000;
  border-radius: 20px;
  /* other styles */
  cursor: pointer; /* Add crosshair cursor style */
  // selectedImage
  &:hover {
    transform: scale(1.01);
  }
  &:focus {
    /* Styles for the select element when focused */
    border: 2px solid #000000;
    /* Add any other desired styles */
  }
`;

const CustomImageSelect = ({
  field,
  form,
  set_value,
  images,
  setSelectedImage,
  selectedImage,
}) => {
  const handleImageClick = (image) => {
    console.log(field.name);
    setSelectedImage(image.src);
    form.setFieldValue(field.name, image.src);

    // Additional code here...
  };
  const renderedImages = images.map((image) => (
    <Grid item key={image.src} xs={6} sm={3}>
      <CustomImage
        key={image.src}
        src={image.src}
        alt={image.alt}
        onClick={() => {
          handleImageClick(image);
        }}
        className={selectedImage === image.src ? "selectedImage" : ""}
      />
    </Grid>
  ));
  return <Grid container>{renderedImages}</Grid>;
};

export default CustomImageSelect;
