const generatePassword = (length = 10, includeUppercase = true, includeLowercase = true, includeNumbers = true, includeSpecial = true) => {
    let password = '';
    let possibleCharacters = '';

    if (includeUppercase) {
        possibleCharacters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    if (includeLowercase) {
        possibleCharacters += 'abcdefghijklmnopqrstuvwxyz';
    }

    if (includeNumbers) {
        possibleCharacters += '0123456789';
    }

    if (includeSpecial) {
        possibleCharacters += '!@#$%^&*';
    }

    for (let i = 0; i < length; i++) {
        password += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    }

    return password;
};
export default generatePassword;