import * as React from "react";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value} sek`;
}
const CustomRangeSlider = ({ field, form, set_value, ...props }) => {
  // console.log(props);
  // console.log(field);
  //console.log(form.initialValues.price);
  const [value, setValue] = React.useState([
    form.initialValues.price[0],
    form.initialValues.price[1],
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    set_value(newValue);
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <Slider
      {...field}
      {...props}
      min={form.initialValues.price[0]}
      max={form.initialValues.price[1]}
      step={2}
      size="small"
      name={props.label}
      id={props.name}
      label={props.label}
      placeholder={props.label}
      getAriaLabel={() => "Pris i SEK"}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      color="primary"
    />
  );
};
export default CustomRangeSlider;
