import TextField from '@mui/material/TextField'

const CustomStaticField = ({
    ...props
}) => {
  //console.log(props.defaultValue)
  return (
    <>
    {/* <label htmlFor={field.name}>{props.labelName}</label> */}
    <TextField
          label={props.label}
          placeholder={props.placeholder}
          fullWidth
          disabled
          defaultValue={props.defaultValue}
          margin="normal" //dense normal
          variant="outlined" // standard filled outlined
          color="warning" // warning secondary success
        />
    {/* {errors[field.name] && touched[field.name] ?
        <span>{ errors[field.name]}</span>  
    :null} */}
    </>
  )
}

export default CustomStaticField
