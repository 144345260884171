import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormControlLabel, Checkbox, Button } from "@mui/material";
import CustomField from "../../utils/custom.field";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

import CustomLink from "../../utils/custom.link";

import { Typography } from "@mui/material";
const FormikLogin = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // SubmitData.Post({
  //   username: '',
  //   password: '',
  // })
  // form props
  function SubmitData(formValue) {
    setLoading(true);

    const { username, password } = formValue;
    //console.log(formValue)
    setMessage("");

    AuthService.login(username, password).then(
      () => {
        navigate("/profile");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  }
  const formikProps = {
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Detta fält är obligatoriskt!"),
      password: Yup.string().required("Detta fält är obligatoriskt!"),
    }),
    onSubmit: (values) => {
      //console.log(values)
      SubmitData(values);
    },
  };

  return (
    <Formik {...formikProps}>
      <Form>
        <Field
          name="username"
          type="text"
          label="Användarnamn"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="username"
        />
        <Typography sx={{ fontSize: "0.9rem" }}>
          Glömde din{" "}
          <CustomLink id="" to="/username/request">
            Användarnamn?
          </CustomLink>
        </Typography>
        <Field
          name="password"
          label="Lösenord"
          type="password"
          required={true}
          component={CustomField}
        />
        <ErrorMessage
          component="span"
          className="error-message"
          name="password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Kom ihåg mig"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          Sign In
        </Button>
      </Form>
    </Formik>
  );
};

export default FormikLogin;
