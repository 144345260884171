import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useState, useEffect } from "react";
import eventBus from "../../common/EventBus";
import CustomAutoField from '../../utils/custom.auto.field'

import * as Yup from "yup";
import { postCategory, listCategories } from "./category.service";


import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { wait } from "@testing-library/user-event/dist/utils";
import { styled } from '@mui/material/styles';

function PostCategory() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("");
  const [Categories, setCategories] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    listCategories().then(
      (response) => {
        //console.log(response);
        setCategories(response.data);
        //setCount(response.data.count);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        //setCategories(_content); // fix it later 

        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const StyledFab = styled(Fab)({
    position: 'fixed',
    width: 60,
    height: 60,
    bottom: 40,
    right: 40,
  });
  const SubmitData = (formValue) => {
    setLoading(true)

    const { categoryName } = formValue;
    const { categoryParent } = formValue;
    let findParent = Categories.find((option) => option.name === categoryParent)
    if (findParent) {
      findParent=findParent['id']
    } else {
      findParent=0
    }
    postCategory(categoryName, findParent).then(
      (response) => {
        //console.log(response);
        setMessage(response.message);
        wait(10)
        setOpen(false);

        setLoading(true);
      },
      (error) => {
        //console.log(error.response.data.message)
        const resMessage =
          error.response.data.message ||
          (error.response && error.response && error.response.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setLoading(false);
      }
    );
  };
  const formikProps = {
    initialValues: {
      categoryName: '',
      categoryParent: '',
    },
    validationSchema: Yup.object().shape({
      categoryName: Yup.string().required("Detta fält är obligatoriskt!"),
      categoryParent: Yup.string(),
    }),
    onSubmit: (values, event) => {
      //console.log(values)
      SubmitData(values)
    }
  }
  return (
    <div>
      <StyledFab
        className="addCategory"
        color="primary"
        aria-label="add"
        onClick={handleClickOpen}
      >
        <AddIcon />
      </StyledFab>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open} onClose={handleClose}>
        <Formik {...formikProps}>
        {formik => (
        <Form >
            <DialogTitle>Add a Category</DialogTitle>
            {!loading && (
              <div>
                <DialogContent>
                  <p>{formik.values.categoryName}</p>
                  <Field
                    freeSolo={true}
                    component={CustomAutoField}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="categoryName"
                    label="kategori namn"
                    options={Categories.map((option) => option.name)}
                    formik={formik}
                    placeholder="kategori namn"
                  />
                  <ErrorMessage
                    name="categoryName"
                    component="div"
                    className="alert alert-danger"
                  />
                  <Field
                    freeSolo={true}
                    component={CustomAutoField}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="categoryParent"
                    label="Category Parent"
                    options={Categories.map((option) => option.name)}
                    placeholder="Category Parent"
                  />
                  <ErrorMessage
                    name="categoryParent"
                    component="div"
                    className="alert alert-danger"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Back</Button>
                  <Button type="submit" disabled={loading}>Add</Button>
                </DialogActions>
              </div>
            )}
            {message && (
              <div className="form-group">
                <div
                  className={
                    loading ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )}
        </Form>
      )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default PostCategory;
