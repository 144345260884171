import { Container, Grid } from "@mui/material";
import CustomButton from "../../../../utils/custom.button";
import "./main.section.projects.scss";
import React from "react";

const DesktopMainSectionProjects = (props) => {
  function scrollToSection(sectionId) {
    setTimeout(() => {
      console.log(sectionId);
      const projectSection = document.getElementById(sectionId);

      if (projectSection) {
        projectSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }
  return (
    <div className="DesktopContainerProject">
      {/* <img
        className="backgroundSectionImage"
        alt=""
        title="image"
        src={props.projectsData.main_section[0].image}
      /> */}
      <div className="videoContainer">
        <video
          src={props.projectsData.main_section[0].video}
          type="video/mp4"
          className="mainSectionVideo"
          autoPlay
          loop
          muted
          playsInline
          style={{
            transform: "translateZ(0)",
            objectFit: "cover",
            width: "100%",
            height: "400px",
          }}
        />
      </div>
    </div>
  );
};

export default DesktopMainSectionProjects;
