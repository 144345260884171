import { Grid } from "@mui/material";
import React from "react";
import "./stock.section.products.scss";

const StockSectionProducts = (props) => {
  return (
    <section className="stockProductsSection">
      {props.productsData.stock_section[0].section_list.map((section, i) => (
        <React.Fragment key={i}>
          {section.image_direction === "left" && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "82px" }}
            >
              <Grid xs={12} md={6} className="right">
                <img
                  className="stockProductsLeftImage"
                  alt=""
                  title="image"
                  src={section.image}
                />
              </Grid>
              <Grid xs={12} md={6} className="left">
                <h2>{section.title}</h2>
                {section.description.map((description, i) => (
                  <p key={i}>{description}</p>
                ))}
                {section.colored_description && (
                  <>
                    {section.colored_description.map(
                      (colored_description, i) => (
                        <div className="purpleBoxProducts">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid xs={12} sm={2}>
                              <img
                                className="iconStockProductsSectionImage"
                                alt=""
                                title="image"
                                src={props.productsData.stock_section[0].icon}
                              />
                            </Grid>
                            <Grid xs={12} sm={10}>
                              <p>{colored_description}</p>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {section.image_direction === "right" && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "96px", marginBottom: "89px" }}
              sx={{
                "@media (max-width: 900px)": {
                  // xs screen size
                  flexDirection: "column-reverse",
                },
                "@media (min-width: 900px)": {
                  // md screen size and larger
                  flexDirection: "row",
                },
              }}
            >
              <Grid xs={12} md={6} className="left">
                <h2>{section.title}</h2>
                {section.description.map((description, i) => (
                  <p>{description}</p>
                ))}
                {section.colored_description && (
                  <>
                    {section.colored_description.map(
                      (colored_description, i) => (
                        <div className="purpleBoxProducts">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid xs={12} sm={2}>
                              <img
                                className="iconStockProductsSectionImage"
                                alt=""
                                title="image"
                                src={props.productsData.stock_section[0].icon}
                              />
                            </Grid>

                            <Grid xs={12} sm={10}>
                              <p>{colored_description}</p>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}
                  </>
                )}
              </Grid>
              <Grid xs={12} md={6} className="stockProductsRightImage">
                <img alt="" title="image" src={section.image} />
                <div className="imageStockProductsMask"></div>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </section>
  );
};

export default StockSectionProducts;
