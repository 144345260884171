import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";

const CardsDesktopReasonSectionHome = (props) => {
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container
    >
      {props.homeData.reason_section[0].reason_list.map((reason, i) => (
        <Grid
          key={i}
          className="reasonSectionCard"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={2}>
            <img src={props.homeData.reason_section[0].icon} className="" />
          </Grid>

          <Grid item xs={10}>
            <h2>{reason.title}</h2>
            <p>{reason.description}</p>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsDesktopReasonSectionHome;
