import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import styled from "@emotion/styled";

const CustomOption = styled.div`
  border: 2px solid #392d54;
  border-radius: 50px 0px 0px 50px;

  height: 60px;
  width: 180px;
  /* other styles */
  cursor: pointer; /* Add crosshair cursor style */
  // selectedImage
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  float: right;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 105%;
    /* or 25px */
    margin: 0px;

    text-align: center;
    letter-spacing: 0.04em;
    color: #392d54;
  }
  &:hover {
    background: #392d54;
    p {
      color: #ffffff;
    }
  }
  &:focus {
    /* Styles for the select element when focused */
    border: 2px solid #000000;
    /* Add any other desired styles */
  }
`;
const CustomOptionTwo = styled.div`
  border: 2px solid #392d54;
  border-radius: 0px 50px 50px 0px;
  float: left;
  height: 100%;
  width: 100%;
  height: 60px;
  width: 180px;
  color: white;
  /* other styles */
  cursor: pointer; /* Add crosshair cursor style */
  // selectedImage
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 105%;
    /* or 25px */
    text-align: center;
    align-items: center;
    margin: 0px;
    text-align: center;
    letter-spacing: 0.04em;

    color: #392d54;
  }
  &:hover {
    background: #392d54;
    p {
      color: #ffffff;
    }
  }
  &:focus {
    /* Styles for the select element when focused */
    border: 2px solid #000000;
    /* Add any other desired styles */
  }
`;
const SelectServiceQuote = ({
  field,
  form,
  set_value,
  options,
  serviceType,
  set_serviceType,
  ...props
}) => {
  const handleImageClick = (value) => {
    set_value(value);
    const newServiceType = serviceType.includes(value)
      ? serviceType.filter((type) => type !== value)
      : [...serviceType, value];
    console.log(newServiceType);
    set_serviceType(newServiceType);
    // set_value(value);
    // set_serviceType(value);
    form.setFieldValue(field.name, newServiceType);

    // Additional code here...
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item key={options[0].src} xs={6}>
        <CustomOption
          onClick={() => {
            handleImageClick(options[0].value);
          }}
          className={
            serviceType.includes(options[0].value) ? "selectedOption" : ""
          }
        >
          <p>{options[0].value}</p>{" "}
        </CustomOption>
      </Grid>
      <Grid item key={options[1].src} xs={6}>
        <CustomOptionTwo
          onClick={() => {
            handleImageClick(options[1].value);
          }}
          className={
            serviceType.includes(options[1].value) ? "selectedOption" : ""
          }
        >
          <p>{options[1].value}</p>
        </CustomOptionTwo>
      </Grid>
    </Grid>
  );
  // return (
  //   <>
  //     {options.map((option) => (
  //       <FormControlLabel
  //         key={option.value}
  //         fullWidth
  //         style={{ fontFamily: "Montserrat" }}
  //         margin="normal"
  //         variant="outlined"
  //         color="warning"
  //         control={
  //           <Checkbox
  //             name={props.name}
  //             {...field}
  //             id={props.name}
  //             onChange={() => {
  //               const newValues = [...form.values.serviceType];
  //               if (newValues.includes(option.value)) {
  //                 newValues.splice(newValues.indexOf(option.value), 1);
  //               } else {
  //                 newValues.push(option.value);
  //               }
  //               form.setFieldValue("serviceType", newValues);
  //               if (set_value) {
  //                 set_value(newValues);
  //               }
  //             }}
  //             checked={form.values.serviceType?.includes(option.value) ?? false}
  //           />
  //         }
  //         label={option.label}
  //       />
  //     ))}
  //   </>
  // );
};

export default SelectServiceQuote;
