import React, { useEffect, useState } from "react";
import CustomButton from "../../utils/custom.button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
} from "@mui/material";
import "./fAQs.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import jsonFAQData from "../../json/faq.page.json";
import CustomCollapsible from "../../utils/custom.collapsible.text";
import MainSectionFAQss from "./main/main.section.FAQss";

const FAQsPage = () => {
  const [faqData, setFAQData] = useState(jsonFAQData);
  useEffect(() => {
    AOS.init({ duration: 2000 });
    // AOS.refresh();
  }, []);

  let navigate = useNavigate();

  const rout = () => {
    navigate("/contact");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <MainSectionFAQss FAQssData={faqData} />

      <Container fixed style={{background:"white",     marginBottom: '0px'
    ,paddingBottom: '8px'}}>
        <section className="fAQsAbout">
          <div className="mainFAQsAbout">
            <h2>{faqData.mainFAQsAbout.title}</h2>
            <p>{faqData.mainFAQsAbout.description}</p>

            {/* <h3>
          Har du fortfarande en fråga?
        </h3>
        <h5>
          Har du fler frågor? Kontakta oss! Vårt expertteam är alltid tillgängligt för att hjälpa dig att fatta informerade beslut om våra solprodukter och tjänster. Fyll bara i vårt kontaktformulär så återkommer vi till dig snart.
        </h5>
        <div className="mainFAQsAboutButton">
          
          <CustomButton id="" onClick={rout} type="submit" label="KONTAKTA OSS" size="18px" width='139px' height='46px' color="#F5F2EB" background="background-image: linear-gradient(to right, #FBB034, #ffdd00);" />
        </div> */}
          </div>
        </section>
        <Grid container className="" style={{ marginBottom: "35px" }}>
          {faqData.mainFAQsAbout.fAQs_list.map((question, i) => (
            <CustomCollapsible
              key={i}
              description={question.description}
              title={question.title}
              bulletList={question.bulletList}
              bulletTitle={question.bulletTitle}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default FAQsPage;
