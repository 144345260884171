import { Grid } from "@mui/material";
import CustomButton from "../../../../utils/custom.button";
import "./mobile.main.section.services.scss";
import React, { useEffect, useState } from "react";

const MobileMainSectionServices = (props) => {
  const [zIndex, setZIndex] = useState(1);

  const handleScroll = () => {
    //console.log(window.scrollY);
    if (window.scrollY >= 150) {
      setZIndex(-1);
    } else {
      setZIndex(1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Grid className="mobileMainSectionService">
      <div className="videoContainer" style={{ zIndex: -1 }}>
        <video
          src={props.servicesData.main_section[0].video}
          type="video/mp4"
          className="mainSectionVideo"
          autoPlay
          loop
          muted
          playsInline
          style={{ transform: "translateZ(0)", position: "fixed" }}
        />
      </div>
      {/* <div className="mainSectionImageMask"></div> */}
      {/* <img
        className="mainSectionImage"
        alt=""
        title="image"
        src={props.servicesData.main_section[0].videoGif}
      /> */}
      {/* <img
        className="mainSectionImage"
        alt=""
        title="image"
        src={props.servicesData.main_section[0].image}
      />*/}

      <div className="mainSectionContainerMobile" style={{ zIndex: zIndex }}>
        <h1 className="mainTitle">
          {props.servicesData.main_section[0].title[0]}
          <span> {props.servicesData.main_section[0].title[1]} </span>
        </h1>

        <p className="mainSubTitle">
          {props.servicesData.main_section[0].description_Mobile}
        </p>
        {/* <Grid
          className="mainSubButtons"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <CustomButton
              id="header_main_section_get_a_quote"
              onClick={props.projectAdd}
              label="FÅ EN OFFERT"
              size="9px"
              width="135px"
              height="35px"
              color="#ffffff"
              background="#FF8718"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              id="header_main_section_learn_more"
              onClick={props.learnMore}
              label="LÄS MER"
              width="135px"
              height="35px"
              color="#F5F2EB"
              background="transparent"
              border="#ffff"
            />
          </Grid>
        </Grid> */}
      </div>
      <Grid className="welcomeMainSection">
        <h3>VÄLKOMMEN TILL TOLO SOLAR</h3>
      </Grid>
      {/* <AddProject closeProjectAdd={closeProjectAdd} openProjectAdd={openProjectAdd} /> */}
    </Grid>
  );
};

export default MobileMainSectionServices;
