import "./style.css"

const CookiesPage = () => {

    return (
        <>
        
        </>
    );
};

export default CookiesPage;
