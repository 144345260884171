import { Card, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';

import { useContext } from 'react';
import { CartContext } from '../../context';
import CreateOrder from './order.cart.component';
import TabelCart from './table.cart.component';

function subtotal(cart) {
    return cart.map((index) => parseFloat(index.quantityPrice)).reduce((sum, i) => sum + i, 0);;
}

function CartPage() {
    const [cart, setCart] = useContext(CartContext);


    const invoiceSubtotal = subtotal(cart);
    const invoiceTaxes = 0.25 * invoiceSubtotal;
    const invoiceTotal = invoiceTaxes + invoiceSubtotal;
    return (
        <Container maxWidth="lg" sx={{ paddingTop: { sm: '1rem' } }} >
            <Grid container spacing={2} >
                <Grid item md={8}  >
                    <Card p={2} sx={{ padding: { sm: '1rem' } }}>
                        <TabelCart cart={cart} setCart={cart} invoiceSubtotal={invoiceSubtotal} invoiceTaxes={invoiceTaxes} invoiceTotal={invoiceTotal} />
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Card sx={{ padding: { sm: '1rem' } }}>
                        <CreateOrder cart={cart} invoiceSubtotal={invoiceSubtotal} invoiceTaxes={invoiceTaxes} invoiceTotal={invoiceTotal} />
                    </Card>
                </Grid>
            </Grid>
            {/* <Grid item >
                    <TabelCart cart={cart} setCart={cart} invoiceSubtotal={invoiceSubtotal} invoiceTaxes={invoiceTaxes} invoiceTotal={invoiceTotal} />
                </Grid> */}
            <Grid>
                <Grid>
                    <Card sx={{ margin: { sm: '1rem' }, padding: { sm: '1rem' } }}>
                        <Typography component={'h2'}>Betalningsvillkor:</Typography>
                        <Typography component={'p'}>
                            30 procent vid beställning, 40 procent efter leverans av material och 30 procents betalning efter
                            färdigställd installation, drifttest och färdiganmälan till nätägaren. 10 dagars betalningsperiod från
                            fakturadatum. PDF skickas till beställarens e-postadress.
                        </Typography>
                    </Card>
                </Grid>
                <Grid>
                    <Card sx={{ margin: { sm: '1rem' }, padding: { sm: '1rem' } }}>
                        <Typography component={'h2'}> PRODUKTER:</Typography>
                        <Typography component={'p'}>
                            30 procent vid beställning, 40 procent efter leverans av material och 30 procents betalning efter
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default CartPage;
