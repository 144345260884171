import { useState } from "react";
import * as React from "react";
import CustomField from "../../../utils/custom.field";

import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import eventBus from "../../../common/EventBus";
import { listCategories } from "../../category/category.service";
import CustomImageField from "../../../utils/custom.image.field";
import CustomSelectField from "../../../utils/custom.select.field";
import RichEditorField from "../../../utils/rich.editor.field";

const ProductAddForm = (props) => {
  const [Categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);

  React.useEffect(() => {
    //{Categories.map((option) => option.name)}
    listCategories().then(
      (response) => {
        //console.log(response);
        setCategories(response.data);
        //setCount(response.data.count);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setCategories(_content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const formikProps = {
    initialValues: {
      title: "",
      description: "",
      Make: "",
      category: "",
      price: "",
      quantity_stock: "",
      discount_percent: "",
      discount_datetime_start: "",
      discount_datetime_end: "",
      height: "",
      width: "",
      images: [],
      html_body: "",
    },
    validationSchema: Yup.object().shape({
      category: Yup.string().required("Detta fält är obligatoriskt!"),
      title: Yup.string().required("Detta fält är obligatoriskt!"),
      description: Yup.string().required("Detta fält är obligatoriskt!"),
      Make: Yup.string().required("Detta fält är obligatoriskt!"),
      price: Yup.string().required("Detta fält är obligatoriskt!"),
      quantity_stock: Yup.string().required("Detta fält är obligatoriskt!"),
      discount_percent: Yup.string(),
      discount_datetime_start: Yup.string(),
      discount_datetime_end: Yup.string(),
      height: Yup.string(),
      width: Yup.string(),
      images: Yup.array().min(1, "At least one image is required"),
    }),
    onSubmit: (values, event) => {
      console.log(values);
      props.handleSubmit(values);
    },
  };
  return (
    <Formik {...formikProps}>
      {(formik) => (
        <Form>
          <Field
            name="title"
            type="text"
            label="Titel"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="title"
          />
          <Field
            component={CustomSelectField}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="select"
            name="category"
            label="kategori namn"
            categories={Categories}
            //options={Categories.map((option) => option.name)}
            formik={formik}
            placeholder="kategori namn"
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="category"
          />
          <Field
            name="description"
            type="textarea"
            label="Kort Beskrivning"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="description"
          />
          <Typography
            variant="h6"
            component="p"
            sx={{
              paddingLeft: { sm: "2rem" },
              mb: "1rem",
            }}
          >
            Lång Beskrivning:
          </Typography>
          <Grid
            sx={{
              paddingTop: {},
              mb: "1rem",
            }}
          >
            <Field component={RichEditorField} name="html_body" />
          </Grid>
          <ErrorMessage
            component="span"
            className="error-message"
            name="html_body"
          />
          <Typography
            variant="h6"
            component="p"
            sx={{
              paddingLeft: { sm: "2rem" },
              mb: "1rem",
            }}
          >
            Material och Storlek:
          </Typography>
          <p></p>
          <Field
            name="Make"
            type="text"
            label="material"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="Make"
          />
          <Field
            name="height"
            label="Höjd"
            type="number"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="height"
          />
          <Field
            name="width"
            label="Bredd"
            type="number"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="width"
          />

          <Typography
            variant="h6"
            component="p"
            sx={{
              paddingLeft: { sm: "2rem" },
              mb: "1rem",
            }}
          >
            Pris och Rabatt:
          </Typography>
          <p></p>
          <Field
            name="price"
            type="number"
            label="Pris"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="price"
          />
          <Field
            name="quantity_stock"
            label="Kvantitet lager"
            type="number"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="quantity_stock"
          />
          <Field
            name="discount_percent"
            label="Rabattprocent"
            type="number"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="discount_percent"
          />
          <Field
            name="discount_datetime_start"
            label="Rabatt datum och tid start"
            InputLabelProps={{ shrink: true, required: true }}
            type="date"
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="discount_datetime_start"
          />
          <Field
            name="discount_datetime_end"
            label="Rabatt datum och tid slut"
            type="date"
            InputLabelProps={{ shrink: true, required: true }}
            required={true}
            component={CustomField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="discount_datetime_end"
          />
          <Field
            name="images"
            label="images"
            images={images}
            setImages={setImages}
            component={CustomImageField}
          />
          <ErrorMessage
            component="span"
            className="error-message"
            name="images"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={props.loading}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default ProductAddForm;
