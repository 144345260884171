import { styled } from '@mui/material/styles';
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

const StyledFab = styled(Fab)({
  position: 'fixed',
  width: 60,
  height: 60,
  bottom: 40,
  right: 40,
});

const CustomFab = ({ children, onClick }) => (
  <StyledFab
  color="primary"
  aria-label="add"
  onClick={onClick}
>
  <AddIcon />
</StyledFab>

);
export default CustomFab;