import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import "./stock.section.services.scss";
import { Parser } from "html-to-react";
import { useTheme } from "@emotion/react";

const StockSectionServices = (props) => {
  function convertToHtmlElement(description) {
    const parser = new Parser();
    const reactElement = parser.parse(description);
    return reactElement;
  }
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <section className="stockServicesSection">
      {props.servicesData.stock_section[0].title && (
        <h1>{props.servicesData.stock_section[0].title}</h1>
      )}
      {props.servicesData.stock_section[0].description && (
        <>
          <div
            className="ServiceOrangeBox"
            style={{
              marginLeft: isMobile ? "0px" : "50px",
              marginRight: isMobile ? "0px" : "50px",
            }}
          >
            {props.servicesData.stock_section[0].description.map(
              (description, i) => (
                <p style={{ textAlign: "center" }}>{description}</p>
              )
            )}
          </div>
        </>
      )}

      {props.servicesData.stock_section[0].section_list.map((section, i) => (
        <>
          {section.image_direction === "left" && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} md={6} className="right">
                <img
                  className="stockServicesLeftImage"
                  alt=""
                  title="image"
                  src={section.image}
                />
              </Grid>
              <Grid item xs={12} md={6} className="left">
                {section.title && <h2>{section.title}</h2>}
                {section.description && (
                  <>
                    {section.description.map((description, i) => (
                      <p> {convertToHtmlElement(description)}</p>
                    ))}
                  </>
                )}
                {section.colored_description && (
                  <>
                    {section.colored_description.map(
                      (colored_description, i) => (
                        <div className="purpleBoxServices">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid xs={12} sm={2}>
                              <img
                                className="iconStockServicesSectionImage"
                                alt=""
                                title="image"
                                src={props.servicesData.stock_section[0].icon}
                              />
                            </Grid>
                            <Grid xs={12} sm={10}>
                              <p>{colored_description}</p>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {section.image_direction === "right" && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "96px", marginBottom: "89px" }}
              spacing={4}
              sx={{
                "@media (max-width: 900px)": {
                  // xs screen size
                  flexDirection: "column-reverse",
                },
                "@media (min-width: 900px)": {
                  // md screen size and larger
                  flexDirection: "row",
                },
              }}
            >
              <Grid item xs={12} md={6} className="left">
                {section.title && <h2>{section.title}</h2>}
                {section.description && (
                  <>
                    {section.description.map((description, i) => (
                      <p> {convertToHtmlElement(description)}</p>
                    ))}
                  </>
                )}

                {section.colored_description && (
                  <>
                    {section.colored_description.map(
                      (colored_description, i) => (
                        <div className="purpleBoxServices">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid xs={12} sm={2}>
                              <img
                                className="iconStockServicesSectionImage"
                                alt=""
                                title="image"
                                src={props.servicesData.stock_section[0].icon}
                              />
                            </Grid>
                            <Grid xs={12} sm={10}>
                              <p>{colored_description}</p>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} className="stockServicesRightImage">
                <img alt="" title="image" src={section.image} />
                <div className="imageStockServicesMask"></div>
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </section>
  );
};

export default StockSectionServices;
