import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import "./call.button.component.scss";
import { getAnalytics, logEvent } from "firebase/analytics";

const StyledButton = styled(Button)(
  ({
    font_color,
    border_radius,
    padding,
    background,
    height,
    width,
    border,
    size,
  }) => ({
    width: width,
    minWidth: width + "!important",
    height: height,
    display: "inlineBlock",
    //borderRadius: (border_radius ? border_radius : '96px 96px 96px 96px'),
    //border: '0px solid #000000',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    //padding: (padding ? padding : '12px 24px'),
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: size,
    lineHeight: "120%",
    //backgroundColor: background,
    textTransform: "none",
    color: font_color,
    border: "1px solid " + (border ? border : background ? background : "#fff"),
    position: "relative",
    float: "right",
    overflow: "hidden",
    // transition: 'color 0.1s ease-in-out',
    transition: "width 1.9s ease",

    // animation:
    //   "closeHeaderCall 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
    transitionDelay: "0.3s",

    "&:hover": {
      // paddingTop:'10px',
      //transform: 'scale(1.05)',
      //animation: 'customButtonAnimation 3s both',
      color: font_color,
      backgroundColor: background,

      //fontSize: size,
      //letterSpacing: '2.5px',
      //transition: 'width 1s linear',

      //transition: 'width 1.9s ease',

      // animation:
      //   "openHeaderCall 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
      // width: "160px",
      // minWidth: "160px" + " !important",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "-70%",
      width: "50%",
      height: "100%",
      transform: "skewx(45deg) translateX(0)",
      transition: "1s",
      background: "rgba(255,255,255,0.2)",
    },
    "&:hover:before": {
      //animation: 'customButtonAnimation 3s both',
      transform: "skewx(45deg) translateX(400%)",
    },
  })
);

const CallButton = ({
  children,
  onClick,
  id,
  border_radius,
  padding,
  label,
  color,
  background,
  height,
  width,
  icon,
  border,
  size,
  type,
  disabled,
}) => {
  const analytics = getAnalytics();

  function triggerIt() {
    onClick();
    logEvent(analytics, id, {
      event_name: id,
    });
  }
  return (
    <div className="callbar">
      <div className="call_icon">
        <StyledButton
          disabled={disabled}
          type={type}
          color="primary"
          aria-label="add"
          onClick={triggerIt}
          font_color={color}
          background={background}
          height={height}
          width={width}
          border={border}
          size={size}
          border_radius={border_radius}
          padding={padding}
        >
          {icon}
          {/* <p className="call_button">Call</p> */}
        </StyledButton>
      </div>
    </div>
  );
};
export default CallButton;
