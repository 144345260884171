import { Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import CustomLink from "../../../../utils/custom.link";
import "./why.section.products.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CustomScrollSlider } from "../../../../utils/custom.scroll.slider";
import { useTheme } from "@emotion/react";
const WhySectionProducts = (props) => {
  const [counter, setCounter] = useState(0);
  const [touchDelta, setTouchDelta] = useState(0);
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <section className="whySectionProducts">
      <h1>{props.productsData.why_section[0].title}</h1>

      {isMD ? (
        <CustomScrollSlider
          containerHeight="357px"
          containerWidth={
            (isSM ? "269" : isMD ? "269" : isLG ? "295" : "295") *
              props.productsData.why_section[0].why_list.length +
            "px"
          }
          cardWidth={"277"}
          setCounter={setCounter}
          counter={counter}
          touchDelta={touchDelta}
          setTouchDelta={setTouchDelta}
          // deactivateAutoScroll={deactivateAutoScroll}
          // setDeactivateAutoScroll={setDeactivateAutoScroll}
          hideArrow={isSM ? true : isMD ? false : isLG ? false : false}
        >
          {props.productsData.why_section[0].why_list.map((item, i) => (
            <div
              key={i}
              slideKey={i}
              // style={{
              //   hideArrow: isLG ? false : true,
              // }}
              counter={counter}
              touchDelta={touchDelta}
              className={"whyCard"}
              // key={i}
              // slideKey={i}
              style={{
                width: isSM
                  ? "87vw"
                  : isMD
                  ? "255px"
                  : isLG
                  ? "263px"
                  : "263px",

                //height: "358px",
                height: isSM ? "199px" : "199px",

                margin: isSM
                  ? "13px 7px 13px 7px"
                  : isMD
                  ? "13px 11px 13px 11px"
                  : isLG
                  ? "13px 10px 13px 10px"
                  : "13px 12px 13px 12px",
              }}
              // className={"modelCard"}
            >
              <div className="imageContainer">
                <img className="" alt="" title="image" src={item.icon} />
              </div>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              <a href={item.link} id="" style={{}}>
                {/* Läs mer{" "}
                  <ArrowForwardIosIcon
                    style={{
                      margin: "0px",
                    }}
                    fontSize="small"
                  /> */}
              </a>
            </div>
          ))}
        </CustomScrollSlider>
      ) : (
        // <CustomScrollSlider
        //   height={"358"}
        //   width={"268"}
        //   setCounter={setCounter}
        //   counter={counter}
        //   touchDelta={touchDelta}
        //   setTouchDelta={setTouchDelta}
        //   hideArrow={isXS ? true : isMD ? true : isLG ? false : true}
        //   scrollButton={false}
        // >
        //   {props.productsData.why_section[0].why_list.map((item, i) => (

        //   ))}
        // </CustomScrollSlider>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className=""
          spacing={3}
        >
          {props.productsData.why_section[0].why_list.map((item, i) => (
            <Grid item key={i} xs={4} className={""}>
              <div className="whyCard">
                <div className="imageContainer">
                  <img className="" alt="" title="image" src={item.icon} />
                </div>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
                {/* <a href={item.link} id="" style={{}}>
                    Läs mer{" "}
                    <ArrowForwardIosIcon
                      style={{
                        margin: "0px",
                      }}
                      fontSize="small"
                    />
                  </a> */}
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </section>
  );
};

export default WhySectionProducts;
