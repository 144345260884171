import {
  Grid,
  Divider,
  Paper,
  styled,
  Card,
  Box,
  Typography,
  Stack,
  Button,
  Link,
  CardActions,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BuyServiceDetails = (props) => {
  return (
    <Box m={3} marginTop={0} pt={0}>
      <StyledStack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent={{ xs: "center", md: "center" }}
        alignItems={{ xs: "center", md: "center" }}
        spacing={2}
      >
        <Grid>
          <Typography variant="h6">${props.service.price}</Typography>
        </Grid>
        <Grid>
          <Typography variant="h6">
            {props.service.discount_percent}%
          </Typography>

          {/* {props.service.discount_datetime_end}
              {props.service.discount_datetime_start} */}
        </Grid>
      </StyledStack>
      <Box m={3} pt={0}>
        <Grid container>
          <Grid xs={6} item>
            <Link
              to={"/service/" + props.service.id}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" endIcon={<AddShoppingCartIcon />}>
                Add
              </Button>
            </Link>
          </Grid>
          <Grid xs={6} item>
            <Link
              to={"/service/" + props.service.id}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" endIcon={<ShoppingCartIcon />}>
                Buy
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box m={3} pt={0}>
        <Typography variant="h6" color="textSecondary" component="h6">
          kort beskrivning:
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.service.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default BuyServiceDetails;
